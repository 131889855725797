@import '@styles/basic';

.container {
  display: flex;
  flex-direction: column;

  .recaptcha {
    display: flex;
    justify-content: center;
    margin-bottom: toRem(24);
  }
}

.inputWrapperContainer {
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  display: flex;
  line-height: 1.2rem;
  margin-bottom: 0.7rem;
  margin-top: 0.7rem;

  &.privacy {
    flex-direction: column;
    align-items: flex-start;

    label {
      display: flex;
      .privacyPolicy {
        p {
          font-size: toRem(14);
          line-height: toRem(17);
          font-weight: 500;

          & > a {
            color: $blue;
          }
        }
      }
    }
  }
  .inputWrapper {
    display: flex;
    margin-bottom: 0;

    .inputRadio {
      display: flex;

      &.isPa {
        &:first-child {
          margin-right: 0.7rem;
        }
      }
    }
  }
}

.error {
  color: $red;
  font-weight: 500;
  line-height: 0.9rem;
  font-size: 0.7rem;
  line-height: 1;
  margin-top: 0.4rem;
}