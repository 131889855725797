@import '@styles/basic';
.container {
  p {
    font-size: toRem(16);
    font-weight: 400;
    list-style: toRem(24);
    margin-bottom: toRem(16);
    text-align: left;
  }
}
