@import '@styles/basic';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: toRem(16);
  padding-bottom: 16px;
  margin-top: -0.6rem;
  @include breakpoint(sm) {
    flex-direction: column;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px;
    border-radius: 15px;
    font-size: toRem(12);
    font-weight: 400;
    line-height: toRem(12);

    img {
      height: 32px;
      max-width: none;
      flex-shrink: 0;
    }
    &:hover {
      background: rgba($blue, 0.05);
    }
  }

  .isActive {
    background: rgba($blue, 0.05);
  }
}
