@import '@styles/basic';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  border: 2px solid rgba($dark, 0.1);
  border-radius: toRem(16);
  overflow: hidden;

  p,
  span {
    font-size: toRem(12);
    margin: 0;
    line-height: 1;
  }
  input {
    height: toRem(32) !important;
    font-size: toRem(12);
    padding: 0 toRem(16);
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $gray;
  padding: toRem(16);
  flex-direction: column;
  @include breakpoint(md, true) {
    flex-direction: row;
    align-items: center;
  }
}

.headerContentLeft,
.headerContentRight {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: toRem(8);
  @include breakpoint(md, true) {
    align-items: center;
    margin-bottom: 0;
  }

  .renew {
    color: $blue;
    cursor: pointer;
  }

  .suspend {
    color: $dark;
    opacity: 0.6;
    cursor: pointer;
  }
}

.headerContentLeft {
  margin-bottom: toRem(16);
  @include breakpoint(md, true) {
    margin-bottom: 0;
  }
}

.headerContentRight {
  flex-direction: column;
  width: 100%;

  @include breakpoint(md, true) {
    width: auto;
    flex-direction: row;
    margin-bottom: 0;
  }
}
.headerInfoBlock {
  margin-right: toRem(16);
  margin-bottom: toRem(16);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include breakpoint(md, true) {
    margin-bottom: toRem(0);
  }
  gap: toRem(8);
  .expireDate {
    display: flex;
    align-items: center;
    p {
      margin-right: toRem(8);
    }
  }
}

.headerContentRight {
  button {
    width: 100%;
    @include breakpoint(md, true) {
      width: auto;
    }
  }

  .licenseDownload {
    display: flex;
    margin-right: toRem(16);
    align-items: center;
    margin-bottom: toRem(16);
    cursor: pointer;
    @include breakpoint(md, true) {
      margin-bottom: toRem(0);
    }
    span {
      font-size: toRem(24);
    }
  }
}

.body {
  padding: toRem(16);
  background-color: white;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  @include breakpoint(md, true) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.bodyContentLeft {
  text-align: left;
  @include breakpoint(md, true) {
    flex: 1 1 66%;
  }
  .title {
    font-size: toRem(16);
  }

  .description {
    margin-top: toRem(8);
    height: toRem(28);
    display: flex;
    align-items: center;
    form {
      width: 100%;
      max-width: toRem(400);
      > div {
        margin: 0;
      }
    }
    p {
      overflow-wrap: anywhere;
    }
    span {
      margin-right: toRem(8);
      cursor: pointer;
      font-size: toRem(16);
    }
  }
}

.bodyContentRight {
  margin-top: toRem(16);
  @include breakpoint(md, true) {
    flex: 1 1 33%;
    margin-top: 0;
  }
  button {
    width: 100%;
    margin-top: toRem(8) !important;
  }
  &.flexItem {
    display: flex;
    gap: toRem(12);
    flex: 1 1 33%;
  }

  .errors {
    text-align: center;
    margin-top: 0.4rem;
    p {
      color: $red;
      font-size: 0.6rem;
      line-height: 1;
      font-weight: 500;
    }
  }
}
.spinnerWrap {
  margin-top: toRem(8);
  display: flex;
  justify-content: center;
}

.activationWrap {
  &.confirmBoxVisible {
    max-width: toRem(184);
  }
  &.fullWidth {
    width: 100%;
    max-width: 100%;
  }
  .title {
    display: flex;
    align-items: center;
    p {
      font-weight: 700;
      margin-right: toRem(4);
    }
    span {
      font-size: toRem(16);
    }
  }
}

.rightSide {
  display: flex;
  align-items: center;
  gap: toRem(4);
  span {
    line-height: 0.9rem;
    cursor: pointer;
  }
}

.input {
  position: relative;

  > span {
    position: absolute;
    top: 57%;
    opacity: 0.5;
    left: toRem(16);
    transform: translateY(-50%);
  }

  input {
    &.error {
      border-color: $red;
    }
  }
  span + input {
    padding-left: toRem(120);
  }

  input + .copy {
    right: toRem(16);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    position: absolute;

    span {
      font-size: toRem(16);
      background-color: $light;
    }
  }
}
