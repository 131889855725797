@import '@styles/basic';

.item {
  margin-bottom: toRem(16);
}
.container {
  width: 100%;
  > h2 {
    text-align: center;
    margin-bottom: 32px;
  }
  > h4 {
    font-size: toRem(24);
    padding-bottom: toRem(16);
  }
  .total {
    font-size: toRem(24);
  }
}

.inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: toRem(16);
}

.productItem {
  margin-bottom: 16px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  padding: toRem(24);
  border: 1px solid rgb(11, 40, 78, 0.25);
  border-radius: 16px;
  flex-direction: column;
  > div strong {
    margin-bottom: toRem(8);
  }

  .currency {
    flex-shrink: 0;
  }

  @include breakpoint(xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}
