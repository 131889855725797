@import '@styles/basic';

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}
.disabled {
  pointer-events: none;
  p {
    opacity: 0.5;
  }
}
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #aaa;
}

.checkmarkError {
  border: 1px solid $red; 
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $blue;
  border: 1px solid $blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:disabled ~ .checkmark {
  border: 1px solid rgba(11, 40, 78, 0.25);
  background: linear-gradient(
      0deg,
      rgba(11, 40, 78, 0.1),
      rgba(11, 40, 78, 0.1)
    ),
    #ffffff;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 2px;
  width: 6px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
