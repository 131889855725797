@import '@styles/basic';

.container {
  margin-top: 2.04rem;
  text-align: left;
  width: 100%;
  max-width: 100%;
  background: $light;
  border: 1px solid transparentize($dark, 0.75);
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  & > div:nth-child(3) > div > p + div:global(.active) {
    border-top: 1px solid rgba(11, 40, 78, 0.25) !important;
  }

  & > div > div > p {
    padding: 0;
  }
}

.inputWrapper {
  margin-bottom: toRem(8);
}
