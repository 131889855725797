@import '@styles/basic';
.container {
  display: flex;
  flex-direction: column;
  transition: all ease-in-out 0.3s;

  width: 100%;
  max-width: 100%;

  .errors {
    text-align: center;
    margin: 24px 0;
    p {
      color: $red;
      font-size: 0.6rem;
      line-height: 1;
      margin-top: 0.4rem;
      font-weight: 500;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    button {
      margin-top: 24px;
    }

    .ctaContainer {
      margin-top: 16px;
      padding-bottom: 8px;
      a {
        display: block;
        &:not(:last-of-type) {
          margin-bottom: 4px;
        }
      }
    }
  }
}
