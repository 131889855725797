@import '@styles/basic';

.container {
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
    margin-left: 4px;
    + p {
      font-size: toRem(10);
      opacity: 0.8;
      font-weight: 600;
      margin-left: toRem(4);
    }
  }
}
