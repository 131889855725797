@import '@styles/basic';

.header {
  text-align: center;
  margin-bottom: 2.4rem;

  &>div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .selectDuration {
    display: inline-flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;

    &>button {
      padding: 7px 15px;
      min-width: 123px;
    }
  }

  .titleSection {
    &.blue {
      color: $blue;
    }
  }

  h1 {
    padding-top: 1.2rem;
    margin-bottom: 0.8rem;
    font-weight: 500 !important;

    @media screen and (max-width: 1190px) {
      font-size: 40px;
    }

    &+p {
      margin-bottom: 2.3rem !important;
    }
  }
}

.cardWrap {
  display: grid;
  justify-content: center;
  width: 100%;
  grid-gap: 1.2rem;

  &.grid1 {
    grid-template-columns: repeat(1, minmax(16rem, 19.05rem));

    @include breakpoint(lg) {
      max-width: 19.05rem;
      grid-template-columns: 1fr;
    }
  }

  &.grid2 {
    grid-template-columns: repeat(2, minmax(16rem, 19.05rem));

    @include breakpoint(lg) {
      max-width: 19.05rem;
      grid-template-columns: 1fr;
    }
  }

  &.grid3 {
    grid-template-columns: repeat(3, minmax(16rem, 19.05rem));

    @include breakpoint(lg) {
      max-width: 19.05rem;
      grid-template-columns: 1fr;
    }
  }

  @include breakpoint(lg) {
    max-width: 19.05rem;
    grid-template-columns: 1fr;
  }
}

.tableWrap {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  &>div {
    width: 100%;
  }
}

.showDowngradesBtn {
  text-align: center;
  margin-top: 1rem;
  text-decoration: underline;
  font-size: 0.9rem;
  cursor: pointer;
}