@import '@styles/basic';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: toRem(512);
  margin-bottom: toRem(32);
  text-align: center;
  h2 {
    font-weight: 700;
    font-size: toRem(24);
    line-height: toRem(30);
    margin-bottom: toRem(16);
  }

  p {
    max-width: toRem(340);
    font-weight: 400;
    font-size: toRem(16);
    line-height: toRem(24);
  }

  &.suspend {
    max-width: 100%;
    p {
      max-width: 100%;
      font-weight: 500;
      font-size: toRem(16);
      line-height: toRem(26);

      & > span {
        color: $blue;
        cursor: pointer;
      }
    }
  }
}

.content {
  border: 1px solid rgba($dark, 0.25);
  border-radius: 15px;
  padding: toRem(24) toRem(16);
  margin-bottom: toRem(24);
  .title {
    font-weight: 600;
    font-size: toRem(12);
    line-height: toRem(15);
    color: rgba($dark, 0.5);
    margin-bottom: toRem(6);
  }
  .productName {
    margin-bottom: toRem(6);
  }

  .note {
    font-weight: 400;
    font-size: toRem(12);
    line-height: toRem(18);
    margin-bottom: toRem(16);
  }

  .details {
    display: flex;
    gap: toRem(16);
    font-weight: 400;
    font-size: toRem(12);
    line-height: toRem(18);
    & > div {
      display: flex;
      flex-direction: column;

      & > span:first-of-type {
        color: rgba($dark, 0.5);
      }
    }
  }
}

.footer {
  display: flex;
  gap: toRem(16);
}
