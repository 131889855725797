@import '@styles/basic';

.container {
  background-color: white;
  text-align: left;
  padding: 16px 0;
  border-radius: 16px;
  border: 1px solid rgb($dark, 0.2);
}

.title {
  padding-left: 16px;
}
.summaryRowTotal {
  margin-bottom: 0;
  line-height: 1;
  margin-top: 24px;
}

.listContainer {
  overflow-y: auto;
  max-height: 500px;
  padding: 0 16px;
}

.containerEmpty {
  width: 100%;
  vertical-align: middle;
  padding: 25% 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    color: rgb($dark, 0.5);
    font-size: 24px;
    line-height: 3;
  }
}

.summary {
  padding: 0 16px !important;
}

.noMargin {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.signupText {
  text-align: center;
  p {
    font-size: toRem(14);
    line-height: toRem(24);
  }
}
