@import '@styles/basic';

.container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  flex-wrap: wrap;
}

.element {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid rgba($dark, 0.4);
  justify-content: center;
  width: toRem(32);
  height: toRem(32);
  border-radius: toRem(32);
  color: $dark;
  margin: 0 toRem(8);
  font-size: toRem(14);
}

.active {
  background-color: rgba($dark, 0.5);
  border: 1px solid rgba($dark, 0.25);
  pointer-events: none;
  color: white;
}

.next {
  transform: rotate(-90deg);
}

.prev {
  transform: rotate(90deg);
}
