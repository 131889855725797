@import '@styles/basic';

.container {
  border: 0;
  padding: 0;
  margin: 0;
}

.radioContainer {
  display: flex;
  margin-bottom: 24px;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}
.icons {
  display: flex;
  align-items: center;
  img {
    margin: 0 4px;
    &:first-child {
      margin-left: 0;
    }
  }
  p {
    font-size: toRem(16);
    margin-right: 4px;
  }
}

/* Hide the browser's default radio button */
.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #aaa;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .checkmark {
  background-color: $blue;
  border: 1px solid $blue;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .checkmark:after {
  top: 7px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
