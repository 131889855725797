@import '@styles/basic';

.container {
  width: 100%;
  min-width: auto;
  margin-bottom: toRem(16);

  &.horizontal:not(:last-of-type) {
    border-bottom: none;

    @include breakpoint(md, true) {
      border-bottom: 1px solid rgba(11, 40, 78, 0.25);
    }
  }

  &.horizontal {
    @include breakpoint(md, true) {
      padding-top: toRem(8);
      padding-bottom: toRem(34);
      display: flex;
      flex-direction: column;
    }

    &>div {
      @include breakpoint(md, true) {
        display: flex;
        justify-content: space-between;
      }

      &>input {
        align-self: flex-start;

        @include breakpoint(md, true) {
          max-width: toRem(500);
        }
      }

      &>label {
        font-size: toRem(16);
        font-weight: 600;
        margin-right: 0;
        line-height: toRem(26);

        @include breakpoint(md, true) {
          min-width: 40%;
          margin-left: 0 !important;
        }
      }
    }
  }

  .inputLabel {
    font-size: toRem(14);
    line-height: toRem(18);
    color: $dark;
    margin-left: toRem(8);
    font-weight: 600;
    margin-bottom: toRem(8);
    display: block;
    text-align: left;
  }

  .inputGroup {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }

  .inputGroupPrefix {
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 0.8rem;
    padding: 0.5rem 1.2rem;
    line-height: 1.2rem;
    background-color: $catskill-white;
    border-top: 1px solid transparentize($dark, 0.75);
    border-bottom: 1px solid transparentize($dark, 0.75);
    border-left: 1px solid transparentize($dark, 0.75);
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    outline: transparent;
  }

  input {
    width: 100%;
  }

  .inputWithPrefix {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    border-top: 1px solid transparentize($dark, 0.75);
    border-right: 1px solid transparentize($dark, 0.75);
    border-bottom: 1px solid transparentize($dark, 0.75);
    border-radius: initial;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .inputHelp {
    font-size: toRem(14);
    line-height: toRem(18);
    color: $dark;
    margin-top: toRem(8);
    margin-left: toRem(8);
    margin-bottom: toRem(8);
    display: block;
    text-align: left;
  }

  .error {
    border-color: $red;
  }

  .horizontalError {
    font-weight: 500;

    @include breakpoint(md, true) {
      margin-right: toRem(8);
      align-self: flex-end;
      width: calc(60% - 22px);
    }
  }

  p {
    font-size: toRem(14);
    margin-top: toRem(4);
    text-align: left;
    color: $red;
  }
}