@import '@styles/basic';

.container {
  text-align: center;
  max-width: toRem(595);
  width: 100%;
  &.showSide {
    @include breakpoint(xl, true) {
      padding-right: toRem(40);
    }
  }
}

.registerCta {
  margin-top: 1.2rem !important;
  text-align: center;
  width: 100%;
  max-width: 100%;
  background: $light;
  border: 1px solid transparentize($dark, 0.75);
  border-radius: 15px;
  padding: 0.9rem;

  .loginCta {
    display: inline;
    font-size: 0.8rem;
    line-height: 26px;
    font-weight: 600;
    color: $blue;
    cursor: pointer;
  }
}
