@import '@styles/basic';

.container {
  display: flex;
  text-align: left;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid rgb(11, 40, 78, 0.25);
  border-radius: 16px;
  p {
    font-size: 16px;
    margin: 0;
  }
}

.topLeftContent {
  margin-bottom: 16px;
}
.rightContent {
  align-items: center;
  display: flex;
}

.description {
  padding-right: 16px;
}

.staticQuantity {
  font-weight: 700;
  color: $blue;
}
