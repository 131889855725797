@import '@styles/basic';

.container {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  .tableWrapper {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: $dark;

    table.basicTable {
      width: 100%;
      table-layout: fixed;
      background: $light;
      box-shadow: 0px 2px 0px rgba(11, 40, 78, 0.05);

      &.uranium {
        @media screen and (max-width: 1200px) {
          width: 900px;
        }
      }

      &.noBorder {
        border-collapse: collapse;
        background: transparent;
      }

      thead {
        background-color: $light;
        tr {
          th {
            font-size: 0.8rem;
            font-weight: 600;
            padding: 0.4rem 0.7rem;
            text-align: left;

            &:first-child {
              width: 320px;
            }
          }
        }
      }

      tbody {
        tr {
          box-shadow: 0px 2px 0px rgba(11, 40, 78, 0.05);

          @media screen and (max-width: 1200px) {
            background: #ffffff !important;
          }

          &.titleRow {
            background-color: $catskill-white !important;

            td {
              position: sticky;
              left: 0;
              p {
                padding: 0.4rem;
                min-width: 320px;
                width: 100%;
                font-weight: 400;
                font-size: 0.7rem;
                line-height: 24px;
              }
            }
          }

          td {
            &.hideFirstColumn:first-child {
              display: none;
            }

            padding: 0.5rem 0.8rem;
            font-size: 0.7rem;
            font-weight: 500;
            text-align: left !important;
            padding: 0.5rem 0.7rem;
            &:first-child {
              @media screen and (min-width: 1200px) {
                width: 320px;
              }
            }
            & > p {
              font-size: 0.7rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
