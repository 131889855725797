@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";

.swiper {
  --swiper-pagination-bullet-width: 0.8rem;
  --swiper-pagination-bullet-height: 0.8rem;
  --swiper-pagination-bullet-inactive-color: #d9d9d9;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-color: #{$dark};
  --swiper-theme-color: #{$dark};

  margin: 0.6rem -0.6rem;

  &-wrapper {
    padding: 1.2rem 0;
  }

  &-slide {
    margin: 0 0.6rem;
    width: calc(33.33% - 1.2rem);
  }

  &-pagination {
    position: static;
    margin: 0.6rem 0;
  }
}
