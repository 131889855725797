@import '@styles/basic';
.container {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  a.forgetPass {
    margin-top: toRem(14);
    display: inline-block;
  }

  .recaptcha {
    display: flex;
    justify-content: center;
    margin: toRem(16) 0;
  }
}

.error {
  color: $red;
  font-weight: 500;
  line-height: toRem(18);
  font-size: toRem(14);
  line-height: 1;
  margin-top: toRem(16);
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $success;
  font-weight: 400;
  font-size: toRem(14);
  line-height: toRem(24);
  margin-top: toRem(16);

  span {
    margin-left: toRem(8);
  }
}
