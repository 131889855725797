@import '@styles/basic';

.container {
  background: $light;
  box-shadow: 0px 4px 16px rgba(181, 181, 181, 0.25);
  border-radius: 30px;
  padding: 1.2rem 1.2rem 1.8rem 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 19.8rem;

  &.isHorizontal {
    margin-bottom: 24px;
    flex-direction: row;
    width: 100%;
    padding: 1.9rem 2.4rem;
    max-width: 100%;

    justify-content: space-between;
    @include breakpoint(lg) {
      flex-direction: column;
      padding: 1.2rem 1.2rem 1.8rem 1.2rem;
    }
  }
  &.active {
    border: 2px solid $blue;
    padding-top: 22px;
  }

  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.isHorizontal {
      flex-direction: row;
      align-items: center;
      display: flex;
      width: unset;

      @include breakpoint(lg) {
        flex-direction: column;
      }
      & > .header {
        display: flex;
        flex-direction: column;

        &.ausilioMaxWidth {
          & > img {
            margin-bottom: 8px;
            max-width: 347px;
          }
        }

        .descriptionHorizontal {
          font-size: 14px !important;
          font-weight: 400;
          line-height: 24px !important;
          max-width: 327px !important;
        }
        img {
          margin-bottom: 0;
          flex-shrink: 0;
        }
        &.maxWidth {
          max-width: 16.4rem;
        }
        margin-right: 1.8rem;
        text-align: left;

        @include breakpoint(lg) {
          margin-right: 0;
          margin-bottom: 24px;
        }
        & > p {
          margin-bottom: 0 !important;
          max-width: 100% !important;
        }
      }

      & > .price {
        display: flex;

        align-items: baseline;
        margin-bottom: 0;
        text-transform: lowercase;
        width: auto;

        & > span {
          margin-left: 0 !important;

          &.perMonth {
            align-self: auto;
          }
        }
      }
    }

    .header {
      text-align: center;
      .logoProduct {
        margin-bottom: 0.8rem;
      }
      .savingsHorizontal {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px !important;
        @include breakpoint(lg) {
          flex-direction: column;
        }
        & > div {
          @include breakpoint(lg) {
            order: -1;
            margin-bottom: 16px !important;
          }
        }
      }
      h4.version {
        margin-bottom: 0.4rem;

        & > strong {
          color: $yellow;
        }
      }

      .savings {
        display: inline-flex;
        padding: 8px;
        border-radius: 100px;
        background-color: rgba($light-red, 0.05);
        margin-bottom: 16px;
        align-self: center;
        p {
          color: $red2;
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
        }
      }
    }

    .basePrice {
      font-weight: 600;
      font-size: 0.8rem;
      line-height: 1.3rem;
      margin-bottom: 0.8rem;
      color: $blue;
    }
    .price {
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 1.9rem;
      margin-bottom: 0.4rem;
      color: $blue;
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: center;

      &.flexCenter {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @include breakpoint(md, true) {
        flex-direction: row;
      }
      span.perMonth {
        margin-left: 0px;
        font-size: 1rem;
        display: flex;
        line-height: 1.4rem;
        align-self: center;

        @include breakpoint(md, true) {
          align-self: flex-end;
          margin-left: 8px;
        }
      }
    }

    .perYear {
      font-weight: 400;
      font-size: 0.7rem;
      line-height: 0.9rem;
      color: rgba($dark, 0.5);
      margin-bottom: 0.8rem;
      text-align: center;
    }

    .options {
      width: 100%;

      .assistance {
        height: 46px;
        padding-bottom: 0.6rem;
        border-bottom: 1px solid rgba(11, 40, 78, 0.25);
      }

      .nameVersions {
        font-weight: 600;
        color: rgba($dark, 1) !important;
      }

      .connections {
        min-height: 35px;
        margin-top: 0.6rem;

        &.noMargin {
          margin-top: 0;
          padding-bottom: 0.6rem;
          border-bottom: 1px solid rgba(11, 40, 78, 0.25);
          height: 46px;
        }
      }
      .assistance,
      .connections {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p.single {
          color: $dark !important;
        }
        p {
          display: flex;
          align-items: center;
          font-size: 0.7rem;
          font-weight: 600;
          line-height: 0.9rem;
          margin-bottom: 0;
          &:nth-child(2) {
            color: rgba($dark, 0.5);
          }
          .info {
            color: rgba($dark, 0.5);
            font-size: 0.75rem;
            margin-left: 0.4rem;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.2rem;

    &.lessMargin {
      margin-top: 0.6rem;
    }
    p.description {
      white-space: pre-line;
      text-align: center;
      margin-bottom: 24px;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1.2rem;
      max-width: 300px;
      margin-top: 24px;
      min-height: 45px;
      @media screen and (max-width: 1126px) {
        min-height: 68px;
      }

      @include breakpoint(lg) {
        min-height: 45px;
      }
    }

    ul.featuresList {
      margin-left: 1rem;
      text-align: left !important;
      li.featuresItem {
        color: $dark;
        margin-bottom: 0.65rem;
        font-size: 0.7rem;
        font-weight: 500;
        line-height: 0.9rem;
        &::before {
          position: absolute;
          margin-top: 1px;
          margin-left: -23px;
          content: url(../../assets/images/tick_green.svg);
        }
      }
    }
    &.isHorizontal {
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 0;
      gap: 1.2rem;
      width: unset;
      @include breakpoint(lg) {
        flex-direction: column;
        width: 100%;
      }
      .quantity {
        margin-bottom: 0;
        justify-content: flex-end;
        width: auto;
        gap: 1.8rem;
        @include breakpoint(lg) {
          width: 100%;
        }
        &.flexEnd {
          justify-content: flex-end;
        }
        & + button {
          flex-shrink: 0;
        }
        div {
          .counter {
            margin-right: 1.8rem;
          }
          &:nth-of-type(2) > p.total {
            margin-left: 0 !important;
            margin-right: 0;
          }
        }
      }
      @include breakpoint(lg) {
        button {
          flex: 1;
          width: 100%;
        }
      }
    }
    .quantity {
      margin-bottom: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &.flexEnd {
        justify-content: flex-end;
      }
      div {
        display: flex;
        align-items: center;
        p {
          font-size: 0.7rem;
          line-height: 1.3rem;
          font-weight: 600;
          margin-right: 0.3rem;
          margin-bottom: 0;
        }

        @include breakpoint(xs, true) {
          p {
            font-size: 0.8rem;
          }
        }

        .counter {
          border: 1px solid rgba($dark, 0.25);
          background-color: $light;
          border-radius: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.45rem 0.4rem;
          span {
            font-size: 0.7rem;
            font-weight: 600;
            line-height: 0.9rem;
            margin: 0 0.8rem;
          }
          button {
            background-color: transparent;
            color: $dark;
            border: none;
            padding: 0;

            i {
              font-size: 0.7rem;
              font-weight: 500;
            }
          }
        }

        .total {
          font-size: 0.9rem;
          line-height: 1.5rem;
          font-weight: 700;
          margin-bottom: 0.6rem;
          margin-bottom: 0;
          color: $dark;
        }

        @include breakpoint(xs, true) {
          .total {
            font-size: 1.2rem;
          }
        }
      }
    }

    .futureExpiration {
      text-align: center;
      margin-top: toRem(16);
      & > p {
        font-size: toRem(14);
        line-height: toRem(18);
        font-weight: 600;
      }
    }
  }
}
