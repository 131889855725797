@import '@styles/basic';

.blocks {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  gap: toRem(16);

  @include breakpoint(md, true) {
    grid-template-columns: repeat(2, 1fr);
    gap: toRem(24);
  }
  @include breakpoint(lg, true) {
    grid-template-columns: repeat(3, 1fr);
  }
  .card {
    padding: toRem(16) toRem(8);
    cursor: pointer;
  }
  span {
    color: $blue;
    font-size: toRem(40);
    font-weight: 300;
  }
  h4 {
    font-size: toRem(24);
  }
  p {
    width: 95%;
  }
  .discountLabel {
    color: $blue;
    font-size: 0.7rem;
    font-weight: bold;
  }
}

.dashboardGuideLink {
  margin-top: toRem(40);
  color: $blue;
  font-size: 0.8rem;
  font-weight: bold; 
}

.alert {
  margin: toRem(32) 0;
}

.alertContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  div {
    font-size: toRem(16);
    margin-top: toRem(16);
    margin-left: 0;
  }
  @include breakpoint(md, true) {
    flex-direction: row;
    div {
      margin-top: 0;
      margin-left: toRem(4);
      a {
        text-decoration: underline;
      }
    }
  }
}
