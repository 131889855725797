@import '@styles/basic';

.error {
  color: $red;
  font-weight: 500;
  line-height: 0.9rem;
  font-size: 0.7rem;
  line-height: 1;
  margin-top: 0.4rem;
}
.inputLabel {
  font-size: toRem(14);
  line-height: toRem(18);
  color: $dark;
  margin-left: toRem(8);
  font-weight: 600;
  margin-bottom: toRem(8);
  display: block;
}
.inputWrapperContainer {
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  display: flex;
  line-height: 1.2rem;
  margin-bottom: 0.7rem;
  margin-top: 0.7rem;

  &.userType {
    flex-direction: column;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    align-items: flex-start;
  }
  .inputWrapper {
    display: flex;
    margin-bottom: 0;

    &.userType {
      flex-direction: column;
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
      .inputRadio {
        margin-bottom: 0.7rem;
      }
    }

    .inputRadio {
      display: flex;
      &.isPa {
        &:first-child {
          margin-right: 0.7rem;
        }
      }
    }
  }
}
.inputHelp {
  font-size: toRem(14);
  line-height: toRem(18);
  color: $dark;
  margin-top: toRem(8);
  margin-left: toRem(8);
  margin-bottom: toRem(8);
  display: block;
}
