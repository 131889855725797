.wrap {
  display: flex;
  width: 88vw;
  width: calc(100vw - 96px);
  max-width: 1340px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding-left: 3.75rem;
  padding-right: 3.75rem;

  @include breakpoint(xxl) {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }

  @include breakpoint(xl) {
    padding-left: 2.6rem;
    padding-right: 2.6rem;
  }

  @include breakpoint(lg) {
    padding-left: 0;
    padding-right: 0;
  }

  &--extended {
    padding: 0;
  }
}

.mobile-only {
  @include breakpoint(lg, true) {
    display: none !important;
  }
}

.custom-mobile-nav {
  @include breakpoint(custom, true) {
    display: none !important;
  }
}

.desktop-only {
  @include breakpoint(lg) {
    display: none !important;
  }
}

.dark-section {
  color: $light;
}
