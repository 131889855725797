.section-cta {
  text-align: center;
  overflow: hidden;

  &__text {
    margin: 0 auto;

    @include breakpoint(sm) {
      order: 4;
    }
  }

  &__bg {
    width: 10%;
    margin: 0 2rem;
    position: relative;

    @include breakpoint(sm) {
      width: 48%;
      height: 38vw;
      margin: 0;
    }

    span {
      position: absolute;
      width: 5.8rem;
      height: 5.8rem;
      max-width: 16vw;
      max-height: 16vw;
      right: 0;
      top: 20%;

      img {
        border-radius: 0.8rem;
        filter: drop-shadow(0px 4px 16px transparentize(rgb(181 181 181), 0.75));
        position: relative;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        top: -60%;
        right: 20%;
        background-image: url("../../images/cta-poly.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 175%;
        padding-bottom: 130%;
      }
    }

    &:last-child {
      span {
        width: 8.6rem;
        height: 8.6rem;
        max-width: 26vw;
        max-height: 26vw;
        right: auto;
        top: auto;
        left: 0;
        bottom: 0;

        img {
          border-radius: 2.5rem;
        }

        &::after {
          width: 100%;
          background-image: url("../../images/cta-lines.svg");
          right: auto;
          left: 45%;
        }
      }
    }
  }

  &__wrap {
    border: 1px solid transparentize($dark, 0.75);
    border-radius: 2.5rem;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    max-width: 1090px;

    @include breakpoint(sm, true) {
      flex-wrap: nowrap;
    }

    @include breakpoint(xxl) {
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
    }

    @include breakpoint(xl) {
      padding-top: 2.6rem;
      padding-bottom: 2.6rem;
    }

    @include breakpoint(lg) {
      background: none;
    }

    @include breakpoint(xs) {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }

    @include breakpoint(xxs) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  .wp-block-buttons {
    justify-content: center !important;
  }
}
