@import '@styles/basic';

.header {
  margin-bottom: toRem(16);
  display: block;
}

.container {
  text-align: center;
  width: 100%;
  max-width: toRem(556);
  margin-top: toRem(32);
}
.block {
  width: 100%;
  text-align: center;
  background: $light;
  border: 1px solid transparentize($dark, 0.75);
  border-radius: 16px;
  padding: toRem(32) toRem(24);
}

.blockContent {
  text-align: left;
  img {
    width: 100px;
  }
  p {
    font-size: toRem(24);
  }
}

.blockContentRight {
  display: flex;
  justify-content: flex-end;
  margin-top: toRem(16);
  span {
    font-size: toRem(24);
    cursor: pointer;
  }
}
.lead {
  font-size: toRem(24);
  color: rgba($dark, 0.5);
}

.errors {
  text-align: center;
  margin-top: 0.4rem;
  p {
    color: $red;
    font-size: 0.6rem;
    line-height: 1;
    font-weight: 500;
  }
}

.error {
  margin-top: toRem(24);
  p {
    color: $red;
  }
}
