@import '@styles/basic';

.faqContainer {
  max-width: 38.9rem;
  text-align: center;
  white-space: pre-line;
}

.seeAll {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: $blue;
  &:hover {
    cursor: pointer;
  }
}
.answer {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 16px;

  a {
    color: $blue;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include breakpoint(xs) {
  .faqContainer {
    padding-top: 40px;
    margin-bottom: 48px;
  }
}
