@import '@styles/basic';

.title {
  margin-bottom: 3rem;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(20.9rem, 24.1rem));
  justify-content: center;
  grid-gap: 23px;
  width: 100%;
  @include breakpoint(lg) {
    max-width: 24.1rem;
    grid-template-columns: 1fr;
  }
}
