@import '@styles/basic';

.cardContent {
  h4 {
    margin-bottom: toRem(4);
    font-size: toRem(16);
  }
  p,
  strong {
    font-size: toRem(12);
  }
}
.blueText {
  color: $blue;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: toRem(8) 0;
  border-bottom: 1px solid rgba($dark, 0.4);
}

.cta {
  cursor: pointer;
}

.cardCta {
  margin-top: toRem(8);
  border-radius: toRem(16);
  border: toRem(1) solid rgba($dark, 0.2);
  padding: toRem(24);
  background-color: rgba(45, 156, 219, 0.05);
  text-align: center;
  button {
    width: 100%;
  }
  p {
    font-size: toRem(16);
  }
}
