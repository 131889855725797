input,
textarea,
select {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 0.8rem;
  padding: 0.5rem 1.2rem;
  line-height: 1.2rem;
  border: 1px solid transparentize($dark, 0.75);
  border-radius: 100px;
  outline: transparent;

  &::placeholder {
    color: transparentize($dark, 0.5);
  }

  &:focus {
    border-color: transparentize($dark, 0.5);
  }
}

.container-input {
  margin-bottom: 0.7rem;
  .wrapper-input {
    input {
      box-shadow: 0 0 0 40px #fff inset !important;
      color: $dark;
      &.full {
        width: 100%;
      }

      &.big {
        padding: 0.5rem 1.2rem;
      }
      &.small {
        padding: 0.3rem 0.8rem;
        font-size: 14px;
        font-weight: 500;
      }
      &.disabled {
        background: linear-gradient(
            0deg,
            rgba(11, 40, 78, 0.1),
            rgba(11, 40, 78, 0.1)
          ),
          #ffffff;
        border: none !important;
        box-shadow: none !important;
        color: ($dark, 0.5);
        padding-top: 0.55rem;
        padding-bottom: 0.55rem;
      }
    }
  }

  .error-input {
    & + p {
      color: $red;
      font-weight: 500;
      line-height: 0.9rem;
      font-size: 0.7rem;
      line-height: 1;
      margin-top: 0.4rem;
      text-align: left;
    }
    input {
      border-color: $red;
      &::placeholder {
        color: $red;
      }
    }
  }
}

.input-acceptance,
.input-checkbox,
.input-radio {
  label {
    user-select: none;
    cursor: pointer;

    input {
      opacity: 0;
      position: absolute;
      width: 2px;
      height: 2px;

      &:checked {
        + span {
          &::before {
            background: $blue;
            border-color: $blue;
            color: $light;
          }
        }
      }
    }

    > span {
      display: flex;
      align-items: center;
      font-weight: 500;

      &::before {
        content: "\f755";
        text-align: center;
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        font: normal normal normal 1rem/1 Phosphor, sans-serif;
        border: 2px solid transparentize($dark, 0.75);
        color: transparent;
        border-radius: 0.2rem;
        margin-right: 0.6rem;
        flex-shrink: 0;
      }
    }
  }
}

.input-radio {
  label {
    > span {
      &::before {
        content: "\fb72";
        font-size: 0.5rem;
        line-height: 2;
        border-radius: 50%;
      }
    }
  }
}

.input-list {
  margin-bottom: 0.3rem;
  display: block;

  &:only-child {
    margin-bottom: 0;
  }
}

button {
  @extend .cta;

  font-family: 'Plus Jakarta Sans', sans-serif;
  cursor: pointer;
}
