@import '@styles/basic';

.header {
  margin-bottom: toRem(16);
  display: block;
}

.container {
  text-align: center;
  width: 100%;
  margin-top: toRem(32);

  gap: toRem(16);
  display: flex;
  flex-direction: column;
  @include breakpoint(md, true) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .formContainer {
    grid-column: 2 / -1;
    border: 1px solid rgba(11, 40, 78, 0.25);
    border-radius: toRem(15);
    padding: toRem(16);
    background-color: $light;
  }
}
