.custom {
  padding: 0 !important;

  &--extended {
    @include breakpoint(lg) {
      background: var(--bg);
    }

    .custom__wrap {
      border-radius: 2.5rem;
      background: var(--bg);

      @include breakpoint(lg) {
        background: none;
      }
    }
  }

  &__container {
    background: var(--bg);
    border-radius: 2.5rem;
    display: block;
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include breakpoint(lg) {
      padding-top: 3.2rem;
      padding-bottom: 3.2rem;
    }

    @include breakpoint(sm) {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }
  }
}
