@import '@styles/basic';

.container {
  margin-bottom: toRem(24);

  .licenseToRenew {
    background: $blue;
    line-height: 2;
    text-align: center;
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    font-size: 0.6rem;
    font-weight: 700;
    color: #fff;
    border-radius: 50%;
    margin-left: 6px;
  }

  .topSide {
    padding-top: toRem(10);
    padding-bottom: toRem(16);
    padding-left: toRem(4);
    border-bottom: 1px solid #dadada;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(md, false) {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .buttons {
      display: flex;
      align-items: center;

      gap: toRem(6);

      flex-wrap: wrap;

      @include breakpoint(xs, false) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .exportCsv {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: toRem(6);

      p {
        font-size: toRem(12);
        font-weight: 400;
        line-height: toRem(18);
      }
    }
  }
  .bottomSide {
    @include breakpoint(md, false) {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .selectWrapper {
      @include breakpoint(md, false) {
        width: 100%;
      }
      div {
        @include breakpoint(md, false) {
          width: 100%;
        }
      }
    }

    p {
      font-size: toRem(12);
      font-weight: 400;
      line-height: toRem(18);
      margin-bottom: 0;
    }
    div > select {
      font-weight: 400 !important;
      background-color: transparent !important;
    }

    display: flex;
    align-items: center;

    gap: toRem(8);
    padding: toRem(22) toRem(4);
  }
}
