@import '@styles/basic';

.wrapper {
  border: 1px solid rgba($dark, 0.5);
  border-radius: 6px;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.container {
  height: 100%;
  box-shadow: 0px 2px 0px rgba(11, 40, 78, 0.05);
  background-color: $light;
  overflow: auto;

  table {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    text-indent: 0;
  }

  th,
  td {
    border-style: solid;
    border-color: rgba(11, 40, 78, 0.05);
    background-color: #fff;
    isolation: isolate;
    text-align: left;
    border-width: 0 0 1px 0;
  }

  th {
    border-width: 0 0 2px 0;
  }

  td {
    vertical-align: middle;
  }
}

.asc,
.desc {
  background-color: $catskill-white;
}

.header {
  display: flex;
  align-items: center;
  padding: 15px 16px;

  &.sortable {
    cursor: pointer;
    user-select: none;
  }
}

.colName {
  display: flex;
  width: 100%;
  & + i {
    margin-left: 8px;
  }
  span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.cell {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  tr:hover .asc &,
  tr:hover .desc & {
    mix-blend-mode: multiply;
  }
}

.smallRows .cell {
  height: 48px;
}
