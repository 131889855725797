@import '@styles/basic';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @include breakpoint(md) {
    flex-direction: column;
  }
  .leftColumn {
    width: 100%;
    @include breakpoint(md, true) {
      width: 46%;
    }

    img {
      @include breakpoint(md) {
        max-width: 75%;
      }
    }

    &.isInverted {
      order: 2;
      @include breakpoint(md) {
        order: -1;
      }
    }
    .description,
    img,
    h2 {
      margin-bottom: 1.2rem;
    }

    .description {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.7rem;
    }
  }

  .rightColumn {
    font-size: 0;
    position: relative;

    @include breakpoint(md, true) {
      width: 47%;
    }

    @include breakpoint(md) {
      margin-top: 2.5rem;
    }

    img,
    video {
      border-radius: 1.5rem;

      @include breakpoint(sm) {
        border-radius: 0.75rem;
      }
    }

    div {
      bottom: 0.9rem;
      max-width: calc(100% - 2rem);
      position: absolute;
      right: 1rem;
    }
  }
}
