@import '@styles/basic';

.container {
  form {
    text-align: left;
  }
}
.error {
  color: $red;
  font-weight: 500;
  line-height: 0.9rem;
  font-size: 0.7rem;
  line-height: 1;
  margin-top: 0.4rem;
}
