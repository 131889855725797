@import '@styles/basic';

.header {
  text-align: center;
  margin-bottom: 1.6rem;

  &.compare {
    margin-top: -1.2rem;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .selectDuration {
    display: inline-flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
    & > button {
      padding: 7px 15px;
      min-width: 123px;
    }
  }
  .titleSection {
    &.blue {
      color: $blue;
    }
  }

  h1 {
    margin: 0;
    margin-bottom: 1.6rem;
    font-size: 48px;
    @media screen and (max-width: 1190px) {
      font-size: 40px;
    }
    & + p {
      margin-bottom: 2.3rem !important;
    }
  }
}

.cardWrap {
  display: grid;
  grid-template-columns: repeat(3, minmax(16rem, 19.05rem));
  justify-content: center;
  width: 100%;
  grid-gap: 1.2rem;

  @include breakpoint(lg) {
    max-width: 19.05rem;
    grid-template-columns: 1fr;
  }
}

.tableWrap {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  & > div {
    width: 100%;
  }

  & > div:nth-child(2) > span,
  & > div:nth-child(3) > span,
  & > div:nth-child(4) > span {
    @media screen and (max-width: 1200px) {
      font-size: 24px;
    }
  }
}
