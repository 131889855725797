@import '@styles/basic';

.container {
  background-color: white;
  padding: toRem(24);
  border: 1px solid rgba($dark, 0.3);
  border-radius: toRem(16);
  max-height: 90vh;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-bottom: toRem(32);

  &.noMargin {
    margin-bottom: 0;
  }
  i {
    position: absolute;
    right: 0;
    top: 0;
    font-size: toRem(32);
    opacity: 0.5;
    cursor: pointer;
  }
  strong {
    margin: 0;
    max-width: 80%;
    text-align: center;
    font-size: toRem(24);
  }
}
