html,
body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: $dark;
  text-rendering: auto;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-text-size-adjust: 100%;
  padding: 0;
  margin: 0;
  font-weight: 400;
}

html {
  font-size: 20px;
  scroll-behavior: smooth;

  @include breakpoint(md) {
    font-size: 20px;
  }

  &[lang] {
    margin-top: 0 !important;
  }
}

body {
  --coldark: #{$dark};
  --collight: #{$light};
  --col1: #{$blue};

  font-size: 1rem;
  display: flex;
  flex-direction: column;
  line-height: 1.55;
}

* {
  text-rendering: auto !important;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

#wpadminbar {
  display: none;
}
