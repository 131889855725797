@import '@styles/basic';

.container {
  background: $light;
  box-shadow: 0px 4px 16px rgba(181, 181, 181, 0.25);
  border-radius: 30px;
  padding: 1.8rem 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 24.1rem;
  width: 100%;
  img {
    max-width: 14.3rem;
    margin-bottom: 1.2rem;
  }

  .description {
    margin-bottom: 2.4rem;
    font-weight: 600;
    & + span {
      margin-bottom: 1.2rem;
    }
  }

  .price {
    color: $blue;
    font-size: 4.8rem;
    line-height: 3.6rem;
    font-weight: 500;
    margin-bottom: 1rem;
    span {
      font-size: 1.2rem;
    }
    & + a {
      font-size: 0.9rem;
    }
  }

  .priceType {
    color: $blue;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1.1rem;
  }
}
