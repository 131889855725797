@import '@styles/basic';

.cellWrapper {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltipLicense {
  display: flex;
  align-items: center;
  gap: toRem(4);
  font-size: toRem(12);
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}
