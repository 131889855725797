@import './basic';
.noBorderBottom {
  & > span + div {
    border-bottom: none !important;
  }
}
.noBorder {
  border-bottom: none !important;
}
.borderTop {
  border-top: 1px solid rgba(11, 40, 78, 0.25);
}
/* React-collapsible rules in Table */

.tableAccordion {
  @media only screen and (max-width: 767px) {
    margin-bottom: 1.6rem;
  }
  .Collapsible {
    width: 100%;

    .is-closed {
      border-bottom: none !important;
    }
    &__contentInner {
      padding: 0;
    }

    &__contentOuter {
      transition: height ease-in-out 0.2s !important;
      border: none;
    }

    &__trigger {
      margin-top: 1.6rem;
      margin-bottom: 1.2rem;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.7rem;
      border: none;
      position: relative;
      display: flex;
      align-items: center;
      padding: 0.4rem;
      cursor: pointer;
      min-height: 2rem;
      position: relative;

      &::after {
        position: absolute;
        content: '\f31a';
        font-family: Phosphor;
        top: 4px;
        right: 12px;
        font-size: 20px;
        display: block;
        pointer-events: none;
      }

      &.is-closed::after {
        transform: rotate(-90deg);
      }

      @include breakpoint(md) {
        margin-top: 1.6rem;
      }

      @include breakpoint(lg) {
        font-size: 18px;
        line-height: 20px;

        min-height: unset;
      }
    }
  }
}

.accordionWrapForm.container {
  & > div:nth-child(3) > div > div > div {
    border-bottom: none !important;
  }

  .Collapsible {
    width: 100%;

    &.active {
      padding: 1.2rem 0.8rem;
      display: block;
      border-bottom: 1px solid rgba(11, 40, 78, 0.25);
    }
    .is-closed {
      border-bottom: none !important;
      display: none;
    }
    &__contentInner {
      padding: 1.2rem 0.8rem;
    }

    &__contentOuter {
      transition: height ease-in-out 0.2s !important;
      border-bottom: 1px solid transparentize($dark, 0.75);
    }

    &__trigger {
      margin-top: 1px;
      font-weight: 500;

      font-size: 20px;
      line-height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      min-height: 40px;
      margin-bottom: 0;

      @include breakpoint(md) {
        margin-top: 0;
      }

      @include breakpoint(lg) {
        font-size: 18px;
        line-height: 20px;

        min-height: unset;
      }
    }
  }
}

/* React-collapsible rules in Faq*/

.faqList {
  text-align: left;
  margin-bottom: 0.8rem;
}

.faqList .Collapsible__trigger {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  padding: 1rem 0 !important;
  width: 100%;

  display: inline-block;

  position: relative;
  padding-left: 50px !important;
  &:hover {
    cursor: pointer;
  }
}

.faqList .Collapsible__trigger.is-open {
  padding-top: 8px;
  padding-right: 40px;
  border-bottom: 0 !important;

  &::before {
    content: '\f4c0';
    font-family: Phosphor;
    font-size: 1.2rem;
    margin-right: 1.4rem;
  }
}

.faqList .Collapsible__trigger.is-closed {
  padding: 8px 0;
  padding-right: 40px;
}
.faqList .Collapsible__trigger:before {
  content: '\f532';
  font-family: Phosphor;

  font-size: 1.2rem;

  position: absolute;
  top: 17px;

  position: absolute;
  left: 0;
}
.faqList .Collapsible__contentOuter {
  border-bottom: none;
  padding-left: 2.6rem;
}

.faqList .Collapsible__contentInner {
  padding: 0 0 1.2rem 0;
}

.faqList {
  li:not(:last-child) {
    border-bottom: 1px solid rgba($dark, 0.25);
  }
}

.micro {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  &.bold {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
}

.cta {
  position: relative;
  &:disabled {
    cursor: not-allowed;
    border: none;
    opacity: 0.4;
  }
}

section {
  &.white {
    background-color: $light;
  }

  &.catskill {
    background-color: $catskill-white;
  }

  &.noMarginBottom {
    margin-bottom: -1rem !important;
  }
}

.wrap > .centered {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.wrap {
  &.centered {
    justify-content: center;
    flex-direction: column;
    display: flex;
  }

  &.fullWidthWrap {
    width: 100%;
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
  }

  &.showSide {
    flex-direction: column;

    justify-content: center;
    align-items: center;
    @include breakpoint(xl, true) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding-right: 2.6rem;
      padding-left: 2.6rem;
      padding-right: 0;
    }
  }
}

p {
  &.text {
    font-size: 0.8rem;
    line-height: 26px;
  }
}

a {
  &.linkBold {
    font-size: 0.8rem;
    line-height: 26px;
    font-weight: 600;
  }
  &.linkBlue {
    color: $blue;
  }
}

.full {
  width: 100%;
}

.main {
  flex: 1;
  & > section,
  & > .wp-block-columns {
    padding-bottom: 1rem !important;
    margin-bottom: 0 !important;
  }

  &.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.rc-tooltip-placement-top .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
  border-top-color: #e8ebee !important;
}

.rc-tooltip.rc-tooltip-placement-top {
  max-width: 16rem !important;

  opacity: none !important;
  font-size: 0.6rem !important;
  background-color: transparent !important;

  @include breakpoint(sm) {
    max-width: 13rem !important;
  }
  .rc-tooltip-content {
    .rc-tooltip-inner {
      background: linear-gradient(
          0deg,
          rgba(11, 40, 78, 0.1),
          rgba(11, 40, 78, 0.1)
        ),
        #ffffff !important;
      border-radius: 2px !important;

      padding: 0.6rem;
      p,
      li {
        font-size: 0.6rem !important;
        font-weight: 500 !important;
        line-height: 15px !important;
        color: $dark !important;
      }
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-input .wrapper-input input {
  width: 100%;
}

.link {
  color: $blue;
  text-decoration: underline;
}

.error {
  color: $red;
  font-weight: 600;
}

.Toastify__toast-container {
  width: 100% !important;
  padding: 0 !important;
  right: toRem(0) !important;

  @include breakpoint(md, true) {
    right: toRem(16) !important;
    max-width: toRem(430);
  }
}

.Toastify__toast {
  padding: 0 !important;
  width: 100% !important;
  color: $dark !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

.text-underline {
  display: inline-block;
  position: relative;
  text-decoration: none !important;
  white-space: nowrap;
  &:before {
    background-image: url('../assets/images/underline.svg');
    background-size: auto 100%;
    border-radius: 0.083em;
    content: '';
    height: 0.166em;
    left: 0;
    margin-top: -0.083em;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transition: width 0.25s ease-in-out 0.15s;
    width: 100%;
  }
}

.ReactModal__Overlay {
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3) !important;
  align-items: center;
  display: flex;
}
.ReactModalPortal {
  z-index: 9999;
}

.ReactModal__Content {
  width: 100%;
  inset: none !important;
  max-width: toRem(600);
  margin: 0 auto;
  height: auto;
}
