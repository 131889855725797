@import '@styles/basic';

.container {
  display: flex;
  align-items: 'center';
  font-size: toRem(16);
  border: 2px solid;
  line-height: 1;
  margin: 0 !important;

  &.light_bg,
  &.white {
    border-radius: toRem(12);
    color: $dark;
    font-weight: 400;
    font-size: toRem(16);
    line-height: toRem(24);
    padding: toRem(16);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.light_bg {
    background-color: rgba(45, 156, 219, 0.05);
  }

  &.white {
    background-color: $light;
  }

  &:disabled {
    color: rgba($dark, 0.5) !important;
    opacity: 1 !important;
    background-color: $catskill-white;
    border: none;
  }
  &.tertiary {
    border-color: $dark;
    background-color: transparent;
    color: $dark;
  }

  &.small {
    font-size: toRem(12);
  }
}
