@import '@styles/basic';

.header {
  margin: toRem(16) 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  h2 {
    margin: 0;
  }

  .searchContainer {
    position: relative;
    input {
      padding-left: toRem(48);
    }
    input + span {
      position: absolute;
      left: toRem(19);
      top: toRem(10);
      font-size: toRem(24);
      color: rgba($dark, 0.5);
    }
  }
}

.container {
  text-align: center;
  width: 100%;
  margin-top: toRem(32);
}

.pagination {
  width: 100%;
  margin-top: toRem(48);
}
