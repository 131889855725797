$blue: #2d9cdb;
$light: #fff;
$dark: #0b284e;
$green: #25e489;
$success: #3cc13b;
$gray: #f4f4f4;
$catskill-white: #f4f8f8;
$red: #cd143c;
$red2: #eb5757;
$light-red: #db2d2d;
$yellow: #f2c94c;
$orange: #ff6634;
$breakpoints: (
  ul: 1680px,
  xxl: 1440px,
  xl: 1280px,
  custom: 1200px,
  lg: 1024px,
  md: 768px,
  sm: 576px,
  xs: 460px,
  xxs: 400px
);

@mixin breakpoint($device, $min: false) {
  @if $min == false {
    @media (max-width: #{map-get($breakpoints, $device)}) {
      @content;
    }
  } @else {
    @media (min-width: #{map-get($breakpoints, $device) + 1px}) {
      @content;
    }
  }
}
