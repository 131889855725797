@import '@styles/basic';

.container {
  text-align: center;
  max-width: 29.75rem;
  width: 100%;
}

.registerCta {
  margin-top: 1.2rem !important;
  text-align: center;
}

.loginCta {
  display: inline;
  font-size: 0.8rem;
  line-height: 26px;
  font-weight: 600;
  color: $blue;
  cursor: pointer;
}

.error {
  color: $red;
  font-weight: 500;
  line-height: 0.9rem;
  font-size: 0.7rem;
  line-height: 1;
  margin-top: 0.4rem;
}