@import '@styles/basic';

.title {
  text-align: left;
  width: 100%;
}

.content {
  background-color: white;
  width: 100%;
  padding: 16px;
  border: 1px solid rgb($dark, 0.25);
  border-radius: 16px;
}
.item {
  margin-bottom: 16px;
}

.summary {
  padding: 8px;
  p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    color: $dark;
  }
}

.summaryTitle p {
  font-size: 16px;
  color: rgb($dark, 0.5);
  padding: 16px 0;
  display: block;
}
.summaryRow {
  display: flex;
  min-height: 35px;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-size: 14px;
    line-height: 2.5;
  }
}

.summaryRowTotal {
  margin-bottom: 24px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    font-size: 16px;
    font-family: 600;
    line-height: 2.5;
  }
  strong {
    font-size: 24px;
  }
}

.signupOrLogin {
  border: 1px solid rgba($dark, 0.2);
  padding: 16px;
  border-radius: 16px;
  display: flex;

  align-items: center;
  justify-content: space-between;

  .text {
    width: 50%;
    flex-shrink: 0;
    padding-right: toRem(12);
    p {
      font-size: toRem(14);
      line-height: toRem(24);
    }
  }
}

.containerEmpty {
  width: 100%;
  vertical-align: middle;
  padding: 25% 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    color: rgb($dark, 0.5);
  }
}
