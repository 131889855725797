@import '@styles/basic';

.container {
  padding: 16px;
  background-color: $catskill-white;
  border-radius: 16px;
  p {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 2;
  }
  a {
    font-size: 14px;
  }
}
