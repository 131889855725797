@import '@styles/basic';

.avatar {
  display: flex;
  align-items: center;
  .logout {
    display: flex;
    flex-direction: column;
    margin-left: toRem(8);

    p {
      margin-bottom: 0;
      font-size: toRem(14);
      line-height: toRem(18);
      font-weight: 600;
      cursor: pointer;
    }

    span {
      font-size: toRem(12);
      line-height: toRem(18);
      font-weight: 400;
      cursor: pointer;
      align-self: flex-start;
    }
  }

  &.mobile {
    padding: toRem(8) 6vw toRem(8);
    margin-top: toRem(24);
    margin-bottom: toRem(24);

    p.logoutMobile {
      margin-left: toRem(8);
      font-size: toRem(16);
      line-height: toRem(26);
      font-weight: 600;
      cursor: pointer;
    }
  }
}
