@import '@styles/basic';

.formWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  text-align: center;
  max-width: 25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 44px;
    font-size: toRem(48);
  }
}
