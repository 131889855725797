@import '@styles/basic';

.title {
  width: 100%;
}

.item {
  margin-bottom: 16px;
}

.containerEmpty {
  width: 100%;
  vertical-align: middle;
  padding: 25% 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    color: rgb($dark, 0.5);
  }
}

.banner {
  text-align: center;
  width: 100%;
  background-color: rgba(45, 156, 219, 0.05);
  border-radius: 50px;
  padding: toRem(90) toRem(16);

  &.bannerError {
    background-color: rgba(11, 40, 78, 0.1);
    span {
      color: $red;
    }
  }

  p {
    font-size: toRem(16);
    @include breakpoint(lg, true) {
      font-size: toRem(24);
    }
    strong {
      position: relative;
      align-items: center;
    }

    span {
      position: absolute;
      right: -28px;
      top: 5px;
      display: inline-block;
      margin-left: 4px;
      color: $success;
    }
  }
}
