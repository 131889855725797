@import '@styles/basic';

.container {
  color: rgb($dark, 0.5);
  font-weight: 400;
  font-size: toRem(14);
  line-height: toRem(24);
  padding: toRem(5.5) toRem(12) toRem(6.5) toRem(12);
  background-color: transparent;
  cursor: pointer;

  &.active {
    color: $light;
    background-color: $blue;

    & > span {
      background-color: $catskill-white;
      color: transparentize($dark, 0.5);
    }
  }
}
