@import '@styles/basic';

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: toRem(8);

  & > div:first-child {
    p {
      display: inline;
    }
  }
}

.cardContent {
  h4 {
    margin-bottom: toRem(4);
    font-size: toRem(16);
  }
  p,
  strong {
    font-size: toRem(12);
  }
}

.blueText {
  color: $blue;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: toRem(8) 0;
  border-bottom: 1px solid rgba($dark, 0.4);
}

.cta {
  cursor: pointer;
}
