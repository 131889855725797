.avatar {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 0.8rem;
  color: $light;
  background: transparentize($dark, 0.5);
  display: inline-block;
  border-radius: 50%;

  &:hover {
    background: transparentize($dark, 0.6);
  }
}
