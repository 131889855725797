@import '@styles/basic';

.container {
  background-color: $catskill-white;
  &.whiteBg {
    background-color: white;
  }

  &.totalWhite {
    background-color: white;
  }
}
