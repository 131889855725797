@import '@styles/basic';
.container {
  max-width: toRem(595);
  width: 100%;
  > p,
  strong {
    margin: 0;
  }
}

.checkoutItem {
  margin-bottom: toRem(24);
  text-align: left;
}

.cardContent {
  margin-bottom: toRem(24);
  &:last-child {
    margin-bottom: toRem(4);
  }

  a {
    font-size: 14px;
  }
}

.checkDiscount {
  margin-left: toRem(12);
  font-size: toRem(32);
  font-weight: 700;
  color: $green;
  &.hasError {
    color: $red;
  }
}
.cardContentInline {
  @include breakpoint(lg, true) {
    display: inline-flex;
  }
}

.cardContentInline form {
  @include breakpoint(lg, true) {
    min-width: 320px;
  }
}

.cardContentInline button {
  width: 100%;
  margin-top: toRem(8) !important;

  @include breakpoint(lg, true) {
    margin-left: toRem(16) !important;
    margin-top: 0 !important;
    width: auto;
  }
}

.error {
  padding-top: toRem(16);
}

.agreementError {
  font-size: 0.7rem !important;
}

.mobile {
  display: block;
  @include breakpoint(xl, true) {
    display: none;
  }
}

.validCheck {
  display: flex;
  align-items: center;
  font-weight: 500;
  > span {
    font-size: 20px;
    margin-left: 3px;
  }
}

.invalid {
  color: $red;
}

.valid {
  color: $success;
}
