@font-face {
  font-family: Phosphor;
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/phosphor.woff2") format("woff2");
  font-display: block;
}

.icon,
[class^="icon-"],
[class*=" icon-"] {
  --icon-size: 1em;
  --icon-color: inherit;

  display: inline-block;
  font: normal normal normal 1em/1 Phosphor, sans-serif;
  flex-shrink: 0;
  speak: none;
  text-transform: none;
  font-size: var(--icon-size);
  color: var(--icon-color);
}

.icon {
  &-activity::before { content: "\f230"; }
  &-address-book::before { content: "\f231"; }
  &-airplane::before { content: "\f232"; }
  &-airplane-in-flight::before { content: "\f233"; }
  &-airplane-landing::before { content: "\f234"; }
  &-airplane-takeoff::before { content: "\f235"; }
  &-airplane-tilt::before { content: "\f236"; }
  &-airplay::before { content: "\f237"; }
  &-alarm::before { content: "\f238"; }
  &-alien::before { content: "\f239"; }
  &-align-bottom::before { content: "\f23a"; }
  &-align-bottom-simple::before { content: "\f23b"; }
  &-align-center-horizontal::before { content: "\f23c"; }
  &-align-center-horizontal-simple::before { content: "\f23d"; }
  &-align-center-vertical::before { content: "\f23e"; }
  &-align-center-vertical-simple::before { content: "\f23f"; }
  &-align-left::before { content: "\f240"; }
  &-align-left-simple::before { content: "\f241"; }
  &-align-right::before { content: "\f242"; }
  &-align-right-simple::before { content: "\f243"; }
  &-align-top::before { content: "\f244"; }
  &-align-top-simple::before { content: "\f245"; }
  &-anchor::before { content: "\f246"; }
  &-anchor-simple::before { content: "\f247"; }
  &-android-logo::before { content: "\f248"; }
  &-angular-logo::before { content: "\f249"; }
  &-aperture::before { content: "\f24a"; }
  &-app-store-logo::before { content: "\f24b"; }
  &-app-window::before { content: "\f24c"; }
  &-apple-logo::before { content: "\f24d"; }
  &-apple-podcasts-logo::before { content: "\f24e"; }
  &-archive::before { content: "\f24f"; }
  &-archive-box::before { content: "\f250"; }
  &-archive-tray::before { content: "\f251"; }
  &-armchair::before { content: "\f252"; }
  &-arrow-arc-left::before { content: "\f253"; }
  &-arrow-arc-right::before { content: "\f254"; }
  &-arrow-bend-double-up-left::before { content: "\f255"; }
  &-arrow-bend-double-up-right::before { content: "\f256"; }
  &-arrow-bend-down-left::before { content: "\f257"; }
  &-arrow-bend-down-right::before { content: "\f258"; }
  &-arrow-bend-left-down::before { content: "\f259"; }
  &-arrow-bend-left-up::before { content: "\f25a"; }
  &-arrow-bend-right-down::before { content: "\f25b"; }
  &-arrow-bend-right-up::before { content: "\f25c"; }
  &-arrow-bend-up-left::before { content: "\f25d"; }
  &-arrow-bend-up-right::before { content: "\f25e"; }
  &-arrow-circle-down::before { content: "\f25f"; }
  &-arrow-circle-down-left::before { content: "\f260"; }
  &-arrow-circle-down-right::before { content: "\f261"; }
  &-arrow-circle-left::before { content: "\f262"; }
  &-arrow-circle-right::before { content: "\f263"; }
  &-arrow-circle-up::before { content: "\f264"; }
  &-arrow-circle-up-left::before { content: "\f265"; }
  &-arrow-circle-up-right::before { content: "\f266"; }
  &-arrow-clockwise::before { content: "\f267"; }
  &-arrow-counter-clockwise::before { content: "\f268"; }
  &-arrow-down::before { content: "\f269"; }
  &-arrow-down-left::before { content: "\f26a"; }
  &-arrow-down-right::before { content: "\f26b"; }
  &-arrow-elbow-down-left::before { content: "\f26c"; }
  &-arrow-elbow-down-right::before { content: "\f26d"; }
  &-arrow-elbow-left::before { content: "\f26e"; }
  &-arrow-elbow-left-down::before { content: "\f26f"; }
  &-arrow-elbow-left-up::before { content: "\f270"; }
  &-arrow-elbow-right::before { content: "\f271"; }
  &-arrow-elbow-right-down::before { content: "\f272"; }
  &-arrow-elbow-right-up::before { content: "\f273"; }
  &-arrow-elbow-up-left::before { content: "\f274"; }
  &-arrow-elbow-up-right::before { content: "\f275"; }
  &-arrow-fat-down::before { content: "\f276"; }
  &-arrow-fat-left::before { content: "\f277"; }
  &-arrow-fat-line-down::before { content: "\f278"; }
  &-arrow-fat-line-left::before { content: "\f279"; }
  &-arrow-fat-line-right::before { content: "\f27a"; }
  &-arrow-fat-line-up::before { content: "\f27b"; }
  &-arrow-fat-lines-down::before { content: "\f27c"; }
  &-arrow-fat-lines-left::before { content: "\f27d"; }
  &-arrow-fat-lines-right::before { content: "\f27e"; }
  &-arrow-fat-lines-up::before { content: "\f27f"; }
  &-arrow-fat-right::before { content: "\f280"; }
  &-arrow-fat-up::before { content: "\f281"; }
  &-arrow-left::before { content: "\f282"; }
  &-arrow-line-down::before { content: "\f283"; }
  &-arrow-line-down-left::before { content: "\f284"; }
  &-arrow-line-down-right::before { content: "\f285"; }
  &-arrow-line-left::before { content: "\f286"; }
  &-arrow-line-right::before { content: "\f287"; }
  &-arrow-line-up::before { content: "\f288"; }
  &-arrow-line-up-left::before { content: "\f289"; }
  &-arrow-line-up-right::before { content: "\f28a"; }
  &-arrow-right::before { content: "\f28b"; }
  &-arrow-square-down::before { content: "\f28c"; }
  &-arrow-square-down-left::before { content: "\f28d"; }
  &-arrow-square-down-right::before { content: "\f28e"; }
  &-arrow-square-in::before { content: "\f28f"; }
  &-arrow-square-left::before { content: "\f290"; }
  &-arrow-square-out::before { content: "\f291"; }
  &-arrow-square-right::before { content: "\f292"; }
  &-arrow-square-up::before { content: "\f293"; }
  &-arrow-square-up-left::before { content: "\f294"; }
  &-arrow-square-up-right::before { content: "\f295"; }
  &-arrow-u-down-left::before { content: "\f296"; }
  &-arrow-u-down-right::before { content: "\f297"; }
  &-arrow-u-left-down::before { content: "\f298"; }
  &-arrow-u-left-up::before { content: "\f299"; }
  &-arrow-u-right-down::before { content: "\f29a"; }
  &-arrow-u-right-up::before { content: "\f29b"; }
  &-arrow-u-up-left::before { content: "\f29c"; }
  &-arrow-u-up-right::before { content: "\f29d"; }
  &-arrow-up::before { content: "\f29e"; }
  &-arrow-up-left::before { content: "\f29f"; }
  &-arrow-up-right::before { content: "\f2a0"; }
  &-arrows-clockwise::before { content: "\f2a1"; }
  &-arrows-counter-clockwise::before { content: "\f2a2"; }
  &-arrows-down-up::before { content: "\f2a3"; }
  &-arrows-horizontal::before { content: "\f2a4"; }
  &-arrows-in::before { content: "\f2a5"; }
  &-arrows-in-cardinal::before { content: "\f2a6"; }
  &-arrows-in-line-horizontal::before { content: "\f2a7"; }
  &-arrows-in-line-vertical::before { content: "\f2a8"; }
  &-arrows-in-simple::before { content: "\f2a9"; }
  &-arrows-left-right::before { content: "\f2aa"; }
  &-arrows-out::before { content: "\f2ab"; }
  &-arrows-out-cardinal::before { content: "\f2ac"; }
  &-arrows-out-line-horizontal::before { content: "\f2ad"; }
  &-arrows-out-line-vertical::before { content: "\f2ae"; }
  &-arrows-out-simple::before { content: "\f2af"; }
  &-arrows-vertical::before { content: "\f2b0"; }
  &-article::before { content: "\f2b1"; }
  &-article-medium::before { content: "\f2b2"; }
  &-article-ny-times::before { content: "\f2b3"; }
  &-asterisk::before { content: "\f2b4"; }
  &-asterisk-simple::before { content: "\f2b5"; }
  &-at::before { content: "\f2b6"; }
  &-atom::before { content: "\f2b7"; }
  &-baby::before { content: "\f2b8"; }
  &-backpack::before { content: "\f2b9"; }
  &-backspace::before { content: "\f2ba"; }
  &-bag::before { content: "\f2bb"; }
  &-bag-simple::before { content: "\f2bc"; }
  &-balloon::before { content: "\f2bd"; }
  &-bandaids::before { content: "\f2be"; }
  &-bank::before { content: "\f2bf"; }
  &-barbell::before { content: "\f2c0"; }
  &-barcode::before { content: "\f2c1"; }
  &-barricade::before { content: "\f2c2"; }
  &-baseball::before { content: "\f2c3"; }
  &-basketball::before { content: "\f2c4"; }
  &-bathtub::before { content: "\f2c5"; }
  &-battery-charging::before { content: "\f2c6"; }
  &-battery-charging-vertical::before { content: "\f2c7"; }
  &-battery-empty::before { content: "\f2c8"; }
  &-battery-full::before { content: "\f2c9"; }
  &-battery-high::before { content: "\f2ca"; }
  &-battery-low::before { content: "\f2cb"; }
  &-battery-medium::before { content: "\f2cc"; }
  &-battery-plus::before { content: "\f2cd"; }
  &-battery-warning::before { content: "\f2ce"; }
  &-battery-warning-vertical::before { content: "\f2cf"; }
  &-bed::before { content: "\f2d0"; }
  &-beer-bottle::before { content: "\f2d1"; }
  &-behance-logo::before { content: "\f2d2"; }
  &-bell::before { content: "\f2d3"; }
  &-bell-ringing::before { content: "\f2d4"; }
  &-bell-simple::before { content: "\f2d5"; }
  &-bell-simple-ringing::before { content: "\f2d6"; }
  &-bell-simple-slash::before { content: "\f2d7"; }
  &-bell-simple-z::before { content: "\f2d8"; }
  &-bell-slash::before { content: "\f2d9"; }
  &-bell-z::before { content: "\f2da"; }
  &-bezier-curve::before { content: "\f2db"; }
  &-bicycle::before { content: "\f2dc"; }
  &-binoculars::before { content: "\f2dd"; }
  &-bird::before { content: "\f2de"; }
  &-bluetooth::before { content: "\f2df"; }
  &-bluetooth-connected::before { content: "\f2e0"; }
  &-bluetooth-slash::before { content: "\f2e1"; }
  &-bluetooth-x::before { content: "\f2e2"; }
  &-boat::before { content: "\f2e3"; }
  &-book::before { content: "\f2e4"; }
  &-book-bookmark::before { content: "\f2e5"; }
  &-book-open::before { content: "\f2e6"; }
  &-bookmark::before { content: "\f2e7"; }
  &-bookmark-simple::before { content: "\f2e8"; }
  &-bookmarks::before { content: "\f2e9"; }
  &-bookmarks-simple::before { content: "\f2ea"; }
  &-books::before { content: "\f2eb"; }
  &-bounding-box::before { content: "\f2ec"; }
  &-brackets-angle::before { content: "\f2ed"; }
  &-brackets-curly::before { content: "\f2ee"; }
  &-brackets-round::before { content: "\f2ef"; }
  &-brackets-square::before { content: "\f2f0"; }
  &-brain::before { content: "\f2f1"; }
  &-brandy::before { content: "\f2f2"; }
  &-briefcase::before { content: "\f2f3"; }
  &-briefcase-metal::before { content: "\f2f4"; }
  &-broadcast::before { content: "\f2f5"; }
  &-browser::before { content: "\f2f6"; }
  &-browsers::before { content: "\f2f7"; }
  &-bug::before { content: "\f2f8"; }
  &-bug-beetle::before { content: "\f2f9"; }
  &-bug-droid::before { content: "\f2fa"; }
  &-buildings::before { content: "\f2fb"; }
  &-bus::before { content: "\f2fc"; }
  &-butterfly::before { content: "\f2fd"; }
  &-cactus::before { content: "\f2fe"; }
  &-cake::before { content: "\f2ff"; }
  &-calculator::before { content: "\f300"; }
  &-calendar::before { content: "\f301"; }
  &-calendar-blank::before { content: "\f302"; }
  &-calendar-check::before { content: "\f303"; }
  &-calendar-plus::before { content: "\f304"; }
  &-calendar-x::before { content: "\f305"; }
  &-camera::before { content: "\f306"; }
  &-camera-rotate::before { content: "\f307"; }
  &-camera-slash::before { content: "\f308"; }
  &-campfire::before { content: "\f309"; }
  &-car::before { content: "\f30a"; }
  &-car-simple::before { content: "\f30b"; }
  &-cardholder::before { content: "\f30c"; }
  &-cards::before { content: "\f30d"; }
  &-caret-circle-double-down::before { content: "\f30e"; }
  &-caret-circle-double-left::before { content: "\f30f"; }
  &-caret-circle-double-right::before { content: "\f310"; }
  &-caret-circle-double-up::before { content: "\f311"; }
  &-caret-circle-down::before { content: "\f312"; }
  &-caret-circle-left::before { content: "\f313"; }
  &-caret-circle-right::before { content: "\f314"; }
  &-caret-circle-up::before { content: "\f315"; }
  &-caret-double-down::before { content: "\f316"; }
  &-caret-double-left::before { content: "\f317"; }
  &-caret-double-right::before { content: "\f318"; }
  &-caret-double-up::before { content: "\f319"; }
  &-caret-down::before { content: "\f31a"; }
  &-caret-left::before { content: "\f31b"; }
  &-caret-right::before { content: "\f31c"; }
  &-caret-up::before { content: "\f31d"; }
  &-cat::before { content: "\f31e"; }
  &-cell-signal-full::before { content: "\f31f"; }
  &-cell-signal-high::before { content: "\f320"; }
  &-cell-signal-low::before { content: "\f321"; }
  &-cell-signal-medium::before { content: "\f322"; }
  &-cell-signal-none::before { content: "\f323"; }
  &-cell-signal-slash::before { content: "\f324"; }
  &-cell-signal-x::before { content: "\f325"; }
  &-chalkboard::before { content: "\f326"; }
  &-chalkboard-simple::before { content: "\f327"; }
  &-chalkboard-teacher::before { content: "\f328"; }
  &-chart-bar::before { content: "\f329"; }
  &-chart-bar-horizontal::before { content: "\f32a"; }
  &-chart-line::before { content: "\f32b"; }
  &-chart-line-up::before { content: "\f32c"; }
  &-chart-pie::before { content: "\f32d"; }
  &-chart-pie-slice::before { content: "\f32e"; }
  &-chat::before { content: "\f32f"; }
  &-chat-centered::before { content: "\f330"; }
  &-chat-centered-dots::before { content: "\f331"; }
  &-chat-centered-text::before { content: "\f332"; }
  &-chat-circle::before { content: "\f333"; }
  &-chat-circle-dots::before { content: "\f334"; }
  &-chat-circle-text::before { content: "\f335"; }
  &-chat-dots::before { content: "\f336"; }
  &-chat-teardrop::before { content: "\f337"; }
  &-chat-teardrop-dots::before { content: "\f338"; }
  &-chat-teardrop-text::before { content: "\f339"; }
  &-chat-text::before { content: "\f33a"; }
  &-chats::before { content: "\f33b"; }
  &-chats-circle::before { content: "\f33c"; }
  &-chats-teardrop::before { content: "\f33d"; }
  &-check::before { content: "\f33e"; }
  &-check-circle::before { content: "\f33f"; }
  &-check-square::before { content: "\f340"; }
  &-check-square-offset::before { content: "\f341"; }
  &-checks::before { content: "\f342"; }
  &-circle::before { content: "\f343"; }
  &-circle-dashed::before { content: "\f344"; }
  &-circle-half::before { content: "\f345"; }
  &-circle-half-tilt::before { content: "\f346"; }
  &-circle-notch::before { content: "\f347"; }
  &-circle-wavy::before { content: "\f348"; }
  &-circle-wavy-check::before { content: "\f349"; }
  &-circle-wavy-question::before { content: "\f34a"; }
  &-circle-wavy-warning::before { content: "\f34b"; }
  &-circles-four::before { content: "\f34c"; }
  &-circles-three::before { content: "\f34d"; }
  &-circles-three-plus::before { content: "\f34e"; }
  &-clipboard::before { content: "\f34f"; }
  &-clipboard-text::before { content: "\f350"; }
  &-clock::before { content: "\f351"; }
  &-clock-afternoon::before { content: "\f352"; }
  &-clock-clockwise::before { content: "\f353"; }
  &-clock-counter-clockwise::before { content: "\f354"; }
  &-closed-captioning::before { content: "\f355"; }
  &-cloud::before { content: "\f356"; }
  &-cloud-arrow-down::before { content: "\f357"; }
  &-cloud-arrow-up::before { content: "\f358"; }
  &-cloud-check::before { content: "\f359"; }
  &-cloud-fog::before { content: "\f35a"; }
  &-cloud-lightning::before { content: "\f35b"; }
  &-cloud-moon::before { content: "\f35c"; }
  &-cloud-rain::before { content: "\f35d"; }
  &-cloud-slash::before { content: "\f35e"; }
  &-cloud-snow::before { content: "\f35f"; }
  &-cloud-sun::before { content: "\f360"; }
  &-club::before { content: "\f361"; }
  &-coat-hanger::before { content: "\f362"; }
  &-code::before { content: "\f363"; }
  &-code-simple::before { content: "\f364"; }
  &-codepen-logo::before { content: "\f365"; }
  &-codesandbox-logo::before { content: "\f366"; }
  &-coffee::before { content: "\f367"; }
  &-coin::before { content: "\f368"; }
  &-coin-vertical::before { content: "\f369"; }
  &-coins::before { content: "\f36a"; }
  &-columns::before { content: "\f36b"; }
  &-command::before { content: "\f36c"; }
  &-compass::before { content: "\f36d"; }
  &-computer-tower::before { content: "\f36e"; }
  &-confetti::before { content: "\f36f"; }
  &-cookie::before { content: "\f370"; }
  &-cooking-pot::before { content: "\f371"; }
  &-copy::before { content: "\f372"; }
  &-copy-simple::before { content: "\f373"; }
  &-copyleft::before { content: "\f374"; }
  &-copyright::before { content: "\f375"; }
  &-corners-in::before { content: "\f376"; }
  &-corners-out::before { content: "\f377"; }
  &-cpu::before { content: "\f378"; }
  &-credit-card::before { content: "\f379"; }
  &-crop::before { content: "\f37a"; }
  &-crosshair::before { content: "\f37b"; }
  &-crosshair-simple::before { content: "\f37c"; }
  &-crown::before { content: "\f37d"; }
  &-crown-simple::before { content: "\f37e"; }
  &-cube::before { content: "\f37f"; }
  &-currency-btc::before { content: "\f380"; }
  &-currency-circle-dollar::before { content: "\f381"; }
  &-currency-cny::before { content: "\f382"; }
  &-currency-dollar::before { content: "\f383"; }
  &-currency-dollar-simple::before { content: "\f384"; }
  &-currency-eth::before { content: "\f385"; }
  &-currency-eur::before { content: "\f386"; }
  &-currency-gbp::before { content: "\f387"; }
  &-currency-inr::before { content: "\f388"; }
  &-currency-jpy::before { content: "\f389"; }
  &-currency-krw::before { content: "\f38a"; }
  &-currency-kzt::before { content: "\f38b"; }
  &-currency-ngn::before { content: "\f38c"; }
  &-currency-rub::before { content: "\f38d"; }
  &-cursor::before { content: "\f38e"; }
  &-cursor-text::before { content: "\f38f"; }
  &-cylinder::before { content: "\f390"; }
  &-database::before { content: "\f391"; }
  &-desktop::before { content: "\f392"; }
  &-desktop-tower::before { content: "\f393"; }
  &-detective::before { content: "\f394"; }
  &-device-mobile::before { content: "\f395"; }
  &-device-mobile-camera::before { content: "\f396"; }
  &-device-mobile-speaker::before { content: "\f397"; }
  &-device-tablet::before { content: "\f398"; }
  &-device-tablet-camera::before { content: "\f399"; }
  &-device-tablet-speaker::before { content: "\f39a"; }
  &-diamond::before { content: "\f39b"; }
  &-diamonds-four::before { content: "\f39c"; }
  &-dice-five::before { content: "\f39d"; }
  &-dice-four::before { content: "\f39e"; }
  &-dice-one::before { content: "\f39f"; }
  &-dice-six::before { content: "\f3a0"; }
  &-dice-three::before { content: "\f3a1"; }
  &-dice-two::before { content: "\f3a2"; }
  &-disc::before { content: "\f3a3"; }
  &-discord-logo::before { content: "\f3a4"; }
  &-divide::before { content: "\f3a5"; }
  &-dog::before { content: "\f3a6"; }
  &-door::before { content: "\f3a7"; }
  &-dots-nine::before { content: "\f3a8"; }
  &-dots-six::before { content: "\f3a9"; }
  &-dots-six-vertical::before { content: "\f3aa"; }
  &-dots-three::before { content: "\f3ab"; }
  &-dots-three-circle::before { content: "\f3ac"; }
  &-dots-three-circle-vertical::before { content: "\f3ad"; }
  &-dots-three-outline::before { content: "\f3ae"; }
  &-dots-three-outline-vertical::before { content: "\f3af"; }
  &-dots-three-vertical::before { content: "\f3b0"; }
  &-download::before { content: "\f3b1"; }
  &-download-simple::before { content: "\f3b2"; }
  &-dribbble-logo::before { content: "\f3b3"; }
  &-drop::before { content: "\f3b4"; }
  &-drop-half::before { content: "\f3b5"; }
  &-drop-half-bottom::before { content: "\f3b6"; }
  &-ear::before { content: "\f3b7"; }
  &-ear-slash::before { content: "\f3b8"; }
  &-egg::before { content: "\f3b9"; }
  &-egg-crack::before { content: "\f3ba"; }
  &-eject::before { content: "\f3bb"; }
  &-eject-simple::before { content: "\f3bc"; }
  &-envelope::before { content: "\f3bd"; }
  &-envelope-open::before { content: "\f3be"; }
  &-envelope-simple::before { content: "\f3bf"; }
  &-envelope-simple-open::before { content: "\f3c0"; }
  &-equalizer::before { content: "\f3c1"; }
  &-equals::before { content: "\f3c2"; }
  &-eraser::before { content: "\f3c3"; }
  &-exam::before { content: "\f3c4"; }
  &-export::before { content: "\f3c5"; }
  &-eye::before { content: "\f3c6"; }
  &-eye-closed::before { content: "\f3c7"; }
  &-eye-slash::before { content: "\f3c8"; }
  &-eyedropper::before { content: "\f3c9"; }
  &-eyedropper-sample::before { content: "\f3ca"; }
  &-eyeglasses::before { content: "\f3cb"; }
  &-face-mask::before { content: "\f3cc"; }
  &-facebook-logo::before { content: "\f3cd"; }
  &-factory::before { content: "\f3ce"; }
  &-faders::before { content: "\f3cf"; }
  &-faders-horizontal::before { content: "\f3d0"; }
  &-fast-forward::before { content: "\f3d1"; }
  &-fast-forward-circle::before { content: "\f3d2"; }
  &-figma-logo::before { content: "\f3d3"; }
  &-file::before { content: "\f3d4"; }
  &-file-arrow-down::before { content: "\f3d5"; }
  &-file-arrow-up::before { content: "\f3d6"; }
  &-file-audio::before { content: "\f3d7"; }
  &-file-cloud::before { content: "\f3d8"; }
  &-file-code::before { content: "\f3d9"; }
  &-file-css::before { content: "\f3da"; }
  &-file-csv::before { content: "\f3db"; }
  &-file-doc::before { content: "\f3dc"; }
  &-file-dotted::before { content: "\f3dd"; }
  &-file-html::before { content: "\f3de"; }
  &-file-image::before { content: "\f3df"; }
  &-file-jpg::before { content: "\f3e0"; }
  &-file-js::before { content: "\f3e1"; }
  &-file-jsx::before { content: "\f3e2"; }
  &-file-lock::before { content: "\f3e3"; }
  &-file-minus::before { content: "\f3e4"; }
  &-file-pdf::before { content: "\f3e5"; }
  &-file-plus::before { content: "\f3e6"; }
  &-file-png::before { content: "\f3e7"; }
  &-file-ppt::before { content: "\f3e8"; }
  &-file-rs::before { content: "\f3e9"; }
  &-file-search::before { content: "\f3ea"; }
  &-file-text::before { content: "\f3eb"; }
  &-file-ts::before { content: "\f3ec"; }
  &-file-tsx::before { content: "\f3ed"; }
  &-file-video::before { content: "\f3ee"; }
  &-file-vue::before { content: "\f3ef"; }
  &-file-x::before { content: "\f3f0"; }
  &-file-xls::before { content: "\f3f1"; }
  &-file-zip::before { content: "\f3f2"; }
  &-files::before { content: "\f3f3"; }
  &-film-script::before { content: "\f3f4"; }
  &-film-slate::before { content: "\f3f5"; }
  &-film-strip::before { content: "\f3f6"; }
  &-fingerprint::before { content: "\f3f7"; }
  &-fingerprint-simple::before { content: "\f3f8"; }
  &-finn-the-human::before { content: "\f3f9"; }
  &-fire::before { content: "\f3fa"; }
  &-fire-simple::before { content: "\f3fb"; }
  &-first-aid::before { content: "\f3fc"; }
  &-first-aid-kit::before { content: "\f3fd"; }
  &-fish::before { content: "\f3fe"; }
  &-fish-simple::before { content: "\f3ff"; }
  &-flag::before { content: "\f400"; }
  &-flag-banner::before { content: "\f401"; }
  &-flag-checkered::before { content: "\f402"; }
  &-flame::before { content: "\f403"; }
  &-flashlight::before { content: "\f404"; }
  &-flask::before { content: "\f405"; }
  &-floppy-disk::before { content: "\f406"; }
  &-floppy-disk-back::before { content: "\f407"; }
  &-flow-arrow::before { content: "\f408"; }
  &-flower::before { content: "\f409"; }
  &-flower-lotus::before { content: "\f40a"; }
  &-flying-saucer::before { content: "\f40b"; }
  &-folder::before { content: "\f40c"; }
  &-folder-dotted::before { content: "\f40d"; }
  &-folder-lock::before { content: "\f40e"; }
  &-folder-minus::before { content: "\f40f"; }
  &-folder-notch::before { content: "\f410"; }
  &-folder-notch-minus::before { content: "\f411"; }
  &-folder-notch-open::before { content: "\f412"; }
  &-folder-notch-plus::before { content: "\f413"; }
  &-folder-open::before { content: "\f414"; }
  &-folder-plus::before { content: "\f415"; }
  &-folder-simple::before { content: "\f416"; }
  &-folder-simple-dotted::before { content: "\f417"; }
  &-folder-simple-lock::before { content: "\f418"; }
  &-folder-simple-minus::before { content: "\f419"; }
  &-folder-simple-plus::before { content: "\f41a"; }
  &-folder-simple-star::before { content: "\f41b"; }
  &-folder-simple-user::before { content: "\f41c"; }
  &-folder-star::before { content: "\f41d"; }
  &-folder-user::before { content: "\f41e"; }
  &-folders::before { content: "\f41f"; }
  &-football::before { content: "\f420"; }
  &-fork-knife::before { content: "\f421"; }
  &-frame-corners::before { content: "\f422"; }
  &-framer-logo::before { content: "\f423"; }
  &-function::before { content: "\f424"; }
  &-funnel::before { content: "\f425"; }
  &-funnel-simple::before { content: "\f426"; }
  &-game-controller::before { content: "\f427"; }
  &-gas-pump::before { content: "\f428"; }
  &-gauge::before { content: "\f429"; }
  &-gear::before { content: "\f42a"; }
  &-gear-six::before { content: "\f42b"; }
  &-gender-female::before { content: "\f42c"; }
  &-gender-intersex::before { content: "\f42d"; }
  &-gender-male::before { content: "\f42e"; }
  &-gender-neuter::before { content: "\f42f"; }
  &-gender-nonbinary::before { content: "\f430"; }
  &-gender-transgender::before { content: "\f431"; }
  &-ghost::before { content: "\f432"; }
  &-gif::before { content: "\f433"; }
  &-gift::before { content: "\f434"; }
  &-git-branch::before { content: "\f435"; }
  &-git-commit::before { content: "\f436"; }
  &-git-diff::before { content: "\f437"; }
  &-git-fork::before { content: "\f438"; }
  &-git-merge::before { content: "\f439"; }
  &-git-pull-request::before { content: "\f43a"; }
  &-github-logo::before { content: "\f43b"; }
  &-gitlab-logo::before { content: "\f43c"; }
  &-gitlab-logo-simple::before { content: "\f43d"; }
  &-globe::before { content: "\f43e"; }
  &-globe-hemisphere-east::before { content: "\f43f"; }
  &-globe-hemisphere-west::before { content: "\f440"; }
  &-globe-simple::before { content: "\f441"; }
  &-globe-stand::before { content: "\f442"; }
  &-google-chrome-logo::before { content: "\f443"; }
  &-google-logo::before { content: "\f444"; }
  &-google-photos-logo::before { content: "\f445"; }
  &-google-play-logo::before { content: "\f446"; }
  &-google-podcasts-logo::before { content: "\f447"; }
  &-gradient::before { content: "\f448"; }
  &-graduation-cap::before { content: "\f449"; }
  &-graph::before { content: "\f44a"; }
  &-grid-four::before { content: "\f44b"; }
  &-hamburger::before { content: "\f44c"; }
  &-hand::before { content: "\f44d"; }
  &-hand-eye::before { content: "\f44e"; }
  &-hand-fist::before { content: "\f44f"; }
  &-hand-grabbing::before { content: "\f450"; }
  &-hand-palm::before { content: "\f451"; }
  &-hand-pointing::before { content: "\f452"; }
  &-hand-soap::before { content: "\f453"; }
  &-hand-waving::before { content: "\f454"; }
  &-handbag::before { content: "\f455"; }
  &-handbag-simple::before { content: "\f456"; }
  &-hands-clapping::before { content: "\f457"; }
  &-handshake::before { content: "\f458"; }
  &-hard-drive::before { content: "\f459"; }
  &-hard-drives::before { content: "\f45a"; }
  &-hash::before { content: "\f45b"; }
  &-hash-straight::before { content: "\f45c"; }
  &-headlights::before { content: "\f45d"; }
  &-headphones::before { content: "\f45e"; }
  &-headset::before { content: "\f45f"; }
  &-heart::before { content: "\f460"; }
  &-heart-break::before { content: "\f461"; }
  &-heart-straight::before { content: "\f462"; }
  &-heart-straight-break::before { content: "\f463"; }
  &-heartbeat::before { content: "\f464"; }
  &-hexagon::before { content: "\f465"; }
  &-highlighter-circle::before { content: "\f466"; }
  &-horse::before { content: "\f467"; }
  &-hourglass::before { content: "\f468"; }
  &-hourglass-high::before { content: "\f469"; }
  &-hourglass-low::before { content: "\f46a"; }
  &-hourglass-medium::before { content: "\f46b"; }
  &-hourglass-simple::before { content: "\f46c"; }
  &-hourglass-simple-high::before { content: "\f46d"; }
  &-hourglass-simple-low::before { content: "\f46e"; }
  &-hourglass-simple-medium::before { content: "\f46f"; }
  &-house::before { content: "\f470"; }
  &-house-line::before { content: "\f471"; }
  &-house-simple::before { content: "\f472"; }
  &-identification-badge::before { content: "\f473"; }
  &-identification-card::before { content: "\f474"; }
  &-image::before { content: "\f475"; }
  &-image-square::before { content: "\f476"; }
  &-infinity::before { content: "\f477"; }
  &-info::before { content: "\f478"; }
  &-instagram-logo::before { content: "\f479"; }
  &-intersect::before { content: "\f47a"; }
  &-jeep::before { content: "\f47b"; }
  &-kanban::before { content: "\f47c"; }
  &-key::before { content: "\f47d"; }
  &-key-return::before { content: "\f47e"; }
  &-keyboard::before { content: "\f47f"; }
  &-keyhole::before { content: "\f480"; }
  &-knife::before { content: "\f481"; }
  &-ladder::before { content: "\f482"; }
  &-ladder-simple::before { content: "\f483"; }
  &-lamp::before { content: "\f484"; }
  &-laptop::before { content: "\f485"; }
  &-layout::before { content: "\f486"; }
  &-leaf::before { content: "\f487"; }
  &-lifebuoy::before { content: "\f488"; }
  &-lightbulb::before { content: "\f489"; }
  &-lightbulb-filament::before { content: "\f48a"; }
  &-lightning::before { content: "\f48b"; }
  &-lightning-slash::before { content: "\f48c"; }
  &-line-segment::before { content: "\f48d"; }
  &-line-segments::before { content: "\f48e"; }
  &-link::before { content: "\f48f"; }
  &-link-break::before { content: "\f490"; }
  &-link-simple::before { content: "\f491"; }
  &-link-simple-break::before { content: "\f492"; }
  &-link-simple-horizontal::before { content: "\f493"; }
  &-link-simple-horizontal-break::before { content: "\f494"; }
  &-linkedin-logo::before { content: "\f495"; }
  &-linux-logo::before { content: "\f496"; }
  &-list::before { content: "\f497"; }
  &-list-bullets::before { content: "\f498"; }
  &-list-checks::before { content: "\f499"; }
  &-list-dashes::before { content: "\f49a"; }
  &-list-numbers::before { content: "\f49b"; }
  &-list-plus::before { content: "\f49c"; }
  &-lock::before { content: "\f49d"; }
  &-lock-key::before { content: "\f49e"; }
  &-lock-key-open::before { content: "\f49f"; }
  &-lock-laminated::before { content: "\f4a0"; }
  &-lock-laminated-open::before { content: "\f4a1"; }
  &-lock-open::before { content: "\f4a2"; }
  &-lock-simple::before { content: "\f4a3"; }
  &-lock-simple-open::before { content: "\f4a4"; }
  &-magic-wand::before { content: "\f4a5"; }
  &-magnet::before { content: "\f4a6"; }
  &-magnet-straight::before { content: "\f4a7"; }
  &-magnifying-glass::before { content: "\f4a8"; }
  &-magnifying-glass-minus::before { content: "\f4a9"; }
  &-magnifying-glass-plus::before { content: "\f4aa"; }
  &-map-pin::before { content: "\f4ab"; }
  &-map-pin-line::before { content: "\f4ac"; }
  &-map-trifold::before { content: "\f4ad"; }
  &-marker-circle::before { content: "\f4ae"; }
  &-martini::before { content: "\f4af"; }
  &-mask-happy::before { content: "\f4b0"; }
  &-mask-sad::before { content: "\f4b1"; }
  &-math-operations::before { content: "\f4b2"; }
  &-medal::before { content: "\f4b3"; }
  &-medium-logo::before { content: "\f4b4"; }
  &-megaphone::before { content: "\f4b5"; }
  &-megaphone-simple::before { content: "\f4b6"; }
  &-messenger-logo::before { content: "\f4b7"; }
  &-microphone::before { content: "\f4b8"; }
  &-microphone-slash::before { content: "\f4b9"; }
  &-microphone-stage::before { content: "\f4ba"; }
  &-microsoft-excel-logo::before { content: "\f4bb"; }
  &-microsoft-powerpoint-logo::before { content: "\f4bc"; }
  &-microsoft-teams-logo::before { content: "\f4bd"; }
  &-microsoft-word-logo::before { content: "\f4be"; }
  &-minus::before { content: "\f4bf"; }
  &-minus-circle::before { content: "\f4c0"; }
  &-money::before { content: "\f4c1"; }
  &-monitor::before { content: "\f4c2"; }
  &-monitor-play::before { content: "\f4c3"; }
  &-moon::before { content: "\f4c4"; }
  &-moon-stars::before { content: "\f4c5"; }
  &-mountains::before { content: "\f4c6"; }
  &-mouse::before { content: "\f4c7"; }
  &-mouse-simple::before { content: "\f4c8"; }
  &-music-note::before { content: "\f4c9"; }
  &-music-note-simple::before { content: "\f4ca"; }
  &-music-notes::before { content: "\f4cb"; }
  &-music-notes-plus::before { content: "\f4cc"; }
  &-music-notes-simple::before { content: "\f4cd"; }
  &-navigation-arrow::before { content: "\f4ce"; }
  &-needle::before { content: "\f4cf"; }
  &-newspaper::before { content: "\f4d0"; }
  &-newspaper-clipping::before { content: "\f4d1"; }
  &-note::before { content: "\f4d2"; }
  &-note-blank::before { content: "\f4d3"; }
  &-note-pencil::before { content: "\f4d4"; }
  &-notebook::before { content: "\f4d5"; }
  &-notepad::before { content: "\f4d6"; }
  &-notification::before { content: "\f4d7"; }
  &-number-circle-eight::before { content: "\f4d8"; }
  &-number-circle-five::before { content: "\f4d9"; }
  &-number-circle-four::before { content: "\f4da"; }
  &-number-circle-nine::before { content: "\f4db"; }
  &-number-circle-one::before { content: "\f4dc"; }
  &-number-circle-seven::before { content: "\f4dd"; }
  &-number-circle-six::before { content: "\f4de"; }
  &-number-circle-three::before { content: "\f4df"; }
  &-number-circle-two::before { content: "\f4e0"; }
  &-number-circle-zero::before { content: "\f4e1"; }
  &-number-eight::before { content: "\f4e2"; }
  &-number-five::before { content: "\f4e3"; }
  &-number-four::before { content: "\f4e4"; }
  &-number-nine::before { content: "\f4e5"; }
  &-number-one::before { content: "\f4e6"; }
  &-number-seven::before { content: "\f4e7"; }
  &-number-six::before { content: "\f4e8"; }
  &-number-square-eight::before { content: "\f4e9"; }
  &-number-square-five::before { content: "\f4ea"; }
  &-number-square-four::before { content: "\f4eb"; }
  &-number-square-nine::before { content: "\f4ec"; }
  &-number-square-one::before { content: "\f4ed"; }
  &-number-square-seven::before { content: "\f4ee"; }
  &-number-square-six::before { content: "\f4ef"; }
  &-number-square-three::before { content: "\f4f0"; }
  &-number-square-two::before { content: "\f4f1"; }
  &-number-square-zero::before { content: "\f4f2"; }
  &-number-three::before { content: "\f4f3"; }
  &-number-two::before { content: "\f4f4"; }
  &-number-zero::before { content: "\f4f5"; }
  &-nut::before { content: "\f4f6"; }
  &-ny-times-logo::before { content: "\f4f7"; }
  &-octagon::before { content: "\f4f8"; }
  &-option::before { content: "\f4f9"; }
  &-package::before { content: "\f4fa"; }
  &-paint-brush::before { content: "\f4fb"; }
  &-paint-brush-broad::before { content: "\f4fc"; }
  &-paint-brush-household::before { content: "\f4fd"; }
  &-paint-bucket::before { content: "\f4fe"; }
  &-paint-roller::before { content: "\f4ff"; }
  &-palette::before { content: "\f500"; }
  &-paper-plane::before { content: "\f501"; }
  &-paper-plane-right::before { content: "\f502"; }
  &-paper-plane-tilt::before { content: "\f503"; }
  &-paperclip::before { content: "\f504"; }
  &-paperclip-horizontal::before { content: "\f505"; }
  &-parachute::before { content: "\f506"; }
  &-password::before { content: "\f507"; }
  &-path::before { content: "\f508"; }
  &-pause::before { content: "\f509"; }
  &-pause-circle::before { content: "\f50a"; }
  &-paw-print::before { content: "\f50b"; }
  &-peace::before { content: "\f50c"; }
  &-pen::before { content: "\f50d"; }
  &-pen-nib::before { content: "\f50e"; }
  &-pen-nib-straight::before { content: "\f50f"; }
  &-pencil::before { content: "\f510"; }
  &-pencil-circle::before { content: "\f511"; }
  &-pencil-line::before { content: "\f512"; }
  &-pencil-simple::before { content: "\f513"; }
  &-pencil-simple-line::before { content: "\f514"; }
  &-percent::before { content: "\f515"; }
  &-person::before { content: "\f516"; }
  &-person-simple::before { content: "\f517"; }
  &-person-simple-run::before { content: "\f518"; }
  &-person-simple-walk::before { content: "\f519"; }
  &-perspective::before { content: "\f51a"; }
  &-phone::before { content: "\f51b"; }
  &-phone-call::before { content: "\f51c"; }
  &-phone-disconnect::before { content: "\f51d"; }
  &-phone-incoming::before { content: "\f51e"; }
  &-phone-outgoing::before { content: "\f51f"; }
  &-phone-slash::before { content: "\f520"; }
  &-phone-x::before { content: "\f521"; }
  &-phosphor-logo::before { content: "\f522"; }
  &-piano-keys::before { content: "\f523"; }
  &-picture-in-picture::before { content: "\f524"; }
  &-pill::before { content: "\f525"; }
  &-pinterest-logo::before { content: "\f526"; }
  &-pinwheel::before { content: "\f527"; }
  &-pizza::before { content: "\f528"; }
  &-placeholder::before { content: "\f529"; }
  &-planet::before { content: "\f52a"; }
  &-play::before { content: "\f52b"; }
  &-play-circle::before { content: "\f52c"; }
  &-playlist::before { content: "\f52d"; }
  &-plug::before { content: "\f52e"; }
  &-plugs::before { content: "\f52f"; }
  &-plugs-connected::before { content: "\f530"; }
  &-plus::before { content: "\f531"; }
  &-plus-circle::before { content: "\f532"; }
  &-plus-minus::before { content: "\f533"; }
  &-poker-chip::before { content: "\f534"; }
  &-police-car::before { content: "\f535"; }
  &-polygon::before { content: "\f536"; }
  &-popcorn::before { content: "\f537"; }
  &-power::before { content: "\f538"; }
  &-prescription::before { content: "\f539"; }
  &-presentation::before { content: "\f53a"; }
  &-presentation-chart::before { content: "\f53b"; }
  &-printer::before { content: "\f53c"; }
  &-prohibit::before { content: "\f53d"; }
  &-prohibit-inset::before { content: "\f53e"; }
  &-projector-screen::before { content: "\f53f"; }
  &-projector-screen-chart::before { content: "\f540"; }
  &-push-pin::before { content: "\f541"; }
  &-push-pin-simple::before { content: "\f542"; }
  &-push-pin-simple-slash::before { content: "\f543"; }
  &-push-pin-slash::before { content: "\f544"; }
  &-puzzle-piece::before { content: "\f545"; }
  &-qr-code::before { content: "\f546"; }
  &-question::before { content: "\f547"; }
  &-queue::before { content: "\f548"; }
  &-quotes::before { content: "\f549"; }
  &-radical::before { content: "\f54a"; }
  &-radio::before { content: "\f54b"; }
  &-radio-button::before { content: "\f54c"; }
  &-rainbow::before { content: "\f54d"; }
  &-rainbow-cloud::before { content: "\f54e"; }
  &-receipt::before { content: "\f54f"; }
  &-record::before { content: "\f550"; }
  &-rectangle::before { content: "\f551"; }
  &-recycle::before { content: "\f552"; }
  &-reddit-logo::before { content: "\f553"; }
  &-repeat::before { content: "\f554"; }
  &-repeat-once::before { content: "\f555"; }
  &-rewind::before { content: "\f556"; }
  &-rewind-circle::before { content: "\f557"; }
  &-robot::before { content: "\f558"; }
  &-rocket::before { content: "\f559"; }
  &-rocket-launch::before { content: "\f55a"; }
  &-rows::before { content: "\f55b"; }
  &-rss::before { content: "\f55c"; }
  &-rss-simple::before { content: "\f55d"; }
  &-rug::before { content: "\f55e"; }
  &-ruler::before { content: "\f55f"; }
  &-scales::before { content: "\f560"; }
  &-scan::before { content: "\f561"; }
  &-scissors::before { content: "\f562"; }
  &-screencast::before { content: "\f563"; }
  &-scribble-loop::before { content: "\f564"; }
  &-scroll::before { content: "\f565"; }
  &-selection::before { content: "\f566"; }
  &-selection-all::before { content: "\f567"; }
  &-selection-background::before { content: "\f568"; }
  &-selection-foreground::before { content: "\f569"; }
  &-selection-inverse::before { content: "\f56a"; }
  &-selection-plus::before { content: "\f56b"; }
  &-selection-slash::before { content: "\f56c"; }
  &-share::before { content: "\f56d"; }
  &-share-network::before { content: "\f56e"; }
  &-shield::before { content: "\f56f"; }
  &-shield-check::before { content: "\f570"; }
  &-shield-checkered::before { content: "\f571"; }
  &-shield-chevron::before { content: "\f572"; }
  &-shield-plus::before { content: "\f573"; }
  &-shield-slash::before { content: "\f574"; }
  &-shield-star::before { content: "\f575"; }
  &-shield-warning::before { content: "\f576"; }
  &-shopping-bag::before { content: "\f577"; }
  &-shopping-bag-open::before { content: "\f578"; }
  &-shopping-cart::before { content: "\f579"; }
  &-shopping-cart-simple::before { content: "\f57a"; }
  &-shower::before { content: "\f57b"; }
  &-shuffle::before { content: "\f57c"; }
  &-shuffle-angular::before { content: "\f57d"; }
  &-shuffle-simple::before { content: "\f57e"; }
  &-sidebar::before { content: "\f57f"; }
  &-sidebar-simple::before { content: "\f580"; }
  &-sign-in::before { content: "\f581"; }
  &-sign-out::before { content: "\f582"; }
  &-signpost::before { content: "\f583"; }
  &-sim-card::before { content: "\f584"; }
  &-sketch-logo::before { content: "\f585"; }
  &-skip-back::before { content: "\f586"; }
  &-skip-back-circle::before { content: "\f587"; }
  &-skip-forward::before { content: "\f588"; }
  &-skip-forward-circle::before { content: "\f589"; }
  &-skull::before { content: "\f58a"; }
  &-slack-logo::before { content: "\f58b"; }
  &-sliders::before { content: "\f58c"; }
  &-sliders-horizontal::before { content: "\f58d"; }
  &-smiley::before { content: "\f58e"; }
  &-smiley-blank::before { content: "\f58f"; }
  &-smiley-meh::before { content: "\f590"; }
  &-smiley-nervous::before { content: "\f591"; }
  &-smiley-sad::before { content: "\f592"; }
  &-smiley-sticker::before { content: "\f593"; }
  &-smiley-wink::before { content: "\f594"; }
  &-smiley-x-eyes::before { content: "\f595"; }
  &-snapchat-logo::before { content: "\f596"; }
  &-snowflake::before { content: "\f597"; }
  &-soccer-ball::before { content: "\f598"; }
  &-sort-ascending::before { content: "\f599"; }
  &-sort-descending::before { content: "\f59a"; }
  &-spade::before { content: "\f59b"; }
  &-sparkle::before { content: "\f59c"; }
  &-speaker-high::before { content: "\f59d"; }
  &-speaker-low::before { content: "\f59e"; }
  &-speaker-none::before { content: "\f59f"; }
  &-speaker-simple-high::before { content: "\f5a0"; }
  &-speaker-simple-low::before { content: "\f5a1"; }
  &-speaker-simple-none::before { content: "\f5a2"; }
  &-speaker-simple-slash::before { content: "\f5a3"; }
  &-speaker-simple-x::before { content: "\f5a4"; }
  &-speaker-slash::before { content: "\f5a5"; }
  &-speaker-x::before { content: "\f5a6"; }
  &-spinner::before { content: "\f5a7"; }
  &-spinner-gap::before { content: "\f5a8"; }
  &-spiral::before { content: "\f5a9"; }
  &-spotify-logo::before { content: "\f5aa"; }
  &-square::before { content: "\f5ab"; }
  &-square-half::before { content: "\f5ac"; }
  &-square-half-bottom::before { content: "\f5ad"; }
  &-square-logo::before { content: "\f5ae"; }
  &-squares-four::before { content: "\f5af"; }
  &-stack::before { content: "\f5b0"; }
  &-stack-overflow-logo::before { content: "\f5b1"; }
  &-stack-simple::before { content: "\f5b2"; }
  &-stamp::before { content: "\f5b3"; }
  &-star::before { content: "\f5b4"; }
  &-star-four::before { content: "\f5b5"; }
  &-star-half::before { content: "\f5b6"; }
  &-sticker::before { content: "\f5b7"; }
  &-stop::before { content: "\f5b8"; }
  &-stop-circle::before { content: "\f5b9"; }
  &-storefront::before { content: "\f5ba"; }
  &-strategy::before { content: "\f5bb"; }
  &-stripe-logo::before { content: "\f5bc"; }
  &-student::before { content: "\f5bd"; }
  &-suitcase::before { content: "\f5be"; }
  &-suitcase-simple::before { content: "\f5bf"; }
  &-sun::before { content: "\f5c0"; }
  &-sun-dim::before { content: "\f5c1"; }
  &-sun-horizon::before { content: "\f5c2"; }
  &-sunglasses::before { content: "\f5c3"; }
  &-swap::before { content: "\f5c4"; }
  &-swatches::before { content: "\f5c5"; }
  &-sword::before { content: "\f5c6"; }
  &-syringe::before { content: "\f5c7"; }
  &-t-shirt::before { content: "\f5c8"; }
  &-table::before { content: "\f5c9"; }
  &-tabs::before { content: "\f5ca"; }
  &-tag::before { content: "\f5cb"; }
  &-tag-chevron::before { content: "\f5cc"; }
  &-tag-simple::before { content: "\f5cd"; }
  &-target::before { content: "\f5ce"; }
  &-taxi::before { content: "\f5cf"; }
  &-telegram-logo::before { content: "\f5d0"; }
  &-television::before { content: "\f5d1"; }
  &-television-simple::before { content: "\f5d2"; }
  &-tennis-ball::before { content: "\f5d3"; }
  &-terminal::before { content: "\f5d4"; }
  &-terminal-window::before { content: "\f5d5"; }
  &-test-tube::before { content: "\f5d6"; }
  &-text-aa::before { content: "\f5d7"; }
  &-text-align-center::before { content: "\f5d8"; }
  &-text-align-justify::before { content: "\f5d9"; }
  &-text-align-left::before { content: "\f5da"; }
  &-text-align-right::before { content: "\f5db"; }
  &-text-bolder::before { content: "\f5dc"; }
  &-text-h::before { content: "\f5dd"; }
  &-text-h-five::before { content: "\f5de"; }
  &-text-h-four::before { content: "\f5df"; }
  &-text-h-one::before { content: "\f5e0"; }
  &-text-h-six::before { content: "\f5e1"; }
  &-text-h-three::before { content: "\f5e2"; }
  &-text-h-two::before { content: "\f5e3"; }
  &-text-indent::before { content: "\f5e4"; }
  &-text-italic::before { content: "\f5e5"; }
  &-text-outdent::before { content: "\f5e6"; }
  &-text-strikethrough::before { content: "\f5e7"; }
  &-text-t::before { content: "\f5e8"; }
  &-text-underline::before { content: "\f5e9"; }
  &-textbox::before { content: "\f5ea"; }
  &-thermometer::before { content: "\f5eb"; }
  &-thermometer-cold::before { content: "\f5ec"; }
  &-thermometer-hot::before { content: "\f5ed"; }
  &-thermometer-simple::before { content: "\f5ee"; }
  &-thumbs-down::before { content: "\f5ef"; }
  &-thumbs-up::before { content: "\f5f0"; }
  &-ticket::before { content: "\f5f1"; }
  &-tiktok-logo::before { content: "\f5f2"; }
  &-timer::before { content: "\f5f3"; }
  &-toggle-left::before { content: "\f5f4"; }
  &-toggle-right::before { content: "\f5f5"; }
  &-toilet::before { content: "\f5f6"; }
  &-toilet-paper::before { content: "\f5f7"; }
  &-tote::before { content: "\f5f8"; }
  &-tote-simple::before { content: "\f5f9"; }
  &-trademark-registered::before { content: "\f5fa"; }
  &-traffic-cone::before { content: "\f5fb"; }
  &-traffic-sign::before { content: "\f5fc"; }
  &-traffic-signal::before { content: "\f5fd"; }
  &-train::before { content: "\f5fe"; }
  &-train-regional::before { content: "\f5ff"; }
  &-train-simple::before { content: "\f600"; }
  &-translate::before { content: "\f601"; }
  &-trash::before { content: "\f602"; }
  &-trash-simple::before { content: "\f603"; }
  &-tray::before { content: "\f604"; }
  &-tree::before { content: "\f605"; }
  &-tree-evergreen::before { content: "\f606"; }
  &-tree-structure::before { content: "\f607"; }
  &-trend-down::before { content: "\f608"; }
  &-trend-up::before { content: "\f609"; }
  &-triangle::before { content: "\f60a"; }
  &-trophy::before { content: "\f60b"; }
  &-truck::before { content: "\f60c"; }
  &-twitch-logo::before { content: "\f60d"; }
  &-twitter-logo::before { content: "\f60e"; }
  &-umbrella::before { content: "\f60f"; }
  &-umbrella-simple::before { content: "\f610"; }
  &-upload::before { content: "\f611"; }
  &-upload-simple::before { content: "\f612"; }
  &-user::before { content: "\f613"; }
  &-user-circle::before { content: "\f614"; }
  &-user-circle-gear::before { content: "\f615"; }
  &-user-circle-minus::before { content: "\f616"; }
  &-user-circle-plus::before { content: "\f617"; }
  &-user-focus::before { content: "\f618"; }
  &-user-gear::before { content: "\f619"; }
  &-user-list::before { content: "\f61a"; }
  &-user-minus::before { content: "\f61b"; }
  &-user-plus::before { content: "\f61c"; }
  &-user-rectangle::before { content: "\f61d"; }
  &-user-square::before { content: "\f61e"; }
  &-user-switch::before { content: "\f61f"; }
  &-users::before { content: "\f620"; }
  &-users-four::before { content: "\f621"; }
  &-users-three::before { content: "\f622"; }
  &-vault::before { content: "\f623"; }
  &-vibrate::before { content: "\f624"; }
  &-video-camera::before { content: "\f625"; }
  &-video-camera-slash::before { content: "\f626"; }
  &-vignette::before { content: "\f627"; }
  &-voicemail::before { content: "\f628"; }
  &-volleyball::before { content: "\f629"; }
  &-wall::before { content: "\f62a"; }
  &-wallet::before { content: "\f62b"; }
  &-warning::before { content: "\f62c"; }
  &-warning-circle::before { content: "\f62d"; }
  &-warning-octagon::before { content: "\f62e"; }
  &-watch::before { content: "\f62f"; }
  &-wave-sawtooth::before { content: "\f630"; }
  &-wave-sine::before { content: "\f631"; }
  &-wave-square::before { content: "\f632"; }
  &-wave-triangle::before { content: "\f633"; }
  &-waves::before { content: "\f634"; }
  &-webcam::before { content: "\f635"; }
  &-whatsapp-logo::before { content: "\f636"; }
  &-wheelchair::before { content: "\f637"; }
  &-wifi-high::before { content: "\f638"; }
  &-wifi-low::before { content: "\f639"; }
  &-wifi-medium::before { content: "\f63a"; }
  &-wifi-none::before { content: "\f63b"; }
  &-wifi-slash::before { content: "\f63c"; }
  &-wifi-x::before { content: "\f63d"; }
  &-wind::before { content: "\f63e"; }
  &-windows-logo::before { content: "\f63f"; }
  &-wine::before { content: "\f640"; }
  &-wrench::before { content: "\f641"; }
  &-x::before { content: "\f642"; }
  &-x-circle::before { content: "\f643"; }
  &-x-square::before { content: "\f644"; }
  &-yin-yang::before { content: "\f645"; }
  &-youtube-logo::before { content: "\f646"; }

  &-activity-light::before { content: "\ee19"; }
  &-address-book-light::before { content: "\ee1a"; }
  &-airplane-in-flight-light::before { content: "\ee1b"; }
  &-airplane-landing-light::before { content: "\ee1c"; }
  &-airplane-light::before { content: "\ee1d"; }
  &-airplane-takeoff-light::before { content: "\ee1e"; }
  &-airplane-tilt-light::before { content: "\ee1f"; }
  &-airplay-light::before { content: "\ee20"; }
  &-alarm-light::before { content: "\ee21"; }
  &-alien-light::before { content: "\ee22"; }
  &-align-bottom-light::before { content: "\ee23"; }
  &-align-bottom-simple-light::before { content: "\ee24"; }
  &-align-center-horizontal-light::before { content: "\ee25"; }
  &-align-center-horizontal-simple-light::before { content: "\ee26"; }
  &-align-center-vertical-light::before { content: "\ee27"; }
  &-align-center-vertical-simple-light::before { content: "\ee28"; }
  &-align-left-light::before { content: "\ee29"; }
  &-align-left-simple-light::before { content: "\ee2a"; }
  &-align-right-light::before { content: "\ee2b"; }
  &-align-right-simple-light::before { content: "\ee2c"; }
  &-align-top-light::before { content: "\ee2d"; }
  &-align-top-simple-light::before { content: "\ee2e"; }
  &-anchor-light::before { content: "\ee2f"; }
  &-anchor-simple-light::before { content: "\ee30"; }
  &-android-logo-light::before { content: "\ee31"; }
  &-angular-logo-light::before { content: "\ee32"; }
  &-aperture-light::before { content: "\ee33"; }
  &-app-store-logo-light::before { content: "\ee34"; }
  &-app-window-light::before { content: "\ee35"; }
  &-apple-logo-light::before { content: "\ee36"; }
  &-apple-podcasts-logo-light::before { content: "\ee37"; }
  &-archive-box-light::before { content: "\ee38"; }
  &-archive-light::before { content: "\ee39"; }
  &-archive-tray-light::before { content: "\ee3a"; }
  &-armchair-light::before { content: "\ee3b"; }
  &-arrow-arc-left-light::before { content: "\ee3c"; }
  &-arrow-arc-right-light::before { content: "\ee3d"; }
  &-arrow-bend-double-up-left-light::before { content: "\ee3e"; }
  &-arrow-bend-double-up-right-light::before { content: "\ee3f"; }
  &-arrow-bend-down-left-light::before { content: "\ee40"; }
  &-arrow-bend-down-right-light::before { content: "\ee41"; }
  &-arrow-bend-left-down-light::before { content: "\ee42"; }
  &-arrow-bend-left-up-light::before { content: "\ee43"; }
  &-arrow-bend-right-down-light::before { content: "\ee44"; }
  &-arrow-bend-right-up-light::before { content: "\ee45"; }
  &-arrow-bend-up-left-light::before { content: "\ee46"; }
  &-arrow-bend-up-right-light::before { content: "\ee47"; }
  &-arrow-circle-down-left-light::before { content: "\ee48"; }
  &-arrow-circle-down-light::before { content: "\ee49"; }
  &-arrow-circle-down-right-light::before { content: "\ee4a"; }
  &-arrow-circle-left-light::before { content: "\ee4b"; }
  &-arrow-circle-right-light::before { content: "\ee4c"; }
  &-arrow-circle-up-left-light::before { content: "\ee4d"; }
  &-arrow-circle-up-light::before { content: "\ee4e"; }
  &-arrow-circle-up-right-light::before { content: "\ee4f"; }
  &-arrow-clockwise-light::before { content: "\ee50"; }
  &-arrow-counter-clockwise-light::before { content: "\ee51"; }
  &-arrow-down-left-light::before { content: "\ee52"; }
  &-arrow-down-light::before { content: "\ee53"; }
  &-arrow-down-right-light::before { content: "\ee54"; }
  &-arrow-elbow-down-left-light::before { content: "\ee55"; }
  &-arrow-elbow-down-right-light::before { content: "\ee56"; }
  &-arrow-elbow-left-down-light::before { content: "\ee57"; }
  &-arrow-elbow-left-light::before { content: "\ee58"; }
  &-arrow-elbow-left-up-light::before { content: "\ee59"; }
  &-arrow-elbow-right-down-light::before { content: "\ee5a"; }
  &-arrow-elbow-right-light::before { content: "\ee5b"; }
  &-arrow-elbow-right-up-light::before { content: "\ee5c"; }
  &-arrow-elbow-up-left-light::before { content: "\ee5d"; }
  &-arrow-elbow-up-right-light::before { content: "\ee5e"; }
  &-arrow-fat-down-light::before { content: "\ee5f"; }
  &-arrow-fat-left-light::before { content: "\ee60"; }
  &-arrow-fat-line-down-light::before { content: "\ee61"; }
  &-arrow-fat-line-left-light::before { content: "\ee62"; }
  &-arrow-fat-line-right-light::before { content: "\ee63"; }
  &-arrow-fat-line-up-light::before { content: "\ee64"; }
  &-arrow-fat-lines-down-light::before { content: "\ee65"; }
  &-arrow-fat-lines-left-light::before { content: "\ee66"; }
  &-arrow-fat-lines-right-light::before { content: "\ee67"; }
  &-arrow-fat-lines-up-light::before { content: "\ee68"; }
  &-arrow-fat-right-light::before { content: "\ee69"; }
  &-arrow-fat-up-light::before { content: "\ee6a"; }
  &-arrow-left-light::before { content: "\ee6b"; }
  &-arrow-line-down-left-light::before { content: "\ee6c"; }
  &-arrow-line-down-light::before { content: "\ee6d"; }
  &-arrow-line-down-right-light::before { content: "\ee6e"; }
  &-arrow-line-left-light::before { content: "\ee6f"; }
  &-arrow-line-right-light::before { content: "\ee70"; }
  &-arrow-line-up-left-light::before { content: "\ee71"; }
  &-arrow-line-up-light::before { content: "\ee72"; }
  &-arrow-line-up-right-light::before { content: "\ee73"; }
  &-arrow-right-light::before { content: "\ee74"; }
  &-arrow-square-down-left-light::before { content: "\ee75"; }
  &-arrow-square-down-light::before { content: "\ee76"; }
  &-arrow-square-down-right-light::before { content: "\ee77"; }
  &-arrow-square-in-light::before { content: "\ee78"; }
  &-arrow-square-left-light::before { content: "\ee79"; }
  &-arrow-square-out-light::before { content: "\ee7a"; }
  &-arrow-square-right-light::before { content: "\ee7b"; }
  &-arrow-square-up-left-light::before { content: "\ee7c"; }
  &-arrow-square-up-light::before { content: "\ee7d"; }
  &-arrow-square-up-right-light::before { content: "\ee7e"; }
  &-arrow-u-down-left-light::before { content: "\ee7f"; }
  &-arrow-u-down-right-light::before { content: "\ee80"; }
  &-arrow-u-left-down-light::before { content: "\ee81"; }
  &-arrow-u-left-up-light::before { content: "\ee82"; }
  &-arrow-u-right-down-light::before { content: "\ee83"; }
  &-arrow-u-right-up-light::before { content: "\ee84"; }
  &-arrow-u-up-left-light::before { content: "\ee85"; }
  &-arrow-u-up-right-light::before { content: "\ee86"; }
  &-arrow-up-left-light::before { content: "\ee87"; }
  &-arrow-up-light::before { content: "\ee88"; }
  &-arrow-up-right-light::before { content: "\ee89"; }
  &-arrows-clockwise-light::before { content: "\ee8a"; }
  &-arrows-counter-clockwise-light::before { content: "\ee8b"; }
  &-arrows-down-up-light::before { content: "\ee8c"; }
  &-arrows-horizontal-light::before { content: "\ee8d"; }
  &-arrows-in-cardinal-light::before { content: "\ee8e"; }
  &-arrows-in-light::before { content: "\ee8f"; }
  &-arrows-in-line-horizontal-light::before { content: "\ee90"; }
  &-arrows-in-line-vertical-light::before { content: "\ee91"; }
  &-arrows-in-simple-light::before { content: "\ee92"; }
  &-arrows-left-right-light::before { content: "\ee93"; }
  &-arrows-out-cardinal-light::before { content: "\ee94"; }
  &-arrows-out-light::before { content: "\ee95"; }
  &-arrows-out-line-horizontal-light::before { content: "\ee96"; }
  &-arrows-out-line-vertical-light::before { content: "\ee97"; }
  &-arrows-out-simple-light::before { content: "\ee98"; }
  &-arrows-vertical-light::before { content: "\ee99"; }
  &-article-light::before { content: "\ee9a"; }
  &-article-medium-light::before { content: "\ee9b"; }
  &-article-ny-times-light::before { content: "\ee9c"; }
  &-asterisk-light::before { content: "\ee9d"; }
  &-asterisk-simple-light::before { content: "\ee9e"; }
  &-at-light::before { content: "\ee9f"; }
  &-atom-light::before { content: "\eea0"; }
  &-baby-light::before { content: "\eea1"; }
  &-backpack-light::before { content: "\eea2"; }
  &-backspace-light::before { content: "\eea3"; }
  &-bag-light::before { content: "\eea4"; }
  &-bag-simple-light::before { content: "\eea5"; }
  &-balloon-light::before { content: "\eea6"; }
  &-bandaids-light::before { content: "\eea7"; }
  &-bank-light::before { content: "\eea8"; }
  &-barbell-light::before { content: "\eea9"; }
  &-barcode-light::before { content: "\eeaa"; }
  &-barricade-light::before { content: "\eeab"; }
  &-baseball-light::before { content: "\eeac"; }
  &-basketball-light::before { content: "\eead"; }
  &-bathtub-light::before { content: "\eeae"; }
  &-battery-charging-light::before { content: "\eeaf"; }
  &-battery-charging-vertical-light::before { content: "\eeb0"; }
  &-battery-empty-light::before { content: "\eeb1"; }
  &-battery-full-light::before { content: "\eeb2"; }
  &-battery-high-light::before { content: "\eeb3"; }
  &-battery-low-light::before { content: "\eeb4"; }
  &-battery-medium-light::before { content: "\eeb5"; }
  &-battery-plus-light::before { content: "\eeb6"; }
  &-battery-warning-light::before { content: "\eeb7"; }
  &-battery-warning-vertical-light::before { content: "\eeb8"; }
  &-bed-light::before { content: "\eeb9"; }
  &-beer-bottle-light::before { content: "\eeba"; }
  &-behance-logo-light::before { content: "\eebb"; }
  &-bell-light::before { content: "\eebc"; }
  &-bell-ringing-light::before { content: "\eebd"; }
  &-bell-simple-light::before { content: "\eebe"; }
  &-bell-simple-ringing-light::before { content: "\eebf"; }
  &-bell-simple-slash-light::before { content: "\eec0"; }
  &-bell-simple-z-light::before { content: "\eec1"; }
  &-bell-slash-light::before { content: "\eec2"; }
  &-bell-z-light::before { content: "\eec3"; }
  &-bezier-curve-light::before { content: "\eec4"; }
  &-bicycle-light::before { content: "\eec5"; }
  &-binoculars-light::before { content: "\eec6"; }
  &-bird-light::before { content: "\eec7"; }
  &-bluetooth-connected-light::before { content: "\eec8"; }
  &-bluetooth-light::before { content: "\eec9"; }
  &-bluetooth-slash-light::before { content: "\eeca"; }
  &-bluetooth-x-light::before { content: "\eecb"; }
  &-boat-light::before { content: "\eecc"; }
  &-book-bookmark-light::before { content: "\eecd"; }
  &-book-light::before { content: "\eece"; }
  &-book-open-light::before { content: "\eecf"; }
  &-bookmark-light::before { content: "\eed0"; }
  &-bookmark-simple-light::before { content: "\eed1"; }
  &-bookmarks-light::before { content: "\eed2"; }
  &-bookmarks-simple-light::before { content: "\eed3"; }
  &-books-light::before { content: "\eed4"; }
  &-bounding-box-light::before { content: "\eed5"; }
  &-brackets-angle-light::before { content: "\eed6"; }
  &-brackets-curly-light::before { content: "\eed7"; }
  &-brackets-round-light::before { content: "\eed8"; }
  &-brackets-square-light::before { content: "\eed9"; }
  &-brain-light::before { content: "\eeda"; }
  &-brandy-light::before { content: "\eedb"; }
  &-briefcase-light::before { content: "\eedc"; }
  &-briefcase-metal-light::before { content: "\eedd"; }
  &-broadcast-light::before { content: "\eede"; }
  &-browser-light::before { content: "\eedf"; }
  &-browsers-light::before { content: "\eee0"; }
  &-bug-beetle-light::before { content: "\eee1"; }
  &-bug-droid-light::before { content: "\eee2"; }
  &-bug-light::before { content: "\eee3"; }
  &-buildings-light::before { content: "\eee4"; }
  &-bus-light::before { content: "\eee5"; }
  &-butterfly-light::before { content: "\eee6"; }
  &-cactus-light::before { content: "\eee7"; }
  &-cake-light::before { content: "\eee8"; }
  &-calculator-light::before { content: "\eee9"; }
  &-calendar-blank-light::before { content: "\eeea"; }
  &-calendar-check-light::before { content: "\eeeb"; }
  &-calendar-light::before { content: "\eeec"; }
  &-calendar-plus-light::before { content: "\eeed"; }
  &-calendar-x-light::before { content: "\eeee"; }
  &-camera-light::before { content: "\eeef"; }
  &-camera-rotate-light::before { content: "\eef0"; }
  &-camera-slash-light::before { content: "\eef1"; }
  &-campfire-light::before { content: "\eef2"; }
  &-car-light::before { content: "\eef3"; }
  &-car-simple-light::before { content: "\eef4"; }
  &-cardholder-light::before { content: "\eef5"; }
  &-cards-light::before { content: "\eef6"; }
  &-caret-circle-double-down-light::before { content: "\eef7"; }
  &-caret-circle-double-left-light::before { content: "\eef8"; }
  &-caret-circle-double-right-light::before { content: "\eef9"; }
  &-caret-circle-double-up-light::before { content: "\eefa"; }
  &-caret-circle-down-light::before { content: "\eefb"; }
  &-caret-circle-left-light::before { content: "\eefc"; }
  &-caret-circle-right-light::before { content: "\eefd"; }
  &-caret-circle-up-light::before { content: "\eefe"; }
  &-caret-double-down-light::before { content: "\eeff"; }
  &-caret-double-left-light::before { content: "\ef00"; }
  &-caret-double-right-light::before { content: "\ef01"; }
  &-caret-double-up-light::before { content: "\ef02"; }
  &-caret-down-light::before { content: "\ef03"; }
  &-caret-left-light::before { content: "\ef04"; }
  &-caret-right-light::before { content: "\ef05"; }
  &-caret-up-light::before { content: "\ef06"; }
  &-cat-light::before { content: "\ef07"; }
  &-cell-signal-full-light::before { content: "\ef08"; }
  &-cell-signal-high-light::before { content: "\ef09"; }
  &-cell-signal-low-light::before { content: "\ef0a"; }
  &-cell-signal-medium-light::before { content: "\ef0b"; }
  &-cell-signal-none-light::before { content: "\ef0c"; }
  &-cell-signal-slash-light::before { content: "\ef0d"; }
  &-cell-signal-x-light::before { content: "\ef0e"; }
  &-chalkboard-light::before { content: "\ef0f"; }
  &-chalkboard-simple-light::before { content: "\ef10"; }
  &-chalkboard-teacher-light::before { content: "\ef11"; }
  &-chart-bar-horizontal-light::before { content: "\ef12"; }
  &-chart-bar-light::before { content: "\ef13"; }
  &-chart-line-light::before { content: "\ef14"; }
  &-chart-line-up-light::before { content: "\ef15"; }
  &-chart-pie-light::before { content: "\ef16"; }
  &-chart-pie-slice-light::before { content: "\ef17"; }
  &-chat-centered-dots-light::before { content: "\ef18"; }
  &-chat-centered-light::before { content: "\ef19"; }
  &-chat-centered-text-light::before { content: "\ef1a"; }
  &-chat-circle-dots-light::before { content: "\ef1b"; }
  &-chat-circle-light::before { content: "\ef1c"; }
  &-chat-circle-text-light::before { content: "\ef1d"; }
  &-chat-dots-light::before { content: "\ef1e"; }
  &-chat-light::before { content: "\ef1f"; }
  &-chat-teardrop-dots-light::before { content: "\ef20"; }
  &-chat-teardrop-light::before { content: "\ef21"; }
  &-chat-teardrop-text-light::before { content: "\ef22"; }
  &-chat-text-light::before { content: "\ef23"; }
  &-chats-circle-light::before { content: "\ef24"; }
  &-chats-light::before { content: "\ef25"; }
  &-chats-teardrop-light::before { content: "\ef26"; }
  &-check-circle-light::before { content: "\ef27"; }
  &-check-light::before { content: "\ef28"; }
  &-check-square-light::before { content: "\ef29"; }
  &-check-square-offset-light::before { content: "\ef2a"; }
  &-checks-light::before { content: "\ef2b"; }
  &-circle-dashed-light::before { content: "\ef2c"; }
  &-circle-half-light::before { content: "\ef2d"; }
  &-circle-half-tilt-light::before { content: "\ef2e"; }
  &-circle-light::before { content: "\ef2f"; }
  &-circle-notch-light::before { content: "\ef30"; }
  &-circle-wavy-check-light::before { content: "\ef31"; }
  &-circle-wavy-light::before { content: "\ef32"; }
  &-circle-wavy-question-light::before { content: "\ef33"; }
  &-circle-wavy-warning-light::before { content: "\ef34"; }
  &-circles-four-light::before { content: "\ef35"; }
  &-circles-three-light::before { content: "\ef36"; }
  &-circles-three-plus-light::before { content: "\ef37"; }
  &-clipboard-light::before { content: "\ef38"; }
  &-clipboard-text-light::before { content: "\ef39"; }
  &-clock-afternoon-light::before { content: "\ef3a"; }
  &-clock-clockwise-light::before { content: "\ef3b"; }
  &-clock-counter-clockwise-light::before { content: "\ef3c"; }
  &-clock-light::before { content: "\ef3d"; }
  &-closed-captioning-light::before { content: "\ef3e"; }
  &-cloud-arrow-down-light::before { content: "\ef3f"; }
  &-cloud-arrow-up-light::before { content: "\ef40"; }
  &-cloud-check-light::before { content: "\ef41"; }
  &-cloud-fog-light::before { content: "\ef42"; }
  &-cloud-light::before { content: "\ef43"; }
  &-cloud-lightning-light::before { content: "\ef44"; }
  &-cloud-moon-light::before { content: "\ef45"; }
  &-cloud-rain-light::before { content: "\ef46"; }
  &-cloud-slash-light::before { content: "\ef47"; }
  &-cloud-snow-light::before { content: "\ef48"; }
  &-cloud-sun-light::before { content: "\ef49"; }
  &-club-light::before { content: "\ef4a"; }
  &-coat-hanger-light::before { content: "\ef4b"; }
  &-code-light::before { content: "\ef4c"; }
  &-code-simple-light::before { content: "\ef4d"; }
  &-codepen-logo-light::before { content: "\ef4e"; }
  &-codesandbox-logo-light::before { content: "\ef4f"; }
  &-coffee-light::before { content: "\ef50"; }
  &-coin-light::before { content: "\ef51"; }
  &-coin-vertical-light::before { content: "\ef52"; }
  &-coins-light::before { content: "\ef53"; }
  &-columns-light::before { content: "\ef54"; }
  &-command-light::before { content: "\ef55"; }
  &-compass-light::before { content: "\ef56"; }
  &-computer-tower-light::before { content: "\ef57"; }
  &-confetti-light::before { content: "\ef58"; }
  &-cookie-light::before { content: "\ef59"; }
  &-cooking-pot-light::before { content: "\ef5a"; }
  &-copy-light::before { content: "\ef5b"; }
  &-copy-simple-light::before { content: "\ef5c"; }
  &-copyleft-light::before { content: "\ef5d"; }
  &-copyright-light::before { content: "\ef5e"; }
  &-corners-in-light::before { content: "\ef5f"; }
  &-corners-out-light::before { content: "\ef60"; }
  &-cpu-light::before { content: "\ef61"; }
  &-credit-card-light::before { content: "\ef62"; }
  &-crop-light::before { content: "\ef63"; }
  &-crosshair-light::before { content: "\ef64"; }
  &-crosshair-simple-light::before { content: "\ef65"; }
  &-crown-light::before { content: "\ef66"; }
  &-crown-simple-light::before { content: "\ef67"; }
  &-cube-light::before { content: "\ef68"; }
  &-currency-btc-light::before { content: "\ef69"; }
  &-currency-circle-dollar-light::before { content: "\ef6a"; }
  &-currency-cny-light::before { content: "\ef6b"; }
  &-currency-dollar-light::before { content: "\ef6c"; }
  &-currency-dollar-simple-light::before { content: "\ef6d"; }
  &-currency-eth-light::before { content: "\ef6e"; }
  &-currency-eur-light::before { content: "\ef6f"; }
  &-currency-gbp-light::before { content: "\ef70"; }
  &-currency-inr-light::before { content: "\ef71"; }
  &-currency-jpy-light::before { content: "\ef72"; }
  &-currency-krw-light::before { content: "\ef73"; }
  &-currency-kzt-light::before { content: "\ef74"; }
  &-currency-ngn-light::before { content: "\ef75"; }
  &-currency-rub-light::before { content: "\ef76"; }
  &-cursor-light::before { content: "\ef77"; }
  &-cursor-text-light::before { content: "\ef78"; }
  &-cylinder-light::before { content: "\ef79"; }
  &-database-light::before { content: "\ef7a"; }
  &-desktop-light::before { content: "\ef7b"; }
  &-desktop-tower-light::before { content: "\ef7c"; }
  &-detective-light::before { content: "\ef7d"; }
  &-device-mobile-camera-light::before { content: "\ef7e"; }
  &-device-mobile-light::before { content: "\ef7f"; }
  &-device-mobile-speaker-light::before { content: "\ef80"; }
  &-device-tablet-camera-light::before { content: "\ef81"; }
  &-device-tablet-light::before { content: "\ef82"; }
  &-device-tablet-speaker-light::before { content: "\ef83"; }
  &-diamond-light::before { content: "\ef84"; }
  &-diamonds-four-light::before { content: "\ef85"; }
  &-dice-five-light::before { content: "\ef86"; }
  &-dice-four-light::before { content: "\ef87"; }
  &-dice-one-light::before { content: "\ef88"; }
  &-dice-six-light::before { content: "\ef89"; }
  &-dice-three-light::before { content: "\ef8a"; }
  &-dice-two-light::before { content: "\ef8b"; }
  &-disc-light::before { content: "\ef8c"; }
  &-discord-logo-light::before { content: "\ef8d"; }
  &-divide-light::before { content: "\ef8e"; }
  &-dog-light::before { content: "\ef8f"; }
  &-door-light::before { content: "\ef90"; }
  &-dots-nine-light::before { content: "\ef91"; }
  &-dots-six-light::before { content: "\ef92"; }
  &-dots-six-vertical-light::before { content: "\ef93"; }
  &-dots-three-circle-light::before { content: "\ef94"; }
  &-dots-three-circle-vertical-light::before { content: "\ef95"; }
  &-dots-three-light::before { content: "\ef96"; }
  &-dots-three-outline-light::before { content: "\ef97"; }
  &-dots-three-outline-vertical-light::before { content: "\ef98"; }
  &-dots-three-vertical-light::before { content: "\ef99"; }
  &-download-light::before { content: "\ef9a"; }
  &-download-simple-light::before { content: "\ef9b"; }
  &-dribbble-logo-light::before { content: "\ef9c"; }
  &-drop-half-bottom-light::before { content: "\ef9d"; }
  &-drop-half-light::before { content: "\ef9e"; }
  &-drop-light::before { content: "\ef9f"; }
  &-ear-light::before { content: "\efa0"; }
  &-ear-slash-light::before { content: "\efa1"; }
  &-egg-crack-light::before { content: "\efa2"; }
  &-egg-light::before { content: "\efa3"; }
  &-eject-light::before { content: "\efa4"; }
  &-eject-simple-light::before { content: "\efa5"; }
  &-envelope-light::before { content: "\efa6"; }
  &-envelope-open-light::before { content: "\efa7"; }
  &-envelope-simple-light::before { content: "\efa8"; }
  &-envelope-simple-open-light::before { content: "\efa9"; }
  &-equalizer-light::before { content: "\efaa"; }
  &-equals-light::before { content: "\efab"; }
  &-eraser-light::before { content: "\efac"; }
  &-exam-light::before { content: "\efad"; }
  &-export-light::before { content: "\efae"; }
  &-eye-closed-light::before { content: "\efaf"; }
  &-eye-light::before { content: "\efb0"; }
  &-eye-slash-light::before { content: "\efb1"; }
  &-eyedropper-light::before { content: "\efb2"; }
  &-eyedropper-sample-light::before { content: "\efb3"; }
  &-eyeglasses-light::before { content: "\efb4"; }
  &-face-mask-light::before { content: "\efb5"; }
  &-facebook-logo-light::before { content: "\efb6"; }
  &-factory-light::before { content: "\efb7"; }
  &-faders-horizontal-light::before { content: "\efb8"; }
  &-faders-light::before { content: "\efb9"; }
  &-fast-forward-circle-light::before { content: "\efba"; }
  &-fast-forward-light::before { content: "\efbb"; }
  &-figma-logo-light::before { content: "\efbc"; }
  &-file-arrow-down-light::before { content: "\efbd"; }
  &-file-arrow-up-light::before { content: "\efbe"; }
  &-file-audio-light::before { content: "\efbf"; }
  &-file-cloud-light::before { content: "\efc0"; }
  &-file-code-light::before { content: "\efc1"; }
  &-file-css-light::before { content: "\efc2"; }
  &-file-csv-light::before { content: "\efc3"; }
  &-file-doc-light::before { content: "\efc4"; }
  &-file-dotted-light::before { content: "\efc5"; }
  &-file-html-light::before { content: "\efc6"; }
  &-file-image-light::before { content: "\efc7"; }
  &-file-jpg-light::before { content: "\efc8"; }
  &-file-js-light::before { content: "\efc9"; }
  &-file-jsx-light::before { content: "\efca"; }
  &-file-light::before { content: "\efcb"; }
  &-file-lock-light::before { content: "\efcc"; }
  &-file-minus-light::before { content: "\efcd"; }
  &-file-pdf-light::before { content: "\efce"; }
  &-file-plus-light::before { content: "\efcf"; }
  &-file-png-light::before { content: "\efd0"; }
  &-file-ppt-light::before { content: "\efd1"; }
  &-file-rs-light::before { content: "\efd2"; }
  &-file-search-light::before { content: "\efd3"; }
  &-file-text-light::before { content: "\efd4"; }
  &-file-ts-light::before { content: "\efd5"; }
  &-file-tsx-light::before { content: "\efd6"; }
  &-file-video-light::before { content: "\efd7"; }
  &-file-vue-light::before { content: "\efd8"; }
  &-file-x-light::before { content: "\efd9"; }
  &-file-xls-light::before { content: "\efda"; }
  &-file-zip-light::before { content: "\efdb"; }
  &-files-light::before { content: "\efdc"; }
  &-film-script-light::before { content: "\efdd"; }
  &-film-slate-light::before { content: "\efde"; }
  &-film-strip-light::before { content: "\efdf"; }
  &-fingerprint-light::before { content: "\efe0"; }
  &-fingerprint-simple-light::before { content: "\efe1"; }
  &-finn-the-human-light::before { content: "\efe2"; }
  &-fire-light::before { content: "\efe3"; }
  &-fire-simple-light::before { content: "\efe4"; }
  &-first-aid-kit-light::before { content: "\efe5"; }
  &-first-aid-light::before { content: "\efe6"; }
  &-fish-light::before { content: "\efe7"; }
  &-fish-simple-light::before { content: "\efe8"; }
  &-flag-banner-light::before { content: "\efe9"; }
  &-flag-checkered-light::before { content: "\efea"; }
  &-flag-light::before { content: "\efeb"; }
  &-flame-light::before { content: "\efec"; }
  &-flashlight-light::before { content: "\efed"; }
  &-flask-light::before { content: "\efee"; }
  &-floppy-disk-back-light::before { content: "\efef"; }
  &-floppy-disk-light::before { content: "\eff0"; }
  &-flow-arrow-light::before { content: "\eff1"; }
  &-flower-light::before { content: "\eff2"; }
  &-flower-lotus-light::before { content: "\eff3"; }
  &-flying-saucer-light::before { content: "\eff4"; }
  &-folder-dotted-light::before { content: "\eff5"; }
  &-folder-light::before { content: "\eff6"; }
  &-folder-lock-light::before { content: "\eff7"; }
  &-folder-minus-light::before { content: "\eff8"; }
  &-folder-notch-light::before { content: "\eff9"; }
  &-folder-notch-minus-light::before { content: "\effa"; }
  &-folder-notch-open-light::before { content: "\effb"; }
  &-folder-notch-plus-light::before { content: "\effc"; }
  &-folder-open-light::before { content: "\effd"; }
  &-folder-plus-light::before { content: "\effe"; }
  &-folder-simple-dotted-light::before { content: "\efff"; }
  &-folder-simple-light::before { content: "\f000"; }
  &-folder-simple-lock-light::before { content: "\f001"; }
  &-folder-simple-minus-light::before { content: "\f002"; }
  &-folder-simple-plus-light::before { content: "\f003"; }
  &-folder-simple-star-light::before { content: "\f004"; }
  &-folder-simple-user-light::before { content: "\f005"; }
  &-folder-star-light::before { content: "\f006"; }
  &-folder-user-light::before { content: "\f007"; }
  &-folders-light::before { content: "\f008"; }
  &-football-light::before { content: "\f009"; }
  &-fork-knife-light::before { content: "\f00a"; }
  &-frame-corners-light::before { content: "\f00b"; }
  &-framer-logo-light::before { content: "\f00c"; }
  &-function-light::before { content: "\f00d"; }
  &-funnel-light::before { content: "\f00e"; }
  &-funnel-simple-light::before { content: "\f00f"; }
  &-game-controller-light::before { content: "\f010"; }
  &-gas-pump-light::before { content: "\f011"; }
  &-gauge-light::before { content: "\f012"; }
  &-gear-light::before { content: "\f013"; }
  &-gear-six-light::before { content: "\f014"; }
  &-gender-female-light::before { content: "\f015"; }
  &-gender-intersex-light::before { content: "\f016"; }
  &-gender-male-light::before { content: "\f017"; }
  &-gender-neuter-light::before { content: "\f018"; }
  &-gender-nonbinary-light::before { content: "\f019"; }
  &-gender-transgender-light::before { content: "\f01a"; }
  &-ghost-light::before { content: "\f01b"; }
  &-gif-light::before { content: "\f01c"; }
  &-gift-light::before { content: "\f01d"; }
  &-git-branch-light::before { content: "\f01e"; }
  &-git-commit-light::before { content: "\f01f"; }
  &-git-diff-light::before { content: "\f020"; }
  &-git-fork-light::before { content: "\f021"; }
  &-git-merge-light::before { content: "\f022"; }
  &-git-pull-request-light::before { content: "\f023"; }
  &-github-logo-light::before { content: "\f024"; }
  &-gitlab-logo-light::before { content: "\f025"; }
  &-gitlab-logo-simple-light::before { content: "\f026"; }
  &-globe-hemisphere-east-light::before { content: "\f027"; }
  &-globe-hemisphere-west-light::before { content: "\f028"; }
  &-globe-light::before { content: "\f029"; }
  &-globe-simple-light::before { content: "\f02a"; }
  &-globe-stand-light::before { content: "\f02b"; }
  &-google-chrome-logo-light::before { content: "\f02c"; }
  &-google-logo-light::before { content: "\f02d"; }
  &-google-photos-logo-light::before { content: "\f02e"; }
  &-google-play-logo-light::before { content: "\f02f"; }
  &-google-podcasts-logo-light::before { content: "\f030"; }
  &-gradient-light::before { content: "\f031"; }
  &-graduation-cap-light::before { content: "\f032"; }
  &-graph-light::before { content: "\f033"; }
  &-grid-four-light::before { content: "\f034"; }
  &-hamburger-light::before { content: "\f035"; }
  &-hand-eye-light::before { content: "\f036"; }
  &-hand-fist-light::before { content: "\f037"; }
  &-hand-grabbing-light::before { content: "\f038"; }
  &-hand-light::before { content: "\f039"; }
  &-hand-palm-light::before { content: "\f03a"; }
  &-hand-pointing-light::before { content: "\f03b"; }
  &-hand-soap-light::before { content: "\f03c"; }
  &-hand-waving-light::before { content: "\f03d"; }
  &-handbag-light::before { content: "\f03e"; }
  &-handbag-simple-light::before { content: "\f03f"; }
  &-hands-clapping-light::before { content: "\f040"; }
  &-handshake-light::before { content: "\f041"; }
  &-hard-drive-light::before { content: "\f042"; }
  &-hard-drives-light::before { content: "\f043"; }
  &-hash-light::before { content: "\f044"; }
  &-hash-straight-light::before { content: "\f045"; }
  &-headlights-light::before { content: "\f046"; }
  &-headphones-light::before { content: "\f047"; }
  &-headset-light::before { content: "\f048"; }
  &-heart-break-light::before { content: "\f049"; }
  &-heart-light::before { content: "\f04a"; }
  &-heart-straight-break-light::before { content: "\f04b"; }
  &-heart-straight-light::before { content: "\f04c"; }
  &-heartbeat-light::before { content: "\f04d"; }
  &-hexagon-light::before { content: "\f04e"; }
  &-highlighter-circle-light::before { content: "\f04f"; }
  &-horse-light::before { content: "\f050"; }
  &-hourglass-high-light::before { content: "\f051"; }
  &-hourglass-light::before { content: "\f052"; }
  &-hourglass-low-light::before { content: "\f053"; }
  &-hourglass-medium-light::before { content: "\f054"; }
  &-hourglass-simple-high-light::before { content: "\f055"; }
  &-hourglass-simple-light::before { content: "\f056"; }
  &-hourglass-simple-low-light::before { content: "\f057"; }
  &-hourglass-simple-medium-light::before { content: "\f058"; }
  &-house-light::before { content: "\f059"; }
  &-house-line-light::before { content: "\f05a"; }
  &-house-simple-light::before { content: "\f05b"; }
  &-identification-badge-light::before { content: "\f05c"; }
  &-identification-card-light::before { content: "\f05d"; }
  &-image-light::before { content: "\f05e"; }
  &-image-square-light::before { content: "\f05f"; }
  &-infinity-light::before { content: "\f060"; }
  &-info-light::before { content: "\f061"; }
  &-instagram-logo-light::before { content: "\f062"; }
  &-intersect-light::before { content: "\f063"; }
  &-jeep-light::before { content: "\f064"; }
  &-kanban-light::before { content: "\f065"; }
  &-key-light::before { content: "\f066"; }
  &-key-return-light::before { content: "\f067"; }
  &-keyboard-light::before { content: "\f068"; }
  &-keyhole-light::before { content: "\f069"; }
  &-knife-light::before { content: "\f06a"; }
  &-ladder-light::before { content: "\f06b"; }
  &-ladder-simple-light::before { content: "\f06c"; }
  &-lamp-light::before { content: "\f06d"; }
  &-laptop-light::before { content: "\f06e"; }
  &-layout-light::before { content: "\f06f"; }
  &-leaf-light::before { content: "\f070"; }
  &-lifebuoy-light::before { content: "\f071"; }
  &-lightbulb-filament-light::before { content: "\f072"; }
  &-lightbulb-light::before { content: "\f073"; }
  &-lightning-light::before { content: "\f074"; }
  &-lightning-slash-light::before { content: "\f075"; }
  &-line-segment-light::before { content: "\f076"; }
  &-line-segments-light::before { content: "\f077"; }
  &-link-break-light::before { content: "\f078"; }
  &-link-light::before { content: "\f079"; }
  &-link-simple-break-light::before { content: "\f07a"; }
  &-link-simple-horizontal-break-light::before { content: "\f07b"; }
  &-link-simple-horizontal-light::before { content: "\f07c"; }
  &-link-simple-light::before { content: "\f07d"; }
  &-linkedin-logo-light::before { content: "\f07e"; }
  &-linux-logo-light::before { content: "\f07f"; }
  &-list-bullets-light::before { content: "\f080"; }
  &-list-checks-light::before { content: "\f081"; }
  &-list-dashes-light::before { content: "\f082"; }
  &-list-light::before { content: "\f083"; }
  &-list-numbers-light::before { content: "\f084"; }
  &-list-plus-light::before { content: "\f085"; }
  &-lock-key-light::before { content: "\f086"; }
  &-lock-key-open-light::before { content: "\f087"; }
  &-lock-laminated-light::before { content: "\f088"; }
  &-lock-laminated-open-light::before { content: "\f089"; }
  &-lock-light::before { content: "\f08a"; }
  &-lock-open-light::before { content: "\f08b"; }
  &-lock-simple-light::before { content: "\f08c"; }
  &-lock-simple-open-light::before { content: "\f08d"; }
  &-magic-wand-light::before { content: "\f08e"; }
  &-magnet-light::before { content: "\f08f"; }
  &-magnet-straight-light::before { content: "\f090"; }
  &-magnifying-glass-light::before { content: "\f091"; }
  &-magnifying-glass-minus-light::before { content: "\f092"; }
  &-magnifying-glass-plus-light::before { content: "\f093"; }
  &-map-pin-light::before { content: "\f094"; }
  &-map-pin-line-light::before { content: "\f095"; }
  &-map-trifold-light::before { content: "\f096"; }
  &-marker-circle-light::before { content: "\f097"; }
  &-martini-light::before { content: "\f098"; }
  &-mask-happy-light::before { content: "\f099"; }
  &-mask-sad-light::before { content: "\f09a"; }
  &-math-operations-light::before { content: "\f09b"; }
  &-medal-light::before { content: "\f09c"; }
  &-medium-logo-light::before { content: "\f09d"; }
  &-megaphone-light::before { content: "\f09e"; }
  &-megaphone-simple-light::before { content: "\f09f"; }
  &-messenger-logo-light::before { content: "\f0a0"; }
  &-microphone-light::before { content: "\f0a1"; }
  &-microphone-slash-light::before { content: "\f0a2"; }
  &-microphone-stage-light::before { content: "\f0a3"; }
  &-microsoft-excel-logo-light::before { content: "\f0a4"; }
  &-microsoft-powerpoint-logo-light::before { content: "\f0a5"; }
  &-microsoft-teams-logo-light::before { content: "\f0a6"; }
  &-microsoft-word-logo-light::before { content: "\f0a7"; }
  &-minus-circle-light::before { content: "\f0a8"; }
  &-minus-light::before { content: "\f0a9"; }
  &-money-light::before { content: "\f0aa"; }
  &-monitor-light::before { content: "\f0ab"; }
  &-monitor-play-light::before { content: "\f0ac"; }
  &-moon-light::before { content: "\f0ad"; }
  &-moon-stars-light::before { content: "\f0ae"; }
  &-mountains-light::before { content: "\f0af"; }
  &-mouse-light::before { content: "\f0b0"; }
  &-mouse-simple-light::before { content: "\f0b1"; }
  &-music-note-light::before { content: "\f0b2"; }
  &-music-note-simple-light::before { content: "\f0b3"; }
  &-music-notes-light::before { content: "\f0b4"; }
  &-music-notes-plus-light::before { content: "\f0b5"; }
  &-music-notes-simple-light::before { content: "\f0b6"; }
  &-navigation-arrow-light::before { content: "\f0b7"; }
  &-needle-light::before { content: "\f0b8"; }
  &-newspaper-clipping-light::before { content: "\f0b9"; }
  &-newspaper-light::before { content: "\f0ba"; }
  &-note-blank-light::before { content: "\f0bb"; }
  &-note-light::before { content: "\f0bc"; }
  &-note-pencil-light::before { content: "\f0bd"; }
  &-notebook-light::before { content: "\f0be"; }
  &-notepad-light::before { content: "\f0bf"; }
  &-notification-light::before { content: "\f0c0"; }
  &-number-circle-eight-light::before { content: "\f0c1"; }
  &-number-circle-five-light::before { content: "\f0c2"; }
  &-number-circle-four-light::before { content: "\f0c3"; }
  &-number-circle-nine-light::before { content: "\f0c4"; }
  &-number-circle-one-light::before { content: "\f0c5"; }
  &-number-circle-seven-light::before { content: "\f0c6"; }
  &-number-circle-six-light::before { content: "\f0c7"; }
  &-number-circle-three-light::before { content: "\f0c8"; }
  &-number-circle-two-light::before { content: "\f0c9"; }
  &-number-circle-zero-light::before { content: "\f0ca"; }
  &-number-eight-light::before { content: "\f0cb"; }
  &-number-five-light::before { content: "\f0cc"; }
  &-number-four-light::before { content: "\f0cd"; }
  &-number-nine-light::before { content: "\f0ce"; }
  &-number-one-light::before { content: "\f0cf"; }
  &-number-seven-light::before { content: "\f0d0"; }
  &-number-six-light::before { content: "\f0d1"; }
  &-number-square-eight-light::before { content: "\f0d2"; }
  &-number-square-five-light::before { content: "\f0d3"; }
  &-number-square-four-light::before { content: "\f0d4"; }
  &-number-square-nine-light::before { content: "\f0d5"; }
  &-number-square-one-light::before { content: "\f0d6"; }
  &-number-square-seven-light::before { content: "\f0d7"; }
  &-number-square-six-light::before { content: "\f0d8"; }
  &-number-square-three-light::before { content: "\f0d9"; }
  &-number-square-two-light::before { content: "\f0da"; }
  &-number-square-zero-light::before { content: "\f0db"; }
  &-number-three-light::before { content: "\f0dc"; }
  &-number-two-light::before { content: "\f0dd"; }
  &-number-zero-light::before { content: "\f0de"; }
  &-nut-light::before { content: "\f0df"; }
  &-ny-times-logo-light::before { content: "\f0e0"; }
  &-octagon-light::before { content: "\f0e1"; }
  &-option-light::before { content: "\f0e2"; }
  &-package-light::before { content: "\f0e3"; }
  &-paint-brush-broad-light::before { content: "\f0e4"; }
  &-paint-brush-household-light::before { content: "\f0e5"; }
  &-paint-brush-light::before { content: "\f0e6"; }
  &-paint-bucket-light::before { content: "\f0e7"; }
  &-paint-roller-light::before { content: "\f0e8"; }
  &-palette-light::before { content: "\f0e9"; }
  &-paper-plane-light::before { content: "\f0ea"; }
  &-paper-plane-right-light::before { content: "\f0eb"; }
  &-paper-plane-tilt-light::before { content: "\f0ec"; }
  &-paperclip-horizontal-light::before { content: "\f0ed"; }
  &-paperclip-light::before { content: "\f0ee"; }
  &-parachute-light::before { content: "\f0ef"; }
  &-password-light::before { content: "\f0f0"; }
  &-path-light::before { content: "\f0f1"; }
  &-pause-circle-light::before { content: "\f0f2"; }
  &-pause-light::before { content: "\f0f3"; }
  &-paw-print-light::before { content: "\f0f4"; }
  &-peace-light::before { content: "\f0f5"; }
  &-pen-light::before { content: "\f0f6"; }
  &-pen-nib-light::before { content: "\f0f7"; }
  &-pen-nib-straight-light::before { content: "\f0f8"; }
  &-pencil-circle-light::before { content: "\f0f9"; }
  &-pencil-light::before { content: "\f0fa"; }
  &-pencil-line-light::before { content: "\f0fb"; }
  &-pencil-simple-light::before { content: "\f0fc"; }
  &-pencil-simple-line-light::before { content: "\f0fd"; }
  &-percent-light::before { content: "\f0fe"; }
  &-person-light::before { content: "\f0ff"; }
  &-person-simple-light::before { content: "\f100"; }
  &-person-simple-run-light::before { content: "\f101"; }
  &-person-simple-walk-light::before { content: "\f102"; }
  &-perspective-light::before { content: "\f103"; }
  &-phone-call-light::before { content: "\f104"; }
  &-phone-disconnect-light::before { content: "\f105"; }
  &-phone-incoming-light::before { content: "\f106"; }
  &-phone-light::before { content: "\f107"; }
  &-phone-outgoing-light::before { content: "\f108"; }
  &-phone-slash-light::before { content: "\f109"; }
  &-phone-x-light::before { content: "\f10a"; }
  &-phosphor-logo-light::before { content: "\f10b"; }
  &-piano-keys-light::before { content: "\f10c"; }
  &-picture-in-picture-light::before { content: "\f10d"; }
  &-pill-light::before { content: "\f10e"; }
  &-pinterest-logo-light::before { content: "\f10f"; }
  &-pinwheel-light::before { content: "\f110"; }
  &-pizza-light::before { content: "\f111"; }
  &-placeholder-light::before { content: "\f112"; }
  &-planet-light::before { content: "\f113"; }
  &-play-circle-light::before { content: "\f114"; }
  &-play-light::before { content: "\f115"; }
  &-playlist-light::before { content: "\f116"; }
  &-plug-light::before { content: "\f117"; }
  &-plugs-connected-light::before { content: "\f118"; }
  &-plugs-light::before { content: "\f119"; }
  &-plus-circle-light::before { content: "\f11a"; }
  &-plus-light::before { content: "\f11b"; }
  &-plus-minus-light::before { content: "\f11c"; }
  &-poker-chip-light::before { content: "\f11d"; }
  &-police-car-light::before { content: "\f11e"; }
  &-polygon-light::before { content: "\f11f"; }
  &-popcorn-light::before { content: "\f120"; }
  &-power-light::before { content: "\f121"; }
  &-prescription-light::before { content: "\f122"; }
  &-presentation-chart-light::before { content: "\f123"; }
  &-presentation-light::before { content: "\f124"; }
  &-printer-light::before { content: "\f125"; }
  &-prohibit-inset-light::before { content: "\f126"; }
  &-prohibit-light::before { content: "\f127"; }
  &-projector-screen-chart-light::before { content: "\f128"; }
  &-projector-screen-light::before { content: "\f129"; }
  &-push-pin-light::before { content: "\f12a"; }
  &-push-pin-simple-light::before { content: "\f12b"; }
  &-push-pin-simple-slash-light::before { content: "\f12c"; }
  &-push-pin-slash-light::before { content: "\f12d"; }
  &-puzzle-piece-light::before { content: "\f12e"; }
  &-qr-code-light::before { content: "\f12f"; }
  &-question-light::before { content: "\f130"; }
  &-queue-light::before { content: "\f131"; }
  &-quotes-light::before { content: "\f132"; }
  &-radical-light::before { content: "\f133"; }
  &-radio-button-light::before { content: "\f134"; }
  &-radio-light::before { content: "\f135"; }
  &-rainbow-cloud-light::before { content: "\f136"; }
  &-rainbow-light::before { content: "\f137"; }
  &-receipt-light::before { content: "\f138"; }
  &-record-light::before { content: "\f139"; }
  &-rectangle-light::before { content: "\f13a"; }
  &-recycle-light::before { content: "\f13b"; }
  &-reddit-logo-light::before { content: "\f13c"; }
  &-repeat-light::before { content: "\f13d"; }
  &-repeat-once-light::before { content: "\f13e"; }
  &-rewind-circle-light::before { content: "\f13f"; }
  &-rewind-light::before { content: "\f140"; }
  &-robot-light::before { content: "\f141"; }
  &-rocket-launch-light::before { content: "\f142"; }
  &-rocket-light::before { content: "\f143"; }
  &-rows-light::before { content: "\f144"; }
  &-rss-light::before { content: "\f145"; }
  &-rss-simple-light::before { content: "\f146"; }
  &-rug-light::before { content: "\f147"; }
  &-ruler-light::before { content: "\f148"; }
  &-scales-light::before { content: "\f149"; }
  &-scan-light::before { content: "\f14a"; }
  &-scissors-light::before { content: "\f14b"; }
  &-screencast-light::before { content: "\f14c"; }
  &-scribble-loop-light::before { content: "\f14d"; }
  &-scroll-light::before { content: "\f14e"; }
  &-selection-all-light::before { content: "\f14f"; }
  &-selection-background-light::before { content: "\f150"; }
  &-selection-foreground-light::before { content: "\f151"; }
  &-selection-inverse-light::before { content: "\f152"; }
  &-selection-light::before { content: "\f153"; }
  &-selection-plus-light::before { content: "\f154"; }
  &-selection-slash-light::before { content: "\f155"; }
  &-share-light::before { content: "\f156"; }
  &-share-network-light::before { content: "\f157"; }
  &-shield-check-light::before { content: "\f158"; }
  &-shield-checkered-light::before { content: "\f159"; }
  &-shield-chevron-light::before { content: "\f15a"; }
  &-shield-light::before { content: "\f15b"; }
  &-shield-plus-light::before { content: "\f15c"; }
  &-shield-slash-light::before { content: "\f15d"; }
  &-shield-star-light::before { content: "\f15e"; }
  &-shield-warning-light::before { content: "\f15f"; }
  &-shopping-bag-light::before { content: "\f160"; }
  &-shopping-bag-open-light::before { content: "\f161"; }
  &-shopping-cart-light::before { content: "\f162"; }
  &-shopping-cart-simple-light::before { content: "\f163"; }
  &-shower-light::before { content: "\f164"; }
  &-shuffle-angular-light::before { content: "\f165"; }
  &-shuffle-light::before { content: "\f166"; }
  &-shuffle-simple-light::before { content: "\f167"; }
  &-sidebar-light::before { content: "\f168"; }
  &-sidebar-simple-light::before { content: "\f169"; }
  &-sign-in-light::before { content: "\f16a"; }
  &-sign-out-light::before { content: "\f16b"; }
  &-signpost-light::before { content: "\f16c"; }
  &-sim-card-light::before { content: "\f16d"; }
  &-sketch-logo-light::before { content: "\f16e"; }
  &-skip-back-circle-light::before { content: "\f16f"; }
  &-skip-back-light::before { content: "\f170"; }
  &-skip-forward-circle-light::before { content: "\f171"; }
  &-skip-forward-light::before { content: "\f172"; }
  &-skull-light::before { content: "\f173"; }
  &-slack-logo-light::before { content: "\f174"; }
  &-sliders-horizontal-light::before { content: "\f175"; }
  &-sliders-light::before { content: "\f176"; }
  &-smiley-blank-light::before { content: "\f177"; }
  &-smiley-light::before { content: "\f178"; }
  &-smiley-meh-light::before { content: "\f179"; }
  &-smiley-nervous-light::before { content: "\f17a"; }
  &-smiley-sad-light::before { content: "\f17b"; }
  &-smiley-sticker-light::before { content: "\f17c"; }
  &-smiley-wink-light::before { content: "\f17d"; }
  &-smiley-x-eyes-light::before { content: "\f17e"; }
  &-snapchat-logo-light::before { content: "\f17f"; }
  &-snowflake-light::before { content: "\f180"; }
  &-soccer-ball-light::before { content: "\f181"; }
  &-sort-ascending-light::before { content: "\f182"; }
  &-sort-descending-light::before { content: "\f183"; }
  &-spade-light::before { content: "\f184"; }
  &-sparkle-light::before { content: "\f185"; }
  &-speaker-high-light::before { content: "\f186"; }
  &-speaker-low-light::before { content: "\f187"; }
  &-speaker-none-light::before { content: "\f188"; }
  &-speaker-simple-high-light::before { content: "\f189"; }
  &-speaker-simple-low-light::before { content: "\f18a"; }
  &-speaker-simple-none-light::before { content: "\f18b"; }
  &-speaker-simple-slash-light::before { content: "\f18c"; }
  &-speaker-simple-x-light::before { content: "\f18d"; }
  &-speaker-slash-light::before { content: "\f18e"; }
  &-speaker-x-light::before { content: "\f18f"; }
  &-spinner-gap-light::before { content: "\f190"; }
  &-spinner-light::before { content: "\f191"; }
  &-spiral-light::before { content: "\f192"; }
  &-spotify-logo-light::before { content: "\f193"; }
  &-square-half-bottom-light::before { content: "\f194"; }
  &-square-half-light::before { content: "\f195"; }
  &-square-light::before { content: "\f196"; }
  &-square-logo-light::before { content: "\f197"; }
  &-squares-four-light::before { content: "\f198"; }
  &-stack-light::before { content: "\f199"; }
  &-stack-overflow-logo-light::before { content: "\f19a"; }
  &-stack-simple-light::before { content: "\f19b"; }
  &-stamp-light::before { content: "\f19c"; }
  &-star-four-light::before { content: "\f19d"; }
  &-star-half-light::before { content: "\f19e"; }
  &-star-light::before { content: "\f19f"; }
  &-sticker-light::before { content: "\f1a0"; }
  &-stop-circle-light::before { content: "\f1a1"; }
  &-stop-light::before { content: "\f1a2"; }
  &-storefront-light::before { content: "\f1a3"; }
  &-strategy-light::before { content: "\f1a4"; }
  &-stripe-logo-light::before { content: "\f1a5"; }
  &-student-light::before { content: "\f1a6"; }
  &-suitcase-light::before { content: "\f1a7"; }
  &-suitcase-simple-light::before { content: "\f1a8"; }
  &-sun-dim-light::before { content: "\f1a9"; }
  &-sun-horizon-light::before { content: "\f1aa"; }
  &-sun-light::before { content: "\f1ab"; }
  &-sunglasses-light::before { content: "\f1ac"; }
  &-swap-light::before { content: "\f1ad"; }
  &-swatches-light::before { content: "\f1ae"; }
  &-sword-light::before { content: "\f1af"; }
  &-syringe-light::before { content: "\f1b0"; }
  &-t-shirt-light::before { content: "\f1b1"; }
  &-table-light::before { content: "\f1b2"; }
  &-tabs-light::before { content: "\f1b3"; }
  &-tag-chevron-light::before { content: "\f1b4"; }
  &-tag-light::before { content: "\f1b5"; }
  &-tag-simple-light::before { content: "\f1b6"; }
  &-target-light::before { content: "\f1b7"; }
  &-taxi-light::before { content: "\f1b8"; }
  &-telegram-logo-light::before { content: "\f1b9"; }
  &-television-light::before { content: "\f1ba"; }
  &-television-simple-light::before { content: "\f1bb"; }
  &-tennis-ball-light::before { content: "\f1bc"; }
  &-terminal-light::before { content: "\f1bd"; }
  &-terminal-window-light::before { content: "\f1be"; }
  &-test-tube-light::before { content: "\f1bf"; }
  &-text-aa-light::before { content: "\f1c0"; }
  &-text-align-center-light::before { content: "\f1c1"; }
  &-text-align-justify-light::before { content: "\f1c2"; }
  &-text-align-left-light::before { content: "\f1c3"; }
  &-text-align-right-light::before { content: "\f1c4"; }
  &-text-bolder-light::before { content: "\f1c5"; }
  &-text-h-five-light::before { content: "\f1c6"; }
  &-text-h-four-light::before { content: "\f1c7"; }
  &-text-h-light::before { content: "\f1c8"; }
  &-text-h-one-light::before { content: "\f1c9"; }
  &-text-h-six-light::before { content: "\f1ca"; }
  &-text-h-three-light::before { content: "\f1cb"; }
  &-text-h-two-light::before { content: "\f1cc"; }
  &-text-indent-light::before { content: "\f1cd"; }
  &-text-italic-light::before { content: "\f1ce"; }
  &-text-outdent-light::before { content: "\f1cf"; }
  &-text-strikethrough-light::before { content: "\f1d0"; }
  &-text-t-light::before { content: "\f1d1"; }
  &-text-underline-light::before { content: "\f1d2"; }
  &-textbox-light::before { content: "\f1d3"; }
  &-thermometer-cold-light::before { content: "\f1d4"; }
  &-thermometer-hot-light::before { content: "\f1d5"; }
  &-thermometer-light::before { content: "\f1d6"; }
  &-thermometer-simple-light::before { content: "\f1d7"; }
  &-thumbs-down-light::before { content: "\f1d8"; }
  &-thumbs-up-light::before { content: "\f1d9"; }
  &-ticket-light::before { content: "\f1da"; }
  &-tiktok-logo-light::before { content: "\f1db"; }
  &-timer-light::before { content: "\f1dc"; }
  &-toggle-left-light::before { content: "\f1dd"; }
  &-toggle-right-light::before { content: "\f1de"; }
  &-toilet-light::before { content: "\f1df"; }
  &-toilet-paper-light::before { content: "\f1e0"; }
  &-tote-light::before { content: "\f1e1"; }
  &-tote-simple-light::before { content: "\f1e2"; }
  &-trademark-registered-light::before { content: "\f1e3"; }
  &-traffic-cone-light::before { content: "\f1e4"; }
  &-traffic-sign-light::before { content: "\f1e5"; }
  &-traffic-signal-light::before { content: "\f1e6"; }
  &-train-light::before { content: "\f1e7"; }
  &-train-regional-light::before { content: "\f1e8"; }
  &-train-simple-light::before { content: "\f1e9"; }
  &-translate-light::before { content: "\f1ea"; }
  &-trash-light::before { content: "\f1eb"; }
  &-trash-simple-light::before { content: "\f1ec"; }
  &-tray-light::before { content: "\f1ed"; }
  &-tree-evergreen-light::before { content: "\f1ee"; }
  &-tree-light::before { content: "\f1ef"; }
  &-tree-structure-light::before { content: "\f1f0"; }
  &-trend-down-light::before { content: "\f1f1"; }
  &-trend-up-light::before { content: "\f1f2"; }
  &-triangle-light::before { content: "\f1f3"; }
  &-trophy-light::before { content: "\f1f4"; }
  &-truck-light::before { content: "\f1f5"; }
  &-twitch-logo-light::before { content: "\f1f6"; }
  &-twitter-logo-light::before { content: "\f1f7"; }
  &-umbrella-light::before { content: "\f1f8"; }
  &-umbrella-simple-light::before { content: "\f1f9"; }
  &-upload-light::before { content: "\f1fa"; }
  &-upload-simple-light::before { content: "\f1fb"; }
  &-user-circle-gear-light::before { content: "\f1fc"; }
  &-user-circle-light::before { content: "\f1fd"; }
  &-user-circle-minus-light::before { content: "\f1fe"; }
  &-user-circle-plus-light::before { content: "\f1ff"; }
  &-user-focus-light::before { content: "\f200"; }
  &-user-gear-light::before { content: "\f201"; }
  &-user-light::before { content: "\f202"; }
  &-user-list-light::before { content: "\f203"; }
  &-user-minus-light::before { content: "\f204"; }
  &-user-plus-light::before { content: "\f205"; }
  &-user-rectangle-light::before { content: "\f206"; }
  &-user-square-light::before { content: "\f207"; }
  &-user-switch-light::before { content: "\f208"; }
  &-users-four-light::before { content: "\f209"; }
  &-users-light::before { content: "\f20a"; }
  &-users-three-light::before { content: "\f20b"; }
  &-vault-light::before { content: "\f20c"; }
  &-vibrate-light::before { content: "\f20d"; }
  &-video-camera-light::before { content: "\f20e"; }
  &-video-camera-slash-light::before { content: "\f20f"; }
  &-vignette-light::before { content: "\f210"; }
  &-voicemail-light::before { content: "\f211"; }
  &-volleyball-light::before { content: "\f212"; }
  &-wall-light::before { content: "\f213"; }
  &-wallet-light::before { content: "\f214"; }
  &-warning-circle-light::before { content: "\f215"; }
  &-warning-light::before { content: "\f216"; }
  &-warning-octagon-light::before { content: "\f217"; }
  &-watch-light::before { content: "\f218"; }
  &-wave-sawtooth-light::before { content: "\f219"; }
  &-wave-sine-light::before { content: "\f21a"; }
  &-wave-square-light::before { content: "\f21b"; }
  &-wave-triangle-light::before { content: "\f21c"; }
  &-waves-light::before { content: "\f21d"; }
  &-webcam-light::before { content: "\f21e"; }
  &-whatsapp-logo-light::before { content: "\f21f"; }
  &-wheelchair-light::before { content: "\f220"; }
  &-wifi-high-light::before { content: "\f221"; }
  &-wifi-low-light::before { content: "\f222"; }
  &-wifi-medium-light::before { content: "\f223"; }
  &-wifi-none-light::before { content: "\f224"; }
  &-wifi-slash-light::before { content: "\f225"; }
  &-wifi-x-light::before { content: "\f226"; }
  &-wind-light::before { content: "\f227"; }
  &-windows-logo-light::before { content: "\f228"; }
  &-wine-light::before { content: "\f229"; }
  &-wrench-light::before { content: "\f22a"; }
  &-x-circle-light::before { content: "\f22b"; }
  &-x-light::before { content: "\f22c"; }
  &-x-square-light::before { content: "\f22d"; }
  &-yin-yang-light::before { content: "\f22e"; }
  &-youtube-logo-light::before { content: "\f22f"; }

  &-activity-thin::before { content: "\ea02"; }
  &-address-book-thin::before { content: "\ea03"; }
  &-airplane-in-flight-thin::before { content: "\ea04"; }
  &-airplane-landing-thin::before { content: "\ea05"; }
  &-airplane-takeoff-thin::before { content: "\ea06"; }
  &-airplane-thin::before { content: "\ea07"; }
  &-airplane-tilt-thin::before { content: "\ea08"; }
  &-airplay-thin::before { content: "\ea09"; }
  &-alarm-thin::before { content: "\ea0a"; }
  &-alien-thin::before { content: "\ea0b"; }
  &-align-bottom-simple-thin::before { content: "\ea0c"; }
  &-align-bottom-thin::before { content: "\ea0d"; }
  &-align-center-horizontal-simple-thin::before { content: "\ea0e"; }
  &-align-center-horizontal-thin::before { content: "\ea0f"; }
  &-align-center-vertical-simple-thin::before { content: "\ea10"; }
  &-align-center-vertical-thin::before { content: "\ea11"; }
  &-align-left-simple-thin::before { content: "\ea12"; }
  &-align-left-thin::before { content: "\ea13"; }
  &-align-right-simple-thin::before { content: "\ea14"; }
  &-align-right-thin::before { content: "\ea15"; }
  &-align-top-simple-thin::before { content: "\ea16"; }
  &-align-top-thin::before { content: "\ea17"; }
  &-anchor-simple-thin::before { content: "\ea18"; }
  &-anchor-thin::before { content: "\ea19"; }
  &-android-logo-thin::before { content: "\ea1a"; }
  &-angular-logo-thin::before { content: "\ea1b"; }
  &-aperture-thin::before { content: "\ea1c"; }
  &-app-store-logo-thin::before { content: "\ea1d"; }
  &-app-window-thin::before { content: "\ea1e"; }
  &-apple-logo-thin::before { content: "\ea1f"; }
  &-apple-podcasts-logo-thin::before { content: "\ea20"; }
  &-archive-box-thin::before { content: "\ea21"; }
  &-archive-thin::before { content: "\ea22"; }
  &-archive-tray-thin::before { content: "\ea23"; }
  &-armchair-thin::before { content: "\ea24"; }
  &-arrow-arc-left-thin::before { content: "\ea25"; }
  &-arrow-arc-right-thin::before { content: "\ea26"; }
  &-arrow-bend-double-up-left-thin::before { content: "\ea27"; }
  &-arrow-bend-double-up-right-thin::before { content: "\ea28"; }
  &-arrow-bend-down-left-thin::before { content: "\ea29"; }
  &-arrow-bend-down-right-thin::before { content: "\ea2a"; }
  &-arrow-bend-left-down-thin::before { content: "\ea2b"; }
  &-arrow-bend-left-up-thin::before { content: "\ea2c"; }
  &-arrow-bend-right-down-thin::before { content: "\ea2d"; }
  &-arrow-bend-right-up-thin::before { content: "\ea2e"; }
  &-arrow-bend-up-left-thin::before { content: "\ea2f"; }
  &-arrow-bend-up-right-thin::before { content: "\ea30"; }
  &-arrow-circle-down-left-thin::before { content: "\ea31"; }
  &-arrow-circle-down-right-thin::before { content: "\ea32"; }
  &-arrow-circle-down-thin::before { content: "\ea33"; }
  &-arrow-circle-left-thin::before { content: "\ea34"; }
  &-arrow-circle-right-thin::before { content: "\ea35"; }
  &-arrow-circle-up-left-thin::before { content: "\ea36"; }
  &-arrow-circle-up-right-thin::before { content: "\ea37"; }
  &-arrow-circle-up-thin::before { content: "\ea38"; }
  &-arrow-clockwise-thin::before { content: "\ea39"; }
  &-arrow-counter-clockwise-thin::before { content: "\ea3a"; }
  &-arrow-down-left-thin::before { content: "\ea3b"; }
  &-arrow-down-right-thin::before { content: "\ea3c"; }
  &-arrow-down-thin::before { content: "\ea3d"; }
  &-arrow-elbow-down-left-thin::before { content: "\ea3e"; }
  &-arrow-elbow-down-right-thin::before { content: "\ea3f"; }
  &-arrow-elbow-left-down-thin::before { content: "\ea40"; }
  &-arrow-elbow-left-thin::before { content: "\ea41"; }
  &-arrow-elbow-left-up-thin::before { content: "\ea42"; }
  &-arrow-elbow-right-down-thin::before { content: "\ea43"; }
  &-arrow-elbow-right-thin::before { content: "\ea44"; }
  &-arrow-elbow-right-up-thin::before { content: "\ea45"; }
  &-arrow-elbow-up-left-thin::before { content: "\ea46"; }
  &-arrow-elbow-up-right-thin::before { content: "\ea47"; }
  &-arrow-fat-down-thin::before { content: "\ea48"; }
  &-arrow-fat-left-thin::before { content: "\ea49"; }
  &-arrow-fat-line-down-thin::before { content: "\ea4a"; }
  &-arrow-fat-line-left-thin::before { content: "\ea4b"; }
  &-arrow-fat-line-right-thin::before { content: "\ea4c"; }
  &-arrow-fat-line-up-thin::before { content: "\ea4d"; }
  &-arrow-fat-lines-down-thin::before { content: "\ea4e"; }
  &-arrow-fat-lines-left-thin::before { content: "\ea4f"; }
  &-arrow-fat-lines-right-thin::before { content: "\ea50"; }
  &-arrow-fat-lines-up-thin::before { content: "\ea51"; }
  &-arrow-fat-right-thin::before { content: "\ea52"; }
  &-arrow-fat-up-thin::before { content: "\ea53"; }
  &-arrow-left-thin::before { content: "\ea54"; }
  &-arrow-line-down-left-thin::before { content: "\ea55"; }
  &-arrow-line-down-right-thin::before { content: "\ea56"; }
  &-arrow-line-down-thin::before { content: "\ea57"; }
  &-arrow-line-left-thin::before { content: "\ea58"; }
  &-arrow-line-right-thin::before { content: "\ea59"; }
  &-arrow-line-up-left-thin::before { content: "\ea5a"; }
  &-arrow-line-up-right-thin::before { content: "\ea5b"; }
  &-arrow-line-up-thin::before { content: "\ea5c"; }
  &-arrow-right-thin::before { content: "\ea5d"; }
  &-arrow-square-down-left-thin::before { content: "\ea5e"; }
  &-arrow-square-down-right-thin::before { content: "\ea5f"; }
  &-arrow-square-down-thin::before { content: "\ea60"; }
  &-arrow-square-in-thin::before { content: "\ea61"; }
  &-arrow-square-left-thin::before { content: "\ea62"; }
  &-arrow-square-out-thin::before { content: "\ea63"; }
  &-arrow-square-right-thin::before { content: "\ea64"; }
  &-arrow-square-up-left-thin::before { content: "\ea65"; }
  &-arrow-square-up-right-thin::before { content: "\ea66"; }
  &-arrow-square-up-thin::before { content: "\ea67"; }
  &-arrow-u-down-left-thin::before { content: "\ea68"; }
  &-arrow-u-down-right-thin::before { content: "\ea69"; }
  &-arrow-u-left-down-thin::before { content: "\ea6a"; }
  &-arrow-u-left-up-thin::before { content: "\ea6b"; }
  &-arrow-u-right-down-thin::before { content: "\ea6c"; }
  &-arrow-u-right-up-thin::before { content: "\ea6d"; }
  &-arrow-u-up-left-thin::before { content: "\ea6e"; }
  &-arrow-u-up-right-thin::before { content: "\ea6f"; }
  &-arrow-up-left-thin::before { content: "\ea70"; }
  &-arrow-up-right-thin::before { content: "\ea71"; }
  &-arrow-up-thin::before { content: "\ea72"; }
  &-arrows-clockwise-thin::before { content: "\ea73"; }
  &-arrows-counter-clockwise-thin::before { content: "\ea74"; }
  &-arrows-down-up-thin::before { content: "\ea75"; }
  &-arrows-horizontal-thin::before { content: "\ea76"; }
  &-arrows-in-cardinal-thin::before { content: "\ea77"; }
  &-arrows-in-line-horizontal-thin::before { content: "\ea78"; }
  &-arrows-in-line-vertical-thin::before { content: "\ea79"; }
  &-arrows-in-simple-thin::before { content: "\ea7a"; }
  &-arrows-in-thin::before { content: "\ea7b"; }
  &-arrows-left-right-thin::before { content: "\ea7c"; }
  &-arrows-out-cardinal-thin::before { content: "\ea7d"; }
  &-arrows-out-line-horizontal-thin::before { content: "\ea7e"; }
  &-arrows-out-line-vertical-thin::before { content: "\ea7f"; }
  &-arrows-out-simple-thin::before { content: "\ea80"; }
  &-arrows-out-thin::before { content: "\ea81"; }
  &-arrows-vertical-thin::before { content: "\ea82"; }
  &-article-medium-thin::before { content: "\ea83"; }
  &-article-ny-times-thin::before { content: "\ea84"; }
  &-article-thin::before { content: "\ea85"; }
  &-asterisk-simple-thin::before { content: "\ea86"; }
  &-asterisk-thin::before { content: "\ea87"; }
  &-at-thin::before { content: "\ea88"; }
  &-atom-thin::before { content: "\ea89"; }
  &-baby-thin::before { content: "\ea8a"; }
  &-backpack-thin::before { content: "\ea8b"; }
  &-backspace-thin::before { content: "\ea8c"; }
  &-bag-simple-thin::before { content: "\ea8d"; }
  &-bag-thin::before { content: "\ea8e"; }
  &-balloon-thin::before { content: "\ea8f"; }
  &-bandaids-thin::before { content: "\ea90"; }
  &-bank-thin::before { content: "\ea91"; }
  &-barbell-thin::before { content: "\ea92"; }
  &-barcode-thin::before { content: "\ea93"; }
  &-barricade-thin::before { content: "\ea94"; }
  &-baseball-thin::before { content: "\ea95"; }
  &-basketball-thin::before { content: "\ea96"; }
  &-bathtub-thin::before { content: "\ea97"; }
  &-battery-charging-thin::before { content: "\ea98"; }
  &-battery-charging-vertical-thin::before { content: "\ea99"; }
  &-battery-empty-thin::before { content: "\ea9a"; }
  &-battery-full-thin::before { content: "\ea9b"; }
  &-battery-high-thin::before { content: "\ea9c"; }
  &-battery-low-thin::before { content: "\ea9d"; }
  &-battery-medium-thin::before { content: "\ea9e"; }
  &-battery-plus-thin::before { content: "\ea9f"; }
  &-battery-warning-thin::before { content: "\eaa0"; }
  &-battery-warning-vertical-thin::before { content: "\eaa1"; }
  &-bed-thin::before { content: "\eaa2"; }
  &-beer-bottle-thin::before { content: "\eaa3"; }
  &-behance-logo-thin::before { content: "\eaa4"; }
  &-bell-ringing-thin::before { content: "\eaa5"; }
  &-bell-simple-ringing-thin::before { content: "\eaa6"; }
  &-bell-simple-slash-thin::before { content: "\eaa7"; }
  &-bell-simple-thin::before { content: "\eaa8"; }
  &-bell-simple-z-thin::before { content: "\eaa9"; }
  &-bell-slash-thin::before { content: "\eaaa"; }
  &-bell-thin::before { content: "\eaab"; }
  &-bell-z-thin::before { content: "\eaac"; }
  &-bezier-curve-thin::before { content: "\eaad"; }
  &-bicycle-thin::before { content: "\eaae"; }
  &-binoculars-thin::before { content: "\eaaf"; }
  &-bird-thin::before { content: "\eab0"; }
  &-bluetooth-connected-thin::before { content: "\eab1"; }
  &-bluetooth-slash-thin::before { content: "\eab2"; }
  &-bluetooth-thin::before { content: "\eab3"; }
  &-bluetooth-x-thin::before { content: "\eab4"; }
  &-boat-thin::before { content: "\eab5"; }
  &-book-bookmark-thin::before { content: "\eab6"; }
  &-book-open-thin::before { content: "\eab7"; }
  &-book-thin::before { content: "\eab8"; }
  &-bookmark-simple-thin::before { content: "\eab9"; }
  &-bookmark-thin::before { content: "\eaba"; }
  &-bookmarks-simple-thin::before { content: "\eabb"; }
  &-bookmarks-thin::before { content: "\eabc"; }
  &-books-thin::before { content: "\eabd"; }
  &-bounding-box-thin::before { content: "\eabe"; }
  &-brackets-angle-thin::before { content: "\eabf"; }
  &-brackets-curly-thin::before { content: "\eac0"; }
  &-brackets-round-thin::before { content: "\eac1"; }
  &-brackets-square-thin::before { content: "\eac2"; }
  &-brain-thin::before { content: "\eac3"; }
  &-brandy-thin::before { content: "\eac4"; }
  &-briefcase-metal-thin::before { content: "\eac5"; }
  &-briefcase-thin::before { content: "\eac6"; }
  &-broadcast-thin::before { content: "\eac7"; }
  &-browser-thin::before { content: "\eac8"; }
  &-browsers-thin::before { content: "\eac9"; }
  &-bug-beetle-thin::before { content: "\eaca"; }
  &-bug-droid-thin::before { content: "\eacb"; }
  &-bug-thin::before { content: "\eacc"; }
  &-buildings-thin::before { content: "\eacd"; }
  &-bus-thin::before { content: "\eace"; }
  &-butterfly-thin::before { content: "\eacf"; }
  &-cactus-thin::before { content: "\ead0"; }
  &-cake-thin::before { content: "\ead1"; }
  &-calculator-thin::before { content: "\ead2"; }
  &-calendar-blank-thin::before { content: "\ead3"; }
  &-calendar-check-thin::before { content: "\ead4"; }
  &-calendar-plus-thin::before { content: "\ead5"; }
  &-calendar-thin::before { content: "\ead6"; }
  &-calendar-x-thin::before { content: "\ead7"; }
  &-camera-rotate-thin::before { content: "\ead8"; }
  &-camera-slash-thin::before { content: "\ead9"; }
  &-camera-thin::before { content: "\eada"; }
  &-campfire-thin::before { content: "\eadb"; }
  &-car-simple-thin::before { content: "\eadc"; }
  &-car-thin::before { content: "\eadd"; }
  &-cardholder-thin::before { content: "\eade"; }
  &-cards-thin::before { content: "\eadf"; }
  &-caret-circle-double-down-thin::before { content: "\eae0"; }
  &-caret-circle-double-left-thin::before { content: "\eae1"; }
  &-caret-circle-double-right-thin::before { content: "\eae2"; }
  &-caret-circle-double-up-thin::before { content: "\eae3"; }
  &-caret-circle-down-thin::before { content: "\eae4"; }
  &-caret-circle-left-thin::before { content: "\eae5"; }
  &-caret-circle-right-thin::before { content: "\eae6"; }
  &-caret-circle-up-thin::before { content: "\eae7"; }
  &-caret-double-down-thin::before { content: "\eae8"; }
  &-caret-double-left-thin::before { content: "\eae9"; }
  &-caret-double-right-thin::before { content: "\eaea"; }
  &-caret-double-up-thin::before { content: "\eaeb"; }
  &-caret-down-thin::before { content: "\eaec"; }
  &-caret-left-thin::before { content: "\eaed"; }
  &-caret-right-thin::before { content: "\eaee"; }
  &-caret-up-thin::before { content: "\eaef"; }
  &-cat-thin::before { content: "\eaf0"; }
  &-cell-signal-full-thin::before { content: "\eaf1"; }
  &-cell-signal-high-thin::before { content: "\eaf2"; }
  &-cell-signal-low-thin::before { content: "\eaf3"; }
  &-cell-signal-medium-thin::before { content: "\eaf4"; }
  &-cell-signal-none-thin::before { content: "\eaf5"; }
  &-cell-signal-slash-thin::before { content: "\eaf6"; }
  &-cell-signal-x-thin::before { content: "\eaf7"; }
  &-chalkboard-simple-thin::before { content: "\eaf8"; }
  &-chalkboard-teacher-thin::before { content: "\eaf9"; }
  &-chalkboard-thin::before { content: "\eafa"; }
  &-chart-bar-horizontal-thin::before { content: "\eafb"; }
  &-chart-bar-thin::before { content: "\eafc"; }
  &-chart-line-thin::before { content: "\eafd"; }
  &-chart-line-up-thin::before { content: "\eafe"; }
  &-chart-pie-slice-thin::before { content: "\eaff"; }
  &-chart-pie-thin::before { content: "\eb00"; }
  &-chat-centered-dots-thin::before { content: "\eb01"; }
  &-chat-centered-text-thin::before { content: "\eb02"; }
  &-chat-centered-thin::before { content: "\eb03"; }
  &-chat-circle-dots-thin::before { content: "\eb04"; }
  &-chat-circle-text-thin::before { content: "\eb05"; }
  &-chat-circle-thin::before { content: "\eb06"; }
  &-chat-dots-thin::before { content: "\eb07"; }
  &-chat-teardrop-dots-thin::before { content: "\eb08"; }
  &-chat-teardrop-text-thin::before { content: "\eb09"; }
  &-chat-teardrop-thin::before { content: "\eb0a"; }
  &-chat-text-thin::before { content: "\eb0b"; }
  &-chat-thin::before { content: "\eb0c"; }
  &-chats-circle-thin::before { content: "\eb0d"; }
  &-chats-teardrop-thin::before { content: "\eb0e"; }
  &-chats-thin::before { content: "\eb0f"; }
  &-check-circle-thin::before { content: "\eb10"; }
  &-check-square-offset-thin::before { content: "\eb11"; }
  &-check-square-thin::before { content: "\eb12"; }
  &-check-thin::before { content: "\eb13"; }
  &-checks-thin::before { content: "\eb14"; }
  &-circle-dashed-thin::before { content: "\eb15"; }
  &-circle-half-thin::before { content: "\eb16"; }
  &-circle-half-tilt-thin::before { content: "\eb17"; }
  &-circle-notch-thin::before { content: "\eb18"; }
  &-circle-thin::before { content: "\eb19"; }
  &-circle-wavy-check-thin::before { content: "\eb1a"; }
  &-circle-wavy-question-thin::before { content: "\eb1b"; }
  &-circle-wavy-thin::before { content: "\eb1c"; }
  &-circle-wavy-warning-thin::before { content: "\eb1d"; }
  &-circles-four-thin::before { content: "\eb1e"; }
  &-circles-three-plus-thin::before { content: "\eb1f"; }
  &-circles-three-thin::before { content: "\eb20"; }
  &-clipboard-text-thin::before { content: "\eb21"; }
  &-clipboard-thin::before { content: "\eb22"; }
  &-clock-afternoon-thin::before { content: "\eb23"; }
  &-clock-clockwise-thin::before { content: "\eb24"; }
  &-clock-counter-clockwise-thin::before { content: "\eb25"; }
  &-clock-thin::before { content: "\eb26"; }
  &-closed-captioning-thin::before { content: "\eb27"; }
  &-cloud-arrow-down-thin::before { content: "\eb28"; }
  &-cloud-arrow-up-thin::before { content: "\eb29"; }
  &-cloud-check-thin::before { content: "\eb2a"; }
  &-cloud-fog-thin::before { content: "\eb2b"; }
  &-cloud-lightning-thin::before { content: "\eb2c"; }
  &-cloud-moon-thin::before { content: "\eb2d"; }
  &-cloud-rain-thin::before { content: "\eb2e"; }
  &-cloud-slash-thin::before { content: "\eb2f"; }
  &-cloud-snow-thin::before { content: "\eb30"; }
  &-cloud-sun-thin::before { content: "\eb31"; }
  &-cloud-thin::before { content: "\eb32"; }
  &-club-thin::before { content: "\eb33"; }
  &-coat-hanger-thin::before { content: "\eb34"; }
  &-code-simple-thin::before { content: "\eb35"; }
  &-code-thin::before { content: "\eb36"; }
  &-codepen-logo-thin::before { content: "\eb37"; }
  &-codesandbox-logo-thin::before { content: "\eb38"; }
  &-coffee-thin::before { content: "\eb39"; }
  &-coin-thin::before { content: "\eb3a"; }
  &-coin-vertical-thin::before { content: "\eb3b"; }
  &-coins-thin::before { content: "\eb3c"; }
  &-columns-thin::before { content: "\eb3d"; }
  &-command-thin::before { content: "\eb3e"; }
  &-compass-thin::before { content: "\eb3f"; }
  &-computer-tower-thin::before { content: "\eb40"; }
  &-confetti-thin::before { content: "\eb41"; }
  &-cookie-thin::before { content: "\eb42"; }
  &-cooking-pot-thin::before { content: "\eb43"; }
  &-copy-simple-thin::before { content: "\eb44"; }
  &-copy-thin::before { content: "\eb45"; }
  &-copyleft-thin::before { content: "\eb46"; }
  &-copyright-thin::before { content: "\eb47"; }
  &-corners-in-thin::before { content: "\eb48"; }
  &-corners-out-thin::before { content: "\eb49"; }
  &-cpu-thin::before { content: "\eb4a"; }
  &-credit-card-thin::before { content: "\eb4b"; }
  &-crop-thin::before { content: "\eb4c"; }
  &-crosshair-simple-thin::before { content: "\eb4d"; }
  &-crosshair-thin::before { content: "\eb4e"; }
  &-crown-simple-thin::before { content: "\eb4f"; }
  &-crown-thin::before { content: "\eb50"; }
  &-cube-thin::before { content: "\eb51"; }
  &-currency-btc-thin::before { content: "\eb52"; }
  &-currency-circle-dollar-thin::before { content: "\eb53"; }
  &-currency-cny-thin::before { content: "\eb54"; }
  &-currency-dollar-simple-thin::before { content: "\eb55"; }
  &-currency-dollar-thin::before { content: "\eb56"; }
  &-currency-eth-thin::before { content: "\eb57"; }
  &-currency-eur-thin::before { content: "\eb58"; }
  &-currency-gbp-thin::before { content: "\eb59"; }
  &-currency-inr-thin::before { content: "\eb5a"; }
  &-currency-jpy-thin::before { content: "\eb5b"; }
  &-currency-krw-thin::before { content: "\eb5c"; }
  &-currency-kzt-thin::before { content: "\eb5d"; }
  &-currency-ngn-thin::before { content: "\eb5e"; }
  &-currency-rub-thin::before { content: "\eb5f"; }
  &-cursor-text-thin::before { content: "\eb60"; }
  &-cursor-thin::before { content: "\eb61"; }
  &-cylinder-thin::before { content: "\eb62"; }
  &-database-thin::before { content: "\eb63"; }
  &-desktop-thin::before { content: "\eb64"; }
  &-desktop-tower-thin::before { content: "\eb65"; }
  &-detective-thin::before { content: "\eb66"; }
  &-device-mobile-camera-thin::before { content: "\eb67"; }
  &-device-mobile-speaker-thin::before { content: "\eb68"; }
  &-device-mobile-thin::before { content: "\eb69"; }
  &-device-tablet-camera-thin::before { content: "\eb6a"; }
  &-device-tablet-speaker-thin::before { content: "\eb6b"; }
  &-device-tablet-thin::before { content: "\eb6c"; }
  &-diamond-thin::before { content: "\eb6d"; }
  &-diamonds-four-thin::before { content: "\eb6e"; }
  &-dice-five-thin::before { content: "\eb6f"; }
  &-dice-four-thin::before { content: "\eb70"; }
  &-dice-one-thin::before { content: "\eb71"; }
  &-dice-six-thin::before { content: "\eb72"; }
  &-dice-three-thin::before { content: "\eb73"; }
  &-dice-two-thin::before { content: "\eb74"; }
  &-disc-thin::before { content: "\eb75"; }
  &-discord-logo-thin::before { content: "\eb76"; }
  &-divide-thin::before { content: "\eb77"; }
  &-dog-thin::before { content: "\eb78"; }
  &-door-thin::before { content: "\eb79"; }
  &-dots-nine-thin::before { content: "\eb7a"; }
  &-dots-six-thin::before { content: "\eb7b"; }
  &-dots-six-vertical-thin::before { content: "\eb7c"; }
  &-dots-three-circle-thin::before { content: "\eb7d"; }
  &-dots-three-circle-vertical-thin::before { content: "\eb7e"; }
  &-dots-three-outline-thin::before { content: "\eb7f"; }
  &-dots-three-outline-vertical-thin::before { content: "\eb80"; }
  &-dots-three-thin::before { content: "\eb81"; }
  &-dots-three-vertical-thin::before { content: "\eb82"; }
  &-download-simple-thin::before { content: "\eb83"; }
  &-download-thin::before { content: "\eb84"; }
  &-dribbble-logo-thin::before { content: "\eb85"; }
  &-drop-half-bottom-thin::before { content: "\eb86"; }
  &-drop-half-thin::before { content: "\eb87"; }
  &-drop-thin::before { content: "\eb88"; }
  &-ear-slash-thin::before { content: "\eb89"; }
  &-ear-thin::before { content: "\eb8a"; }
  &-egg-crack-thin::before { content: "\eb8b"; }
  &-egg-thin::before { content: "\eb8c"; }
  &-eject-simple-thin::before { content: "\eb8d"; }
  &-eject-thin::before { content: "\eb8e"; }
  &-envelope-open-thin::before { content: "\eb8f"; }
  &-envelope-simple-open-thin::before { content: "\eb90"; }
  &-envelope-simple-thin::before { content: "\eb91"; }
  &-envelope-thin::before { content: "\eb92"; }
  &-equalizer-thin::before { content: "\eb93"; }
  &-equals-thin::before { content: "\eb94"; }
  &-eraser-thin::before { content: "\eb95"; }
  &-exam-thin::before { content: "\eb96"; }
  &-export-thin::before { content: "\eb97"; }
  &-eye-closed-thin::before { content: "\eb98"; }
  &-eye-slash-thin::before { content: "\eb99"; }
  &-eye-thin::before { content: "\eb9a"; }
  &-eyedropper-sample-thin::before { content: "\eb9b"; }
  &-eyedropper-thin::before { content: "\eb9c"; }
  &-eyeglasses-thin::before { content: "\eb9d"; }
  &-face-mask-thin::before { content: "\eb9e"; }
  &-facebook-logo-thin::before { content: "\eb9f"; }
  &-factory-thin::before { content: "\eba0"; }
  &-faders-horizontal-thin::before { content: "\eba1"; }
  &-faders-thin::before { content: "\eba2"; }
  &-fast-forward-circle-thin::before { content: "\eba3"; }
  &-fast-forward-thin::before { content: "\eba4"; }
  &-figma-logo-thin::before { content: "\eba5"; }
  &-file-arrow-down-thin::before { content: "\eba6"; }
  &-file-arrow-up-thin::before { content: "\eba7"; }
  &-file-audio-thin::before { content: "\eba8"; }
  &-file-cloud-thin::before { content: "\eba9"; }
  &-file-code-thin::before { content: "\ebaa"; }
  &-file-css-thin::before { content: "\ebab"; }
  &-file-csv-thin::before { content: "\ebac"; }
  &-file-doc-thin::before { content: "\ebad"; }
  &-file-dotted-thin::before { content: "\ebae"; }
  &-file-html-thin::before { content: "\ebaf"; }
  &-file-image-thin::before { content: "\ebb0"; }
  &-file-jpg-thin::before { content: "\ebb1"; }
  &-file-js-thin::before { content: "\ebb2"; }
  &-file-jsx-thin::before { content: "\ebb3"; }
  &-file-lock-thin::before { content: "\ebb4"; }
  &-file-minus-thin::before { content: "\ebb5"; }
  &-file-pdf-thin::before { content: "\ebb6"; }
  &-file-plus-thin::before { content: "\ebb7"; }
  &-file-png-thin::before { content: "\ebb8"; }
  &-file-ppt-thin::before { content: "\ebb9"; }
  &-file-rs-thin::before { content: "\ebba"; }
  &-file-search-thin::before { content: "\ebbb"; }
  &-file-text-thin::before { content: "\ebbc"; }
  &-file-thin::before { content: "\ebbd"; }
  &-file-ts-thin::before { content: "\ebbe"; }
  &-file-tsx-thin::before { content: "\ebbf"; }
  &-file-video-thin::before { content: "\ebc0"; }
  &-file-vue-thin::before { content: "\ebc1"; }
  &-file-x-thin::before { content: "\ebc2"; }
  &-file-xls-thin::before { content: "\ebc3"; }
  &-file-zip-thin::before { content: "\ebc4"; }
  &-files-thin::before { content: "\ebc5"; }
  &-film-script-thin::before { content: "\ebc6"; }
  &-film-slate-thin::before { content: "\ebc7"; }
  &-film-strip-thin::before { content: "\ebc8"; }
  &-fingerprint-simple-thin::before { content: "\ebc9"; }
  &-fingerprint-thin::before { content: "\ebca"; }
  &-finn-the-human-thin::before { content: "\ebcb"; }
  &-fire-simple-thin::before { content: "\ebcc"; }
  &-fire-thin::before { content: "\ebcd"; }
  &-first-aid-kit-thin::before { content: "\ebce"; }
  &-first-aid-thin::before { content: "\ebcf"; }
  &-fish-simple-thin::before { content: "\ebd0"; }
  &-fish-thin::before { content: "\ebd1"; }
  &-flag-banner-thin::before { content: "\ebd2"; }
  &-flag-checkered-thin::before { content: "\ebd3"; }
  &-flag-thin::before { content: "\ebd4"; }
  &-flame-thin::before { content: "\ebd5"; }
  &-flashlight-thin::before { content: "\ebd6"; }
  &-flask-thin::before { content: "\ebd7"; }
  &-floppy-disk-back-thin::before { content: "\ebd8"; }
  &-floppy-disk-thin::before { content: "\ebd9"; }
  &-flow-arrow-thin::before { content: "\ebda"; }
  &-flower-lotus-thin::before { content: "\ebdb"; }
  &-flower-thin::before { content: "\ebdc"; }
  &-flying-saucer-thin::before { content: "\ebdd"; }
  &-folder-dotted-thin::before { content: "\ebde"; }
  &-folder-lock-thin::before { content: "\ebdf"; }
  &-folder-minus-thin::before { content: "\ebe0"; }
  &-folder-notch-minus-thin::before { content: "\ebe1"; }
  &-folder-notch-open-thin::before { content: "\ebe2"; }
  &-folder-notch-plus-thin::before { content: "\ebe3"; }
  &-folder-notch-thin::before { content: "\ebe4"; }
  &-folder-open-thin::before { content: "\ebe5"; }
  &-folder-plus-thin::before { content: "\ebe6"; }
  &-folder-simple-dotted-thin::before { content: "\ebe7"; }
  &-folder-simple-lock-thin::before { content: "\ebe8"; }
  &-folder-simple-minus-thin::before { content: "\ebe9"; }
  &-folder-simple-plus-thin::before { content: "\ebea"; }
  &-folder-simple-star-thin::before { content: "\ebeb"; }
  &-folder-simple-thin::before { content: "\ebec"; }
  &-folder-simple-user-thin::before { content: "\ebed"; }
  &-folder-star-thin::before { content: "\ebee"; }
  &-folder-thin::before { content: "\ebef"; }
  &-folder-user-thin::before { content: "\ebf0"; }
  &-folders-thin::before { content: "\ebf1"; }
  &-football-thin::before { content: "\ebf2"; }
  &-fork-knife-thin::before { content: "\ebf3"; }
  &-frame-corners-thin::before { content: "\ebf4"; }
  &-framer-logo-thin::before { content: "\ebf5"; }
  &-function-thin::before { content: "\ebf6"; }
  &-funnel-simple-thin::before { content: "\ebf7"; }
  &-funnel-thin::before { content: "\ebf8"; }
  &-game-controller-thin::before { content: "\ebf9"; }
  &-gas-pump-thin::before { content: "\ebfa"; }
  &-gauge-thin::before { content: "\ebfb"; }
  &-gear-six-thin::before { content: "\ebfc"; }
  &-gear-thin::before { content: "\ebfd"; }
  &-gender-female-thin::before { content: "\ebfe"; }
  &-gender-intersex-thin::before { content: "\ebff"; }
  &-gender-male-thin::before { content: "\ec00"; }
  &-gender-neuter-thin::before { content: "\ec01"; }
  &-gender-nonbinary-thin::before { content: "\ec02"; }
  &-gender-transgender-thin::before { content: "\ec03"; }
  &-ghost-thin::before { content: "\ec04"; }
  &-gif-thin::before { content: "\ec05"; }
  &-gift-thin::before { content: "\ec06"; }
  &-git-branch-thin::before { content: "\ec07"; }
  &-git-commit-thin::before { content: "\ec08"; }
  &-git-diff-thin::before { content: "\ec09"; }
  &-git-fork-thin::before { content: "\ec0a"; }
  &-git-merge-thin::before { content: "\ec0b"; }
  &-git-pull-request-thin::before { content: "\ec0c"; }
  &-github-logo-thin::before { content: "\ec0d"; }
  &-gitlab-logo-simple-thin::before { content: "\ec0e"; }
  &-gitlab-logo-thin::before { content: "\ec0f"; }
  &-globe-hemisphere-east-thin::before { content: "\ec10"; }
  &-globe-hemisphere-west-thin::before { content: "\ec11"; }
  &-globe-simple-thin::before { content: "\ec12"; }
  &-globe-stand-thin::before { content: "\ec13"; }
  &-globe-thin::before { content: "\ec14"; }
  &-google-chrome-logo-thin::before { content: "\ec15"; }
  &-google-logo-thin::before { content: "\ec16"; }
  &-google-photos-logo-thin::before { content: "\ec17"; }
  &-google-play-logo-thin::before { content: "\ec18"; }
  &-google-podcasts-logo-thin::before { content: "\ec19"; }
  &-gradient-thin::before { content: "\ec1a"; }
  &-graduation-cap-thin::before { content: "\ec1b"; }
  &-graph-thin::before { content: "\ec1c"; }
  &-grid-four-thin::before { content: "\ec1d"; }
  &-hamburger-thin::before { content: "\ec1e"; }
  &-hand-eye-thin::before { content: "\ec1f"; }
  &-hand-fist-thin::before { content: "\ec20"; }
  &-hand-grabbing-thin::before { content: "\ec21"; }
  &-hand-palm-thin::before { content: "\ec22"; }
  &-hand-pointing-thin::before { content: "\ec23"; }
  &-hand-soap-thin::before { content: "\ec24"; }
  &-hand-thin::before { content: "\ec25"; }
  &-hand-waving-thin::before { content: "\ec26"; }
  &-handbag-simple-thin::before { content: "\ec27"; }
  &-handbag-thin::before { content: "\ec28"; }
  &-hands-clapping-thin::before { content: "\ec29"; }
  &-handshake-thin::before { content: "\ec2a"; }
  &-hard-drive-thin::before { content: "\ec2b"; }
  &-hard-drives-thin::before { content: "\ec2c"; }
  &-hash-straight-thin::before { content: "\ec2d"; }
  &-hash-thin::before { content: "\ec2e"; }
  &-headlights-thin::before { content: "\ec2f"; }
  &-headphones-thin::before { content: "\ec30"; }
  &-headset-thin::before { content: "\ec31"; }
  &-heart-break-thin::before { content: "\ec32"; }
  &-heart-straight-break-thin::before { content: "\ec33"; }
  &-heart-straight-thin::before { content: "\ec34"; }
  &-heart-thin::before { content: "\ec35"; }
  &-heartbeat-thin::before { content: "\ec36"; }
  &-hexagon-thin::before { content: "\ec37"; }
  &-highlighter-circle-thin::before { content: "\ec38"; }
  &-horse-thin::before { content: "\ec39"; }
  &-hourglass-high-thin::before { content: "\ec3a"; }
  &-hourglass-low-thin::before { content: "\ec3b"; }
  &-hourglass-medium-thin::before { content: "\ec3c"; }
  &-hourglass-simple-high-thin::before { content: "\ec3d"; }
  &-hourglass-simple-low-thin::before { content: "\ec3e"; }
  &-hourglass-simple-medium-thin::before { content: "\ec3f"; }
  &-hourglass-simple-thin::before { content: "\ec40"; }
  &-hourglass-thin::before { content: "\ec41"; }
  &-house-line-thin::before { content: "\ec42"; }
  &-house-simple-thin::before { content: "\ec43"; }
  &-house-thin::before { content: "\ec44"; }
  &-identification-badge-thin::before { content: "\ec45"; }
  &-identification-card-thin::before { content: "\ec46"; }
  &-image-square-thin::before { content: "\ec47"; }
  &-image-thin::before { content: "\ec48"; }
  &-infinity-thin::before { content: "\ec49"; }
  &-info-thin::before { content: "\ec4a"; }
  &-instagram-logo-thin::before { content: "\ec4b"; }
  &-intersect-thin::before { content: "\ec4c"; }
  &-jeep-thin::before { content: "\ec4d"; }
  &-kanban-thin::before { content: "\ec4e"; }
  &-key-return-thin::before { content: "\ec4f"; }
  &-key-thin::before { content: "\ec50"; }
  &-keyboard-thin::before { content: "\ec51"; }
  &-keyhole-thin::before { content: "\ec52"; }
  &-knife-thin::before { content: "\ec53"; }
  &-ladder-simple-thin::before { content: "\ec54"; }
  &-ladder-thin::before { content: "\ec55"; }
  &-lamp-thin::before { content: "\ec56"; }
  &-laptop-thin::before { content: "\ec57"; }
  &-layout-thin::before { content: "\ec58"; }
  &-leaf-thin::before { content: "\ec59"; }
  &-lifebuoy-thin::before { content: "\ec5a"; }
  &-lightbulb-filament-thin::before { content: "\ec5b"; }
  &-lightbulb-thin::before { content: "\ec5c"; }
  &-lightning-slash-thin::before { content: "\ec5d"; }
  &-lightning-thin::before { content: "\ec5e"; }
  &-line-segment-thin::before { content: "\ec5f"; }
  &-line-segments-thin::before { content: "\ec60"; }
  &-link-break-thin::before { content: "\ec61"; }
  &-link-simple-break-thin::before { content: "\ec62"; }
  &-link-simple-horizontal-break-thin::before { content: "\ec63"; }
  &-link-simple-horizontal-thin::before { content: "\ec64"; }
  &-link-simple-thin::before { content: "\ec65"; }
  &-link-thin::before { content: "\ec66"; }
  &-linkedin-logo-thin::before { content: "\ec67"; }
  &-linux-logo-thin::before { content: "\ec68"; }
  &-list-bullets-thin::before { content: "\ec69"; }
  &-list-checks-thin::before { content: "\ec6a"; }
  &-list-dashes-thin::before { content: "\ec6b"; }
  &-list-numbers-thin::before { content: "\ec6c"; }
  &-list-plus-thin::before { content: "\ec6d"; }
  &-list-thin::before { content: "\ec6e"; }
  &-lock-key-open-thin::before { content: "\ec6f"; }
  &-lock-key-thin::before { content: "\ec70"; }
  &-lock-laminated-open-thin::before { content: "\ec71"; }
  &-lock-laminated-thin::before { content: "\ec72"; }
  &-lock-open-thin::before { content: "\ec73"; }
  &-lock-simple-open-thin::before { content: "\ec74"; }
  &-lock-simple-thin::before { content: "\ec75"; }
  &-lock-thin::before { content: "\ec76"; }
  &-magic-wand-thin::before { content: "\ec77"; }
  &-magnet-straight-thin::before { content: "\ec78"; }
  &-magnet-thin::before { content: "\ec79"; }
  &-magnifying-glass-minus-thin::before { content: "\ec7a"; }
  &-magnifying-glass-plus-thin::before { content: "\ec7b"; }
  &-magnifying-glass-thin::before { content: "\ec7c"; }
  &-map-pin-line-thin::before { content: "\ec7d"; }
  &-map-pin-thin::before { content: "\ec7e"; }
  &-map-trifold-thin::before { content: "\ec7f"; }
  &-marker-circle-thin::before { content: "\ec80"; }
  &-martini-thin::before { content: "\ec81"; }
  &-mask-happy-thin::before { content: "\ec82"; }
  &-mask-sad-thin::before { content: "\ec83"; }
  &-math-operations-thin::before { content: "\ec84"; }
  &-medal-thin::before { content: "\ec85"; }
  &-medium-logo-thin::before { content: "\ec86"; }
  &-megaphone-simple-thin::before { content: "\ec87"; }
  &-megaphone-thin::before { content: "\ec88"; }
  &-messenger-logo-thin::before { content: "\ec89"; }
  &-microphone-slash-thin::before { content: "\ec8a"; }
  &-microphone-stage-thin::before { content: "\ec8b"; }
  &-microphone-thin::before { content: "\ec8c"; }
  &-microsoft-excel-logo-thin::before { content: "\ec8d"; }
  &-microsoft-powerpoint-logo-thin::before { content: "\ec8e"; }
  &-microsoft-teams-logo-thin::before { content: "\ec8f"; }
  &-microsoft-word-logo-thin::before { content: "\ec90"; }
  &-minus-circle-thin::before { content: "\ec91"; }
  &-minus-thin::before { content: "\ec92"; }
  &-money-thin::before { content: "\ec93"; }
  &-monitor-play-thin::before { content: "\ec94"; }
  &-monitor-thin::before { content: "\ec95"; }
  &-moon-stars-thin::before { content: "\ec96"; }
  &-moon-thin::before { content: "\ec97"; }
  &-mountains-thin::before { content: "\ec98"; }
  &-mouse-simple-thin::before { content: "\ec99"; }
  &-mouse-thin::before { content: "\ec9a"; }
  &-music-note-simple-thin::before { content: "\ec9b"; }
  &-music-note-thin::before { content: "\ec9c"; }
  &-music-notes-plus-thin::before { content: "\ec9d"; }
  &-music-notes-simple-thin::before { content: "\ec9e"; }
  &-music-notes-thin::before { content: "\ec9f"; }
  &-navigation-arrow-thin::before { content: "\eca0"; }
  &-needle-thin::before { content: "\eca1"; }
  &-newspaper-clipping-thin::before { content: "\eca2"; }
  &-newspaper-thin::before { content: "\eca3"; }
  &-note-blank-thin::before { content: "\eca4"; }
  &-note-pencil-thin::before { content: "\eca5"; }
  &-note-thin::before { content: "\eca6"; }
  &-notebook-thin::before { content: "\eca7"; }
  &-notepad-thin::before { content: "\eca8"; }
  &-notification-thin::before { content: "\eca9"; }
  &-number-circle-eight-thin::before { content: "\ecaa"; }
  &-number-circle-five-thin::before { content: "\ecab"; }
  &-number-circle-four-thin::before { content: "\ecac"; }
  &-number-circle-nine-thin::before { content: "\ecad"; }
  &-number-circle-one-thin::before { content: "\ecae"; }
  &-number-circle-seven-thin::before { content: "\ecaf"; }
  &-number-circle-six-thin::before { content: "\ecb0"; }
  &-number-circle-three-thin::before { content: "\ecb1"; }
  &-number-circle-two-thin::before { content: "\ecb2"; }
  &-number-circle-zero-thin::before { content: "\ecb3"; }
  &-number-eight-thin::before { content: "\ecb4"; }
  &-number-five-thin::before { content: "\ecb5"; }
  &-number-four-thin::before { content: "\ecb6"; }
  &-number-nine-thin::before { content: "\ecb7"; }
  &-number-one-thin::before { content: "\ecb8"; }
  &-number-seven-thin::before { content: "\ecb9"; }
  &-number-six-thin::before { content: "\ecba"; }
  &-number-square-eight-thin::before { content: "\ecbb"; }
  &-number-square-five-thin::before { content: "\ecbc"; }
  &-number-square-four-thin::before { content: "\ecbd"; }
  &-number-square-nine-thin::before { content: "\ecbe"; }
  &-number-square-one-thin::before { content: "\ecbf"; }
  &-number-square-seven-thin::before { content: "\ecc0"; }
  &-number-square-six-thin::before { content: "\ecc1"; }
  &-number-square-three-thin::before { content: "\ecc2"; }
  &-number-square-two-thin::before { content: "\ecc3"; }
  &-number-square-zero-thin::before { content: "\ecc4"; }
  &-number-three-thin::before { content: "\ecc5"; }
  &-number-two-thin::before { content: "\ecc6"; }
  &-number-zero-thin::before { content: "\ecc7"; }
  &-nut-thin::before { content: "\ecc8"; }
  &-ny-times-logo-thin::before { content: "\ecc9"; }
  &-octagon-thin::before { content: "\ecca"; }
  &-option-thin::before { content: "\eccb"; }
  &-package-thin::before { content: "\eccc"; }
  &-paint-brush-broad-thin::before { content: "\eccd"; }
  &-paint-brush-household-thin::before { content: "\ecce"; }
  &-paint-brush-thin::before { content: "\eccf"; }
  &-paint-bucket-thin::before { content: "\ecd0"; }
  &-paint-roller-thin::before { content: "\ecd1"; }
  &-palette-thin::before { content: "\ecd2"; }
  &-paper-plane-right-thin::before { content: "\ecd3"; }
  &-paper-plane-thin::before { content: "\ecd4"; }
  &-paper-plane-tilt-thin::before { content: "\ecd5"; }
  &-paperclip-horizontal-thin::before { content: "\ecd6"; }
  &-paperclip-thin::before { content: "\ecd7"; }
  &-parachute-thin::before { content: "\ecd8"; }
  &-password-thin::before { content: "\ecd9"; }
  &-path-thin::before { content: "\ecda"; }
  &-pause-circle-thin::before { content: "\ecdb"; }
  &-pause-thin::before { content: "\ecdc"; }
  &-paw-print-thin::before { content: "\ecdd"; }
  &-peace-thin::before { content: "\ecde"; }
  &-pen-nib-straight-thin::before { content: "\ecdf"; }
  &-pen-nib-thin::before { content: "\ece0"; }
  &-pen-thin::before { content: "\ece1"; }
  &-pencil-circle-thin::before { content: "\ece2"; }
  &-pencil-line-thin::before { content: "\ece3"; }
  &-pencil-simple-line-thin::before { content: "\ece4"; }
  &-pencil-simple-thin::before { content: "\ece5"; }
  &-pencil-thin::before { content: "\ece6"; }
  &-percent-thin::before { content: "\ece7"; }
  &-person-simple-run-thin::before { content: "\ece8"; }
  &-person-simple-thin::before { content: "\ece9"; }
  &-person-simple-walk-thin::before { content: "\ecea"; }
  &-person-thin::before { content: "\eceb"; }
  &-perspective-thin::before { content: "\ecec"; }
  &-phone-call-thin::before { content: "\eced"; }
  &-phone-disconnect-thin::before { content: "\ecee"; }
  &-phone-incoming-thin::before { content: "\ecef"; }
  &-phone-outgoing-thin::before { content: "\ecf0"; }
  &-phone-slash-thin::before { content: "\ecf1"; }
  &-phone-thin::before { content: "\ecf2"; }
  &-phone-x-thin::before { content: "\ecf3"; }
  &-phosphor-logo-thin::before { content: "\ecf4"; }
  &-piano-keys-thin::before { content: "\ecf5"; }
  &-picture-in-picture-thin::before { content: "\ecf6"; }
  &-pill-thin::before { content: "\ecf7"; }
  &-pinterest-logo-thin::before { content: "\ecf8"; }
  &-pinwheel-thin::before { content: "\ecf9"; }
  &-pizza-thin::before { content: "\ecfa"; }
  &-placeholder-thin::before { content: "\ecfb"; }
  &-planet-thin::before { content: "\ecfc"; }
  &-play-circle-thin::before { content: "\ecfd"; }
  &-play-thin::before { content: "\ecfe"; }
  &-playlist-thin::before { content: "\ecff"; }
  &-plug-thin::before { content: "\ed00"; }
  &-plugs-connected-thin::before { content: "\ed01"; }
  &-plugs-thin::before { content: "\ed02"; }
  &-plus-circle-thin::before { content: "\ed03"; }
  &-plus-minus-thin::before { content: "\ed04"; }
  &-plus-thin::before { content: "\ed05"; }
  &-poker-chip-thin::before { content: "\ed06"; }
  &-police-car-thin::before { content: "\ed07"; }
  &-polygon-thin::before { content: "\ed08"; }
  &-popcorn-thin::before { content: "\ed09"; }
  &-power-thin::before { content: "\ed0a"; }
  &-prescription-thin::before { content: "\ed0b"; }
  &-presentation-chart-thin::before { content: "\ed0c"; }
  &-presentation-thin::before { content: "\ed0d"; }
  &-printer-thin::before { content: "\ed0e"; }
  &-prohibit-inset-thin::before { content: "\ed0f"; }
  &-prohibit-thin::before { content: "\ed10"; }
  &-projector-screen-chart-thin::before { content: "\ed11"; }
  &-projector-screen-thin::before { content: "\ed12"; }
  &-push-pin-simple-slash-thin::before { content: "\ed13"; }
  &-push-pin-simple-thin::before { content: "\ed14"; }
  &-push-pin-slash-thin::before { content: "\ed15"; }
  &-push-pin-thin::before { content: "\ed16"; }
  &-puzzle-piece-thin::before { content: "\ed17"; }
  &-qr-code-thin::before { content: "\ed18"; }
  &-question-thin::before { content: "\ed19"; }
  &-queue-thin::before { content: "\ed1a"; }
  &-quotes-thin::before { content: "\ed1b"; }
  &-radical-thin::before { content: "\ed1c"; }
  &-radio-button-thin::before { content: "\ed1d"; }
  &-radio-thin::before { content: "\ed1e"; }
  &-rainbow-cloud-thin::before { content: "\ed1f"; }
  &-rainbow-thin::before { content: "\ed20"; }
  &-receipt-thin::before { content: "\ed21"; }
  &-record-thin::before { content: "\ed22"; }
  &-rectangle-thin::before { content: "\ed23"; }
  &-recycle-thin::before { content: "\ed24"; }
  &-reddit-logo-thin::before { content: "\ed25"; }
  &-repeat-once-thin::before { content: "\ed26"; }
  &-repeat-thin::before { content: "\ed27"; }
  &-rewind-circle-thin::before { content: "\ed28"; }
  &-rewind-thin::before { content: "\ed29"; }
  &-robot-thin::before { content: "\ed2a"; }
  &-rocket-launch-thin::before { content: "\ed2b"; }
  &-rocket-thin::before { content: "\ed2c"; }
  &-rows-thin::before { content: "\ed2d"; }
  &-rss-simple-thin::before { content: "\ed2e"; }
  &-rss-thin::before { content: "\ed2f"; }
  &-rug-thin::before { content: "\ed30"; }
  &-ruler-thin::before { content: "\ed31"; }
  &-scales-thin::before { content: "\ed32"; }
  &-scan-thin::before { content: "\ed33"; }
  &-scissors-thin::before { content: "\ed34"; }
  &-screencast-thin::before { content: "\ed35"; }
  &-scribble-loop-thin::before { content: "\ed36"; }
  &-scroll-thin::before { content: "\ed37"; }
  &-selection-all-thin::before { content: "\ed38"; }
  &-selection-background-thin::before { content: "\ed39"; }
  &-selection-foreground-thin::before { content: "\ed3a"; }
  &-selection-inverse-thin::before { content: "\ed3b"; }
  &-selection-plus-thin::before { content: "\ed3c"; }
  &-selection-slash-thin::before { content: "\ed3d"; }
  &-selection-thin::before { content: "\ed3e"; }
  &-share-network-thin::before { content: "\ed3f"; }
  &-share-thin::before { content: "\ed40"; }
  &-shield-check-thin::before { content: "\ed41"; }
  &-shield-checkered-thin::before { content: "\ed42"; }
  &-shield-chevron-thin::before { content: "\ed43"; }
  &-shield-plus-thin::before { content: "\ed44"; }
  &-shield-slash-thin::before { content: "\ed45"; }
  &-shield-star-thin::before { content: "\ed46"; }
  &-shield-thin::before { content: "\ed47"; }
  &-shield-warning-thin::before { content: "\ed48"; }
  &-shopping-bag-open-thin::before { content: "\ed49"; }
  &-shopping-bag-thin::before { content: "\ed4a"; }
  &-shopping-cart-simple-thin::before { content: "\ed4b"; }
  &-shopping-cart-thin::before { content: "\ed4c"; }
  &-shower-thin::before { content: "\ed4d"; }
  &-shuffle-angular-thin::before { content: "\ed4e"; }
  &-shuffle-simple-thin::before { content: "\ed4f"; }
  &-shuffle-thin::before { content: "\ed50"; }
  &-sidebar-simple-thin::before { content: "\ed51"; }
  &-sidebar-thin::before { content: "\ed52"; }
  &-sign-in-thin::before { content: "\ed53"; }
  &-sign-out-thin::before { content: "\ed54"; }
  &-signpost-thin::before { content: "\ed55"; }
  &-sim-card-thin::before { content: "\ed56"; }
  &-sketch-logo-thin::before { content: "\ed57"; }
  &-skip-back-circle-thin::before { content: "\ed58"; }
  &-skip-back-thin::before { content: "\ed59"; }
  &-skip-forward-circle-thin::before { content: "\ed5a"; }
  &-skip-forward-thin::before { content: "\ed5b"; }
  &-skull-thin::before { content: "\ed5c"; }
  &-slack-logo-thin::before { content: "\ed5d"; }
  &-sliders-horizontal-thin::before { content: "\ed5e"; }
  &-sliders-thin::before { content: "\ed5f"; }
  &-smiley-blank-thin::before { content: "\ed60"; }
  &-smiley-meh-thin::before { content: "\ed61"; }
  &-smiley-nervous-thin::before { content: "\ed62"; }
  &-smiley-sad-thin::before { content: "\ed63"; }
  &-smiley-sticker-thin::before { content: "\ed64"; }
  &-smiley-thin::before { content: "\ed65"; }
  &-smiley-wink-thin::before { content: "\ed66"; }
  &-smiley-x-eyes-thin::before { content: "\ed67"; }
  &-snapchat-logo-thin::before { content: "\ed68"; }
  &-snowflake-thin::before { content: "\ed69"; }
  &-soccer-ball-thin::before { content: "\ed6a"; }
  &-sort-ascending-thin::before { content: "\ed6b"; }
  &-sort-descending-thin::before { content: "\ed6c"; }
  &-spade-thin::before { content: "\ed6d"; }
  &-sparkle-thin::before { content: "\ed6e"; }
  &-speaker-high-thin::before { content: "\ed6f"; }
  &-speaker-low-thin::before { content: "\ed70"; }
  &-speaker-none-thin::before { content: "\ed71"; }
  &-speaker-simple-high-thin::before { content: "\ed72"; }
  &-speaker-simple-low-thin::before { content: "\ed73"; }
  &-speaker-simple-none-thin::before { content: "\ed74"; }
  &-speaker-simple-slash-thin::before { content: "\ed75"; }
  &-speaker-simple-x-thin::before { content: "\ed76"; }
  &-speaker-slash-thin::before { content: "\ed77"; }
  &-speaker-x-thin::before { content: "\ed78"; }
  &-spinner-gap-thin::before { content: "\ed79"; }
  &-spinner-thin::before { content: "\ed7a"; }
  &-spiral-thin::before { content: "\ed7b"; }
  &-spotify-logo-thin::before { content: "\ed7c"; }
  &-square-half-bottom-thin::before { content: "\ed7d"; }
  &-square-half-thin::before { content: "\ed7e"; }
  &-square-logo-thin::before { content: "\ed7f"; }
  &-square-thin::before { content: "\ed80"; }
  &-squares-four-thin::before { content: "\ed81"; }
  &-stack-overflow-logo-thin::before { content: "\ed82"; }
  &-stack-simple-thin::before { content: "\ed83"; }
  &-stack-thin::before { content: "\ed84"; }
  &-stamp-thin::before { content: "\ed85"; }
  &-star-four-thin::before { content: "\ed86"; }
  &-star-half-thin::before { content: "\ed87"; }
  &-star-thin::before { content: "\ed88"; }
  &-sticker-thin::before { content: "\ed89"; }
  &-stop-circle-thin::before { content: "\ed8a"; }
  &-stop-thin::before { content: "\ed8b"; }
  &-storefront-thin::before { content: "\ed8c"; }
  &-strategy-thin::before { content: "\ed8d"; }
  &-stripe-logo-thin::before { content: "\ed8e"; }
  &-student-thin::before { content: "\ed8f"; }
  &-suitcase-simple-thin::before { content: "\ed90"; }
  &-suitcase-thin::before { content: "\ed91"; }
  &-sun-dim-thin::before { content: "\ed92"; }
  &-sun-horizon-thin::before { content: "\ed93"; }
  &-sun-thin::before { content: "\ed94"; }
  &-sunglasses-thin::before { content: "\ed95"; }
  &-swap-thin::before { content: "\ed96"; }
  &-swatches-thin::before { content: "\ed97"; }
  &-sword-thin::before { content: "\ed98"; }
  &-syringe-thin::before { content: "\ed99"; }
  &-t-shirt-thin::before { content: "\ed9a"; }
  &-table-thin::before { content: "\ed9b"; }
  &-tabs-thin::before { content: "\ed9c"; }
  &-tag-chevron-thin::before { content: "\ed9d"; }
  &-tag-simple-thin::before { content: "\ed9e"; }
  &-tag-thin::before { content: "\ed9f"; }
  &-target-thin::before { content: "\eda0"; }
  &-taxi-thin::before { content: "\eda1"; }
  &-telegram-logo-thin::before { content: "\eda2"; }
  &-television-simple-thin::before { content: "\eda3"; }
  &-television-thin::before { content: "\eda4"; }
  &-tennis-ball-thin::before { content: "\eda5"; }
  &-terminal-thin::before { content: "\eda6"; }
  &-terminal-window-thin::before { content: "\eda7"; }
  &-test-tube-thin::before { content: "\eda8"; }
  &-text-aa-thin::before { content: "\eda9"; }
  &-text-align-center-thin::before { content: "\edaa"; }
  &-text-align-justify-thin::before { content: "\edab"; }
  &-text-align-left-thin::before { content: "\edac"; }
  &-text-align-right-thin::before { content: "\edad"; }
  &-text-bolder-thin::before { content: "\edae"; }
  &-text-h-five-thin::before { content: "\edaf"; }
  &-text-h-four-thin::before { content: "\edb0"; }
  &-text-h-one-thin::before { content: "\edb1"; }
  &-text-h-six-thin::before { content: "\edb2"; }
  &-text-h-thin::before { content: "\edb3"; }
  &-text-h-three-thin::before { content: "\edb4"; }
  &-text-h-two-thin::before { content: "\edb5"; }
  &-text-indent-thin::before { content: "\edb6"; }
  &-text-italic-thin::before { content: "\edb7"; }
  &-text-outdent-thin::before { content: "\edb8"; }
  &-text-strikethrough-thin::before { content: "\edb9"; }
  &-text-t-thin::before { content: "\edba"; }
  &-text-underline-thin::before { content: "\edbb"; }
  &-textbox-thin::before { content: "\edbc"; }
  &-thermometer-cold-thin::before { content: "\edbd"; }
  &-thermometer-hot-thin::before { content: "\edbe"; }
  &-thermometer-simple-thin::before { content: "\edbf"; }
  &-thermometer-thin::before { content: "\edc0"; }
  &-thumbs-down-thin::before { content: "\edc1"; }
  &-thumbs-up-thin::before { content: "\edc2"; }
  &-ticket-thin::before { content: "\edc3"; }
  &-tiktok-logo-thin::before { content: "\edc4"; }
  &-timer-thin::before { content: "\edc5"; }
  &-toggle-left-thin::before { content: "\edc6"; }
  &-toggle-right-thin::before { content: "\edc7"; }
  &-toilet-paper-thin::before { content: "\edc8"; }
  &-toilet-thin::before { content: "\edc9"; }
  &-tote-simple-thin::before { content: "\edca"; }
  &-tote-thin::before { content: "\edcb"; }
  &-trademark-registered-thin::before { content: "\edcc"; }
  &-traffic-cone-thin::before { content: "\edcd"; }
  &-traffic-sign-thin::before { content: "\edce"; }
  &-traffic-signal-thin::before { content: "\edcf"; }
  &-train-regional-thin::before { content: "\edd0"; }
  &-train-simple-thin::before { content: "\edd1"; }
  &-train-thin::before { content: "\edd2"; }
  &-translate-thin::before { content: "\edd3"; }
  &-trash-simple-thin::before { content: "\edd4"; }
  &-trash-thin::before { content: "\edd5"; }
  &-tray-thin::before { content: "\edd6"; }
  &-tree-evergreen-thin::before { content: "\edd7"; }
  &-tree-structure-thin::before { content: "\edd8"; }
  &-tree-thin::before { content: "\edd9"; }
  &-trend-down-thin::before { content: "\edda"; }
  &-trend-up-thin::before { content: "\eddb"; }
  &-triangle-thin::before { content: "\eddc"; }
  &-trophy-thin::before { content: "\eddd"; }
  &-truck-thin::before { content: "\edde"; }
  &-twitch-logo-thin::before { content: "\eddf"; }
  &-twitter-logo-thin::before { content: "\ede0"; }
  &-umbrella-simple-thin::before { content: "\ede1"; }
  &-umbrella-thin::before { content: "\ede2"; }
  &-upload-simple-thin::before { content: "\ede3"; }
  &-upload-thin::before { content: "\ede4"; }
  &-user-circle-gear-thin::before { content: "\ede5"; }
  &-user-circle-minus-thin::before { content: "\ede6"; }
  &-user-circle-plus-thin::before { content: "\ede7"; }
  &-user-circle-thin::before { content: "\ede8"; }
  &-user-focus-thin::before { content: "\ede9"; }
  &-user-gear-thin::before { content: "\edea"; }
  &-user-list-thin::before { content: "\edeb"; }
  &-user-minus-thin::before { content: "\edec"; }
  &-user-plus-thin::before { content: "\eded"; }
  &-user-rectangle-thin::before { content: "\edee"; }
  &-user-square-thin::before { content: "\edef"; }
  &-user-switch-thin::before { content: "\edf0"; }
  &-user-thin::before { content: "\edf1"; }
  &-users-four-thin::before { content: "\edf2"; }
  &-users-thin::before { content: "\edf3"; }
  &-users-three-thin::before { content: "\edf4"; }
  &-vault-thin::before { content: "\edf5"; }
  &-vibrate-thin::before { content: "\edf6"; }
  &-video-camera-slash-thin::before { content: "\edf7"; }
  &-video-camera-thin::before { content: "\edf8"; }
  &-vignette-thin::before { content: "\edf9"; }
  &-voicemail-thin::before { content: "\edfa"; }
  &-volleyball-thin::before { content: "\edfb"; }
  &-wall-thin::before { content: "\edfc"; }
  &-wallet-thin::before { content: "\edfd"; }
  &-warning-circle-thin::before { content: "\edfe"; }
  &-warning-octagon-thin::before { content: "\edff"; }
  &-warning-thin::before { content: "\ee00"; }
  &-watch-thin::before { content: "\ee01"; }
  &-wave-sawtooth-thin::before { content: "\ee02"; }
  &-wave-sine-thin::before { content: "\ee03"; }
  &-wave-square-thin::before { content: "\ee04"; }
  &-wave-triangle-thin::before { content: "\ee05"; }
  &-waves-thin::before { content: "\ee06"; }
  &-webcam-thin::before { content: "\ee07"; }
  &-whatsapp-logo-thin::before { content: "\ee08"; }
  &-wheelchair-thin::before { content: "\ee09"; }
  &-wifi-high-thin::before { content: "\ee0a"; }
  &-wifi-low-thin::before { content: "\ee0b"; }
  &-wifi-medium-thin::before { content: "\ee0c"; }
  &-wifi-none-thin::before { content: "\ee0d"; }
  &-wifi-slash-thin::before { content: "\ee0e"; }
  &-wifi-x-thin::before { content: "\ee0f"; }
  &-wind-thin::before { content: "\ee10"; }
  &-windows-logo-thin::before { content: "\ee11"; }
  &-wine-thin::before { content: "\ee12"; }
  &-wrench-thin::before { content: "\ee13"; }
  &-x-circle-thin::before { content: "\ee14"; }
  &-x-square-thin::before { content: "\ee15"; }
  &-x-thin::before { content: "\ee16"; }
  &-yin-yang-thin::before { content: "\ee17"; }
  &-youtube-logo-thin::before { content: "\ee18"; }

  /*
  &-activity-bold::before { content: "\f647"; }
  &-address-book-bold::before { content: "\f648"; }
  &-airplane-bold::before { content: "\f649"; }
  &-airplane-in-flight-bold::before { content: "\f64a"; }
  &-airplane-landing-bold::before { content: "\f64b"; }
  &-airplane-takeoff-bold::before { content: "\f64c"; }
  &-airplane-tilt-bold::before { content: "\f64d"; }
  &-airplay-bold::before { content: "\f64e"; }
  &-alarm-bold::before { content: "\f64f"; }
  &-alien-bold::before { content: "\f650"; }
  &-align-bottom-bold::before { content: "\f651"; }
  &-align-bottom-simple-bold::before { content: "\f652"; }
  &-align-center-horizontal-bold::before { content: "\f653"; }
  &-align-center-horizontal-simple-bold::before { content: "\f654"; }
  &-align-center-vertical-bold::before { content: "\f655"; }
  &-align-center-vertical-simple-bold::before { content: "\f656"; }
  &-align-left-bold::before { content: "\f657"; }
  &-align-left-simple-bold::before { content: "\f658"; }
  &-align-right-bold::before { content: "\f659"; }
  &-align-right-simple-bold::before { content: "\f65a"; }
  &-align-top-bold::before { content: "\f65b"; }
  &-align-top-simple-bold::before { content: "\f65c"; }
  &-anchor-bold::before { content: "\f65d"; }
  &-anchor-simple-bold::before { content: "\f65e"; }
  &-android-logo-bold::before { content: "\f65f"; }
  &-angular-logo-bold::before { content: "\f660"; }
  &-aperture-bold::before { content: "\f661"; }
  &-app-store-logo-bold::before { content: "\f662"; }
  &-app-window-bold::before { content: "\f663"; }
  &-apple-logo-bold::before { content: "\f664"; }
  &-apple-podcasts-logo-bold::before { content: "\f665"; }
  &-archive-bold::before { content: "\f666"; }
  &-archive-box-bold::before { content: "\f667"; }
  &-archive-tray-bold::before { content: "\f668"; }
  &-armchair-bold::before { content: "\f669"; }
  &-arrow-arc-left-bold::before { content: "\f66a"; }
  &-arrow-arc-right-bold::before { content: "\f66b"; }
  &-arrow-bend-double-up-left-bold::before { content: "\f66c"; }
  &-arrow-bend-double-up-right-bold::before { content: "\f66d"; }
  &-arrow-bend-down-left-bold::before { content: "\f66e"; }
  &-arrow-bend-down-right-bold::before { content: "\f66f"; }
  &-arrow-bend-left-down-bold::before { content: "\f670"; }
  &-arrow-bend-left-up-bold::before { content: "\f671"; }
  &-arrow-bend-right-down-bold::before { content: "\f672"; }
  &-arrow-bend-right-up-bold::before { content: "\f673"; }
  &-arrow-bend-up-left-bold::before { content: "\f674"; }
  &-arrow-bend-up-right-bold::before { content: "\f675"; }
  &-arrow-circle-down-bold::before { content: "\f676"; }
  &-arrow-circle-down-left-bold::before { content: "\f677"; }
  &-arrow-circle-down-right-bold::before { content: "\f678"; }
  &-arrow-circle-left-bold::before { content: "\f679"; }
  &-arrow-circle-right-bold::before { content: "\f67a"; }
  &-arrow-circle-up-bold::before { content: "\f67b"; }
  &-arrow-circle-up-left-bold::before { content: "\f67c"; }
  &-arrow-circle-up-right-bold::before { content: "\f67d"; }
  &-arrow-clockwise-bold::before { content: "\f67e"; }
  &-arrow-counter-clockwise-bold::before { content: "\f67f"; }
  &-arrow-down-bold::before { content: "\f680"; }
  &-arrow-down-left-bold::before { content: "\f681"; }
  &-arrow-down-right-bold::before { content: "\f682"; }
  &-arrow-elbow-down-left-bold::before { content: "\f683"; }
  &-arrow-elbow-down-right-bold::before { content: "\f684"; }
  &-arrow-elbow-left-bold::before { content: "\f685"; }
  &-arrow-elbow-left-down-bold::before { content: "\f686"; }
  &-arrow-elbow-left-up-bold::before { content: "\f687"; }
  &-arrow-elbow-right-bold::before { content: "\f688"; }
  &-arrow-elbow-right-down-bold::before { content: "\f689"; }
  &-arrow-elbow-right-up-bold::before { content: "\f68a"; }
  &-arrow-elbow-up-left-bold::before { content: "\f68b"; }
  &-arrow-elbow-up-right-bold::before { content: "\f68c"; }
  &-arrow-fat-down-bold::before { content: "\f68d"; }
  &-arrow-fat-left-bold::before { content: "\f68e"; }
  &-arrow-fat-line-down-bold::before { content: "\f68f"; }
  &-arrow-fat-line-left-bold::before { content: "\f690"; }
  &-arrow-fat-line-right-bold::before { content: "\f691"; }
  &-arrow-fat-line-up-bold::before { content: "\f692"; }
  &-arrow-fat-lines-down-bold::before { content: "\f693"; }
  &-arrow-fat-lines-left-bold::before { content: "\f694"; }
  &-arrow-fat-lines-right-bold::before { content: "\f695"; }
  &-arrow-fat-lines-up-bold::before { content: "\f696"; }
  &-arrow-fat-right-bold::before { content: "\f697"; }
  &-arrow-fat-up-bold::before { content: "\f698"; }
  &-arrow-left-bold::before { content: "\f699"; }
  &-arrow-line-down-bold::before { content: "\f69a"; }
  &-arrow-line-down-left-bold::before { content: "\f69b"; }
  &-arrow-line-down-right-bold::before { content: "\f69c"; }
  &-arrow-line-left-bold::before { content: "\f69d"; }
  &-arrow-line-right-bold::before { content: "\f69e"; }
  &-arrow-line-up-bold::before { content: "\f69f"; }
  &-arrow-line-up-left-bold::before { content: "\f6a0"; }
  &-arrow-line-up-right-bold::before { content: "\f6a1"; }
  &-arrow-right-bold::before { content: "\f6a2"; }
  &-arrow-square-down-bold::before { content: "\f6a3"; }
  &-arrow-square-down-left-bold::before { content: "\f6a4"; }
  &-arrow-square-down-right-bold::before { content: "\f6a5"; }
  &-arrow-square-in-bold::before { content: "\f6a6"; }
  &-arrow-square-left-bold::before { content: "\f6a7"; }
  &-arrow-square-out-bold::before { content: "\f6a8"; }
  &-arrow-square-right-bold::before { content: "\f6a9"; }
  &-arrow-square-up-bold::before { content: "\f6aa"; }
  &-arrow-square-up-left-bold::before { content: "\f6ab"; }
  &-arrow-square-up-right-bold::before { content: "\f6ac"; }
  &-arrow-u-down-left-bold::before { content: "\f6ad"; }
  &-arrow-u-down-right-bold::before { content: "\f6ae"; }
  &-arrow-u-left-down-bold::before { content: "\f6af"; }
  &-arrow-u-left-up-bold::before { content: "\f6b0"; }
  &-arrow-u-right-down-bold::before { content: "\f6b1"; }
  &-arrow-u-right-up-bold::before { content: "\f6b2"; }
  &-arrow-u-up-left-bold::before { content: "\f6b3"; }
  &-arrow-u-up-right-bold::before { content: "\f6b4"; }
  &-arrow-up-bold::before { content: "\f6b5"; }
  &-arrow-up-left-bold::before { content: "\f6b6"; }
  &-arrow-up-right-bold::before { content: "\f6b7"; }
  &-arrows-clockwise-bold::before { content: "\f6b8"; }
  &-arrows-counter-clockwise-bold::before { content: "\f6b9"; }
  &-arrows-down-up-bold::before { content: "\f6ba"; }
  &-arrows-horizontal-bold::before { content: "\f6bb"; }
  &-arrows-in-bold::before { content: "\f6bc"; }
  &-arrows-in-cardinal-bold::before { content: "\f6bd"; }
  &-arrows-in-line-horizontal-bold::before { content: "\f6be"; }
  &-arrows-in-line-vertical-bold::before { content: "\f6bf"; }
  &-arrows-in-simple-bold::before { content: "\f6c0"; }
  &-arrows-left-right-bold::before { content: "\f6c1"; }
  &-arrows-out-bold::before { content: "\f6c2"; }
  &-arrows-out-cardinal-bold::before { content: "\f6c3"; }
  &-arrows-out-line-horizontal-bold::before { content: "\f6c4"; }
  &-arrows-out-line-vertical-bold::before { content: "\f6c5"; }
  &-arrows-out-simple-bold::before { content: "\f6c6"; }
  &-arrows-vertical-bold::before { content: "\f6c7"; }
  &-article-bold::before { content: "\f6c8"; }
  &-article-medium-bold::before { content: "\f6c9"; }
  &-article-ny-times-bold::before { content: "\f6ca"; }
  &-asterisk-bold::before { content: "\f6cb"; }
  &-asterisk-simple-bold::before { content: "\f6cc"; }
  &-at-bold::before { content: "\f6cd"; }
  &-atom-bold::before { content: "\f6ce"; }
  &-baby-bold::before { content: "\f6cf"; }
  &-backpack-bold::before { content: "\f6d0"; }
  &-backspace-bold::before { content: "\f6d1"; }
  &-bag-bold::before { content: "\f6d2"; }
  &-bag-simple-bold::before { content: "\f6d3"; }
  &-balloon-bold::before { content: "\f6d4"; }
  &-bandaids-bold::before { content: "\f6d5"; }
  &-bank-bold::before { content: "\f6d6"; }
  &-barbell-bold::before { content: "\f6d7"; }
  &-barcode-bold::before { content: "\f6d8"; }
  &-barricade-bold::before { content: "\f6d9"; }
  &-baseball-bold::before { content: "\f6da"; }
  &-basketball-bold::before { content: "\f6db"; }
  &-bathtub-bold::before { content: "\f6dc"; }
  &-battery-charging-bold::before { content: "\f6dd"; }
  &-battery-charging-vertical-bold::before { content: "\f6de"; }
  &-battery-empty-bold::before { content: "\f6df"; }
  &-battery-full-bold::before { content: "\f6e0"; }
  &-battery-high-bold::before { content: "\f6e1"; }
  &-battery-low-bold::before { content: "\f6e2"; }
  &-battery-medium-bold::before { content: "\f6e3"; }
  &-battery-plus-bold::before { content: "\f6e4"; }
  &-battery-warning-bold::before { content: "\f6e5"; }
  &-battery-warning-vertical-bold::before { content: "\f6e6"; }
  &-bed-bold::before { content: "\f6e7"; }
  &-beer-bottle-bold::before { content: "\f6e8"; }
  &-behance-logo-bold::before { content: "\f6e9"; }
  &-bell-bold::before { content: "\f6ea"; }
  &-bell-ringing-bold::before { content: "\f6eb"; }
  &-bell-simple-bold::before { content: "\f6ec"; }
  &-bell-simple-ringing-bold::before { content: "\f6ed"; }
  &-bell-simple-slash-bold::before { content: "\f6ee"; }
  &-bell-simple-z-bold::before { content: "\f6ef"; }
  &-bell-slash-bold::before { content: "\f6f0"; }
  &-bell-z-bold::before { content: "\f6f1"; }
  &-bezier-curve-bold::before { content: "\f6f2"; }
  &-bicycle-bold::before { content: "\f6f3"; }
  &-binoculars-bold::before { content: "\f6f4"; }
  &-bird-bold::before { content: "\f6f5"; }
  &-bluetooth-bold::before { content: "\f6f6"; }
  &-bluetooth-connected-bold::before { content: "\f6f7"; }
  &-bluetooth-slash-bold::before { content: "\f6f8"; }
  &-bluetooth-x-bold::before { content: "\f6f9"; }
  &-boat-bold::before { content: "\f6fa"; }
  &-book-bold::before { content: "\f6fb"; }
  &-book-bookmark-bold::before { content: "\f6fc"; }
  &-book-open-bold::before { content: "\f6fd"; }
  &-bookmark-bold::before { content: "\f6fe"; }
  &-bookmark-simple-bold::before { content: "\f6ff"; }
  &-bookmarks-bold::before { content: "\f700"; }
  &-bookmarks-simple-bold::before { content: "\f701"; }
  &-books-bold::before { content: "\f702"; }
  &-bounding-box-bold::before { content: "\f703"; }
  &-brackets-angle-bold::before { content: "\f704"; }
  &-brackets-curly-bold::before { content: "\f705"; }
  &-brackets-round-bold::before { content: "\f706"; }
  &-brackets-square-bold::before { content: "\f707"; }
  &-brain-bold::before { content: "\f708"; }
  &-brandy-bold::before { content: "\f709"; }
  &-briefcase-bold::before { content: "\f70a"; }
  &-briefcase-metal-bold::before { content: "\f70b"; }
  &-broadcast-bold::before { content: "\f70c"; }
  &-browser-bold::before { content: "\f70d"; }
  &-browsers-bold::before { content: "\f70e"; }
  &-bug-beetle-bold::before { content: "\f70f"; }
  &-bug-bold::before { content: "\f710"; }
  &-bug-droid-bold::before { content: "\f711"; }
  &-buildings-bold::before { content: "\f712"; }
  &-bus-bold::before { content: "\f713"; }
  &-butterfly-bold::before { content: "\f714"; }
  &-cactus-bold::before { content: "\f715"; }
  &-cake-bold::before { content: "\f716"; }
  &-calculator-bold::before { content: "\f717"; }
  &-calendar-blank-bold::before { content: "\f718"; }
  &-calendar-bold::before { content: "\f719"; }
  &-calendar-check-bold::before { content: "\f71a"; }
  &-calendar-plus-bold::before { content: "\f71b"; }
  &-calendar-x-bold::before { content: "\f71c"; }
  &-camera-bold::before { content: "\f71d"; }
  &-camera-rotate-bold::before { content: "\f71e"; }
  &-camera-slash-bold::before { content: "\f71f"; }
  &-campfire-bold::before { content: "\f720"; }
  &-car-bold::before { content: "\f721"; }
  &-car-simple-bold::before { content: "\f722"; }
  &-cardholder-bold::before { content: "\f723"; }
  &-cards-bold::before { content: "\f724"; }
  &-caret-circle-double-down-bold::before { content: "\f725"; }
  &-caret-circle-double-left-bold::before { content: "\f726"; }
  &-caret-circle-double-right-bold::before { content: "\f727"; }
  &-caret-circle-double-up-bold::before { content: "\f728"; }
  &-caret-circle-down-bold::before { content: "\f729"; }
  &-caret-circle-left-bold::before { content: "\f72a"; }
  &-caret-circle-right-bold::before { content: "\f72b"; }
  &-caret-circle-up-bold::before { content: "\f72c"; }
  &-caret-double-down-bold::before { content: "\f72d"; }
  &-caret-double-left-bold::before { content: "\f72e"; }
  &-caret-double-right-bold::before { content: "\f72f"; }
  &-caret-double-up-bold::before { content: "\f730"; }
  &-caret-down-bold::before { content: "\f731"; }
  &-caret-left-bold::before { content: "\f732"; }
  &-caret-right-bold::before { content: "\f733"; }
  &-caret-up-bold::before { content: "\f734"; }
  &-cat-bold::before { content: "\f735"; }
  &-cell-signal-full-bold::before { content: "\f736"; }
  &-cell-signal-high-bold::before { content: "\f737"; }
  &-cell-signal-low-bold::before { content: "\f738"; }
  &-cell-signal-medium-bold::before { content: "\f739"; }
  &-cell-signal-none-bold::before { content: "\f73a"; }
  &-cell-signal-slash-bold::before { content: "\f73b"; }
  &-cell-signal-x-bold::before { content: "\f73c"; }
  &-chalkboard-bold::before { content: "\f73d"; }
  &-chalkboard-simple-bold::before { content: "\f73e"; }
  &-chalkboard-teacher-bold::before { content: "\f73f"; }
  &-chart-bar-bold::before { content: "\f740"; }
  &-chart-bar-horizontal-bold::before { content: "\f741"; }
  &-chart-line-bold::before { content: "\f742"; }
  &-chart-line-up-bold::before { content: "\f743"; }
  &-chart-pie-bold::before { content: "\f744"; }
  &-chart-pie-slice-bold::before { content: "\f745"; }
  &-chat-bold::before { content: "\f746"; }
  &-chat-centered-bold::before { content: "\f747"; }
  &-chat-centered-dots-bold::before { content: "\f748"; }
  &-chat-centered-text-bold::before { content: "\f749"; }
  &-chat-circle-bold::before { content: "\f74a"; }
  &-chat-circle-dots-bold::before { content: "\f74b"; }
  &-chat-circle-text-bold::before { content: "\f74c"; }
  &-chat-dots-bold::before { content: "\f74d"; }
  &-chat-teardrop-bold::before { content: "\f74e"; }
  &-chat-teardrop-dots-bold::before { content: "\f74f"; }
  &-chat-teardrop-text-bold::before { content: "\f750"; }
  &-chat-text-bold::before { content: "\f751"; }
  &-chats-bold::before { content: "\f752"; }
  &-chats-circle-bold::before { content: "\f753"; }
  &-chats-teardrop-bold::before { content: "\f754"; }
  &-check-bold::before { content: "\f755"; }
  &-check-circle-bold::before { content: "\f756"; }
  &-check-square-bold::before { content: "\f757"; }
  &-check-square-offset-bold::before { content: "\f758"; }
  &-checks-bold::before { content: "\f759"; }
  &-circle-bold::before { content: "\f75a"; }
  &-circle-dashed-bold::before { content: "\f75b"; }
  &-circle-half-bold::before { content: "\f75c"; }
  &-circle-half-tilt-bold::before { content: "\f75d"; }
  &-circle-notch-bold::before { content: "\f75e"; }
  &-circle-wavy-bold::before { content: "\f75f"; }
  &-circle-wavy-check-bold::before { content: "\f760"; }
  &-circle-wavy-question-bold::before { content: "\f761"; }
  &-circle-wavy-warning-bold::before { content: "\f762"; }
  &-circles-four-bold::before { content: "\f763"; }
  &-circles-three-bold::before { content: "\f764"; }
  &-circles-three-plus-bold::before { content: "\f765"; }
  &-clipboard-bold::before { content: "\f766"; }
  &-clipboard-text-bold::before { content: "\f767"; }
  &-clock-afternoon-bold::before { content: "\f768"; }
  &-clock-bold::before { content: "\f769"; }
  &-clock-clockwise-bold::before { content: "\f76a"; }
  &-clock-counter-clockwise-bold::before { content: "\f76b"; }
  &-closed-captioning-bold::before { content: "\f76c"; }
  &-cloud-arrow-down-bold::before { content: "\f76d"; }
  &-cloud-arrow-up-bold::before { content: "\f76e"; }
  &-cloud-bold::before { content: "\f76f"; }
  &-cloud-check-bold::before { content: "\f770"; }
  &-cloud-fog-bold::before { content: "\f771"; }
  &-cloud-lightning-bold::before { content: "\f772"; }
  &-cloud-moon-bold::before { content: "\f773"; }
  &-cloud-rain-bold::before { content: "\f774"; }
  &-cloud-slash-bold::before { content: "\f775"; }
  &-cloud-snow-bold::before { content: "\f776"; }
  &-cloud-sun-bold::before { content: "\f777"; }
  &-club-bold::before { content: "\f778"; }
  &-coat-hanger-bold::before { content: "\f779"; }
  &-code-bold::before { content: "\f77a"; }
  &-code-simple-bold::before { content: "\f77b"; }
  &-codepen-logo-bold::before { content: "\f77c"; }
  &-codesandbox-logo-bold::before { content: "\f77d"; }
  &-coffee-bold::before { content: "\f77e"; }
  &-coin-bold::before { content: "\f77f"; }
  &-coin-vertical-bold::before { content: "\f780"; }
  &-coins-bold::before { content: "\f781"; }
  &-columns-bold::before { content: "\f782"; }
  &-command-bold::before { content: "\f783"; }
  &-compass-bold::before { content: "\f784"; }
  &-computer-tower-bold::before { content: "\f785"; }
  &-confetti-bold::before { content: "\f786"; }
  &-cookie-bold::before { content: "\f787"; }
  &-cooking-pot-bold::before { content: "\f788"; }
  &-copy-bold::before { content: "\f789"; }
  &-copy-simple-bold::before { content: "\f78a"; }
  &-copyleft-bold::before { content: "\f78b"; }
  &-copyright-bold::before { content: "\f78c"; }
  &-corners-in-bold::before { content: "\f78d"; }
  &-corners-out-bold::before { content: "\f78e"; }
  &-cpu-bold::before { content: "\f78f"; }
  &-credit-card-bold::before { content: "\f790"; }
  &-crop-bold::before { content: "\f791"; }
  &-crosshair-bold::before { content: "\f792"; }
  &-crosshair-simple-bold::before { content: "\f793"; }
  &-crown-bold::before { content: "\f794"; }
  &-crown-simple-bold::before { content: "\f795"; }
  &-cube-bold::before { content: "\f796"; }
  &-currency-btc-bold::before { content: "\f797"; }
  &-currency-circle-dollar-bold::before { content: "\f798"; }
  &-currency-cny-bold::before { content: "\f799"; }
  &-currency-dollar-bold::before { content: "\f79a"; }
  &-currency-dollar-simple-bold::before { content: "\f79b"; }
  &-currency-eth-bold::before { content: "\f79c"; }
  &-currency-eur-bold::before { content: "\f79d"; }
  &-currency-gbp-bold::before { content: "\f79e"; }
  &-currency-inr-bold::before { content: "\f79f"; }
  &-currency-jpy-bold::before { content: "\f7a0"; }
  &-currency-krw-bold::before { content: "\f7a1"; }
  &-currency-kzt-bold::before { content: "\f7a2"; }
  &-currency-ngn-bold::before { content: "\f7a3"; }
  &-currency-rub-bold::before { content: "\f7a4"; }
  &-cursor-bold::before { content: "\f7a5"; }
  &-cursor-text-bold::before { content: "\f7a6"; }
  &-cylinder-bold::before { content: "\f7a7"; }
  &-database-bold::before { content: "\f7a8"; }
  &-desktop-bold::before { content: "\f7a9"; }
  &-desktop-tower-bold::before { content: "\f7aa"; }
  &-detective-bold::before { content: "\f7ab"; }
  &-device-mobile-bold::before { content: "\f7ac"; }
  &-device-mobile-camera-bold::before { content: "\f7ad"; }
  &-device-mobile-speaker-bold::before { content: "\f7ae"; }
  &-device-tablet-bold::before { content: "\f7af"; }
  &-device-tablet-camera-bold::before { content: "\f7b0"; }
  &-device-tablet-speaker-bold::before { content: "\f7b1"; }
  &-diamond-bold::before { content: "\f7b2"; }
  &-diamonds-four-bold::before { content: "\f7b3"; }
  &-dice-five-bold::before { content: "\f7b4"; }
  &-dice-four-bold::before { content: "\f7b5"; }
  &-dice-one-bold::before { content: "\f7b6"; }
  &-dice-six-bold::before { content: "\f7b7"; }
  &-dice-three-bold::before { content: "\f7b8"; }
  &-dice-two-bold::before { content: "\f7b9"; }
  &-disc-bold::before { content: "\f7ba"; }
  &-discord-logo-bold::before { content: "\f7bb"; }
  &-divide-bold::before { content: "\f7bc"; }
  &-dog-bold::before { content: "\f7bd"; }
  &-door-bold::before { content: "\f7be"; }
  &-dots-nine-bold::before { content: "\f7bf"; }
  &-dots-six-bold::before { content: "\f7c0"; }
  &-dots-six-vertical-bold::before { content: "\f7c1"; }
  &-dots-three-bold::before { content: "\f7c2"; }
  &-dots-three-circle-bold::before { content: "\f7c3"; }
  &-dots-three-circle-vertical-bold::before { content: "\f7c4"; }
  &-dots-three-outline-bold::before { content: "\f7c5"; }
  &-dots-three-outline-vertical-bold::before { content: "\f7c6"; }
  &-dots-three-vertical-bold::before { content: "\f7c7"; }
  &-download-bold::before { content: "\f7c8"; }
  &-download-simple-bold::before { content: "\f7c9"; }
  &-dribbble-logo-bold::before { content: "\f7ca"; }
  &-drop-bold::before { content: "\f7cb"; }
  &-drop-half-bold::before { content: "\f7cc"; }
  &-drop-half-bottom-bold::before { content: "\f7cd"; }
  &-ear-bold::before { content: "\f7ce"; }
  &-ear-slash-bold::before { content: "\f7cf"; }
  &-egg-bold::before { content: "\f7d0"; }
  &-egg-crack-bold::before { content: "\f7d1"; }
  &-eject-bold::before { content: "\f7d2"; }
  &-eject-simple-bold::before { content: "\f7d3"; }
  &-envelope-bold::before { content: "\f7d4"; }
  &-envelope-open-bold::before { content: "\f7d5"; }
  &-envelope-simple-bold::before { content: "\f7d6"; }
  &-envelope-simple-open-bold::before { content: "\f7d7"; }
  &-equalizer-bold::before { content: "\f7d8"; }
  &-equals-bold::before { content: "\f7d9"; }
  &-eraser-bold::before { content: "\f7da"; }
  &-exam-bold::before { content: "\f7db"; }
  &-export-bold::before { content: "\f7dc"; }
  &-eye-bold::before { content: "\f7dd"; }
  &-eye-closed-bold::before { content: "\f7de"; }
  &-eye-slash-bold::before { content: "\f7df"; }
  &-eyedropper-bold::before { content: "\f7e0"; }
  &-eyedropper-sample-bold::before { content: "\f7e1"; }
  &-eyeglasses-bold::before { content: "\f7e2"; }
  &-face-mask-bold::before { content: "\f7e3"; }
  &-facebook-logo-bold::before { content: "\f7e4"; }
  &-factory-bold::before { content: "\f7e5"; }
  &-faders-bold::before { content: "\f7e6"; }
  &-faders-horizontal-bold::before { content: "\f7e7"; }
  &-fast-forward-bold::before { content: "\f7e8"; }
  &-fast-forward-circle-bold::before { content: "\f7e9"; }
  &-figma-logo-bold::before { content: "\f7ea"; }
  &-file-arrow-down-bold::before { content: "\f7eb"; }
  &-file-arrow-up-bold::before { content: "\f7ec"; }
  &-file-audio-bold::before { content: "\f7ed"; }
  &-file-bold::before { content: "\f7ee"; }
  &-file-cloud-bold::before { content: "\f7ef"; }
  &-file-code-bold::before { content: "\f7f0"; }
  &-file-css-bold::before { content: "\f7f1"; }
  &-file-csv-bold::before { content: "\f7f2"; }
  &-file-doc-bold::before { content: "\f7f3"; }
  &-file-dotted-bold::before { content: "\f7f4"; }
  &-file-html-bold::before { content: "\f7f5"; }
  &-file-image-bold::before { content: "\f7f6"; }
  &-file-jpg-bold::before { content: "\f7f7"; }
  &-file-js-bold::before { content: "\f7f8"; }
  &-file-jsx-bold::before { content: "\f7f9"; }
  &-file-lock-bold::before { content: "\f7fa"; }
  &-file-minus-bold::before { content: "\f7fb"; }
  &-file-pdf-bold::before { content: "\f7fc"; }
  &-file-plus-bold::before { content: "\f7fd"; }
  &-file-png-bold::before { content: "\f7fe"; }
  &-file-ppt-bold::before { content: "\f7ff"; }
  &-file-rs-bold::before { content: "\f800"; }
  &-file-search-bold::before { content: "\f801"; }
  &-file-text-bold::before { content: "\f802"; }
  &-file-ts-bold::before { content: "\f803"; }
  &-file-tsx-bold::before { content: "\f804"; }
  &-file-video-bold::before { content: "\f805"; }
  &-file-vue-bold::before { content: "\f806"; }
  &-file-x-bold::before { content: "\f807"; }
  &-file-xls-bold::before { content: "\f808"; }
  &-file-zip-bold::before { content: "\f809"; }
  &-files-bold::before { content: "\f80a"; }
  &-film-script-bold::before { content: "\f80b"; }
  &-film-slate-bold::before { content: "\f80c"; }
  &-film-strip-bold::before { content: "\f80d"; }
  &-fingerprint-bold::before { content: "\f80e"; }
  &-fingerprint-simple-bold::before { content: "\f80f"; }
  &-finn-the-human-bold::before { content: "\f810"; }
  &-fire-bold::before { content: "\f811"; }
  &-fire-simple-bold::before { content: "\f812"; }
  &-first-aid-bold::before { content: "\f813"; }
  &-first-aid-kit-bold::before { content: "\f814"; }
  &-fish-bold::before { content: "\f815"; }
  &-fish-simple-bold::before { content: "\f816"; }
  &-flag-banner-bold::before { content: "\f817"; }
  &-flag-bold::before { content: "\f818"; }
  &-flag-checkered-bold::before { content: "\f819"; }
  &-flame-bold::before { content: "\f81a"; }
  &-flashlight-bold::before { content: "\f81b"; }
  &-flask-bold::before { content: "\f81c"; }
  &-floppy-disk-back-bold::before { content: "\f81d"; }
  &-floppy-disk-bold::before { content: "\f81e"; }
  &-flow-arrow-bold::before { content: "\f81f"; }
  &-flower-bold::before { content: "\f820"; }
  &-flower-lotus-bold::before { content: "\f821"; }
  &-flying-saucer-bold::before { content: "\f822"; }
  &-folder-bold::before { content: "\f823"; }
  &-folder-dotted-bold::before { content: "\f824"; }
  &-folder-lock-bold::before { content: "\f825"; }
  &-folder-minus-bold::before { content: "\f826"; }
  &-folder-notch-bold::before { content: "\f827"; }
  &-folder-notch-minus-bold::before { content: "\f828"; }
  &-folder-notch-open-bold::before { content: "\f829"; }
  &-folder-notch-plus-bold::before { content: "\f82a"; }
  &-folder-open-bold::before { content: "\f82b"; }
  &-folder-plus-bold::before { content: "\f82c"; }
  &-folder-simple-bold::before { content: "\f82d"; }
  &-folder-simple-dotted-bold::before { content: "\f82e"; }
  &-folder-simple-lock-bold::before { content: "\f82f"; }
  &-folder-simple-minus-bold::before { content: "\f830"; }
  &-folder-simple-plus-bold::before { content: "\f831"; }
  &-folder-simple-star-bold::before { content: "\f832"; }
  &-folder-simple-user-bold::before { content: "\f833"; }
  &-folder-star-bold::before { content: "\f834"; }
  &-folder-user-bold::before { content: "\f835"; }
  &-folders-bold::before { content: "\f836"; }
  &-football-bold::before { content: "\f837"; }
  &-fork-knife-bold::before { content: "\f838"; }
  &-frame-corners-bold::before { content: "\f839"; }
  &-framer-logo-bold::before { content: "\f83a"; }
  &-function-bold::before { content: "\f83b"; }
  &-funnel-bold::before { content: "\f83c"; }
  &-funnel-simple-bold::before { content: "\f83d"; }
  &-game-controller-bold::before { content: "\f83e"; }
  &-gas-pump-bold::before { content: "\f83f"; }
  &-gauge-bold::before { content: "\f840"; }
  &-gear-bold::before { content: "\f841"; }
  &-gear-six-bold::before { content: "\f842"; }
  &-gender-female-bold::before { content: "\f843"; }
  &-gender-intersex-bold::before { content: "\f844"; }
  &-gender-male-bold::before { content: "\f845"; }
  &-gender-neuter-bold::before { content: "\f846"; }
  &-gender-nonbinary-bold::before { content: "\f847"; }
  &-gender-transgender-bold::before { content: "\f848"; }
  &-ghost-bold::before { content: "\f849"; }
  &-gif-bold::before { content: "\f84a"; }
  &-gift-bold::before { content: "\f84b"; }
  &-git-branch-bold::before { content: "\f84c"; }
  &-git-commit-bold::before { content: "\f84d"; }
  &-git-diff-bold::before { content: "\f84e"; }
  &-git-fork-bold::before { content: "\f84f"; }
  &-git-merge-bold::before { content: "\f850"; }
  &-git-pull-request-bold::before { content: "\f851"; }
  &-github-logo-bold::before { content: "\f852"; }
  &-gitlab-logo-bold::before { content: "\f853"; }
  &-gitlab-logo-simple-bold::before { content: "\f854"; }
  &-globe-bold::before { content: "\f855"; }
  &-globe-hemisphere-east-bold::before { content: "\f856"; }
  &-globe-hemisphere-west-bold::before { content: "\f857"; }
  &-globe-simple-bold::before { content: "\f858"; }
  &-globe-stand-bold::before { content: "\f859"; }
  &-google-chrome-logo-bold::before { content: "\f85a"; }
  &-google-logo-bold::before { content: "\f85b"; }
  &-google-photos-logo-bold::before { content: "\f85c"; }
  &-google-play-logo-bold::before { content: "\f85d"; }
  &-google-podcasts-logo-bold::before { content: "\f85e"; }
  &-gradient-bold::before { content: "\f85f"; }
  &-graduation-cap-bold::before { content: "\f860"; }
  &-graph-bold::before { content: "\f861"; }
  &-grid-four-bold::before { content: "\f862"; }
  &-hamburger-bold::before { content: "\f863"; }
  &-hand-bold::before { content: "\f864"; }
  &-hand-eye-bold::before { content: "\f865"; }
  &-hand-fist-bold::before { content: "\f866"; }
  &-hand-grabbing-bold::before { content: "\f867"; }
  &-hand-palm-bold::before { content: "\f868"; }
  &-hand-pointing-bold::before { content: "\f869"; }
  &-hand-soap-bold::before { content: "\f86a"; }
  &-hand-waving-bold::before { content: "\f86b"; }
  &-handbag-bold::before { content: "\f86c"; }
  &-handbag-simple-bold::before { content: "\f86d"; }
  &-hands-clapping-bold::before { content: "\f86e"; }
  &-handshake-bold::before { content: "\f86f"; }
  &-hard-drive-bold::before { content: "\f870"; }
  &-hard-drives-bold::before { content: "\f871"; }
  &-hash-bold::before { content: "\f872"; }
  &-hash-straight-bold::before { content: "\f873"; }
  &-headlights-bold::before { content: "\f874"; }
  &-headphones-bold::before { content: "\f875"; }
  &-headset-bold::before { content: "\f876"; }
  &-heart-bold::before { content: "\f877"; }
  &-heart-break-bold::before { content: "\f878"; }
  &-heart-straight-bold::before { content: "\f879"; }
  &-heart-straight-break-bold::before { content: "\f87a"; }
  &-heartbeat-bold::before { content: "\f87b"; }
  &-hexagon-bold::before { content: "\f87c"; }
  &-highlighter-circle-bold::before { content: "\f87d"; }
  &-horse-bold::before { content: "\f87e"; }
  &-hourglass-bold::before { content: "\f87f"; }
  &-hourglass-high-bold::before { content: "\f880"; }
  &-hourglass-low-bold::before { content: "\f881"; }
  &-hourglass-medium-bold::before { content: "\f882"; }
  &-hourglass-simple-bold::before { content: "\f883"; }
  &-hourglass-simple-high-bold::before { content: "\f884"; }
  &-hourglass-simple-low-bold::before { content: "\f885"; }
  &-hourglass-simple-medium-bold::before { content: "\f886"; }
  &-house-bold::before { content: "\f887"; }
  &-house-line-bold::before { content: "\f888"; }
  &-house-simple-bold::before { content: "\f889"; }
  &-identification-badge-bold::before { content: "\f88a"; }
  &-identification-card-bold::before { content: "\f88b"; }
  &-image-bold::before { content: "\f88c"; }
  &-image-square-bold::before { content: "\f88d"; }
  &-infinity-bold::before { content: "\f88e"; }
  &-info-bold::before { content: "\f88f"; }
  &-instagram-logo-bold::before { content: "\f890"; }
  &-intersect-bold::before { content: "\f891"; }
  &-jeep-bold::before { content: "\f892"; }
  &-kanban-bold::before { content: "\f893"; }
  &-key-bold::before { content: "\f894"; }
  &-key-return-bold::before { content: "\f895"; }
  &-keyboard-bold::before { content: "\f896"; }
  &-keyhole-bold::before { content: "\f897"; }
  &-knife-bold::before { content: "\f898"; }
  &-ladder-bold::before { content: "\f899"; }
  &-ladder-simple-bold::before { content: "\f89a"; }
  &-lamp-bold::before { content: "\f89b"; }
  &-laptop-bold::before { content: "\f89c"; }
  &-layout-bold::before { content: "\f89d"; }
  &-leaf-bold::before { content: "\f89e"; }
  &-lifebuoy-bold::before { content: "\f89f"; }
  &-lightbulb-bold::before { content: "\f8a0"; }
  &-lightbulb-filament-bold::before { content: "\f8a1"; }
  &-lightning-bold::before { content: "\f8a2"; }
  &-lightning-slash-bold::before { content: "\f8a3"; }
  &-line-segment-bold::before { content: "\f8a4"; }
  &-line-segments-bold::before { content: "\f8a5"; }
  &-link-bold::before { content: "\f8a6"; }
  &-link-break-bold::before { content: "\f8a7"; }
  &-link-simple-bold::before { content: "\f8a8"; }
  &-link-simple-break-bold::before { content: "\f8a9"; }
  &-link-simple-horizontal-bold::before { content: "\f8aa"; }
  &-link-simple-horizontal-break-bold::before { content: "\f8ab"; }
  &-linkedin-logo-bold::before { content: "\f8ac"; }
  &-linux-logo-bold::before { content: "\f8ad"; }
  &-list-bold::before { content: "\f8ae"; }
  &-list-bullets-bold::before { content: "\f8af"; }
  &-list-checks-bold::before { content: "\f8b0"; }
  &-list-dashes-bold::before { content: "\f8b1"; }
  &-list-numbers-bold::before { content: "\f8b2"; }
  &-list-plus-bold::before { content: "\f8b3"; }
  &-lock-bold::before { content: "\f8b4"; }
  &-lock-key-bold::before { content: "\f8b5"; }
  &-lock-key-open-bold::before { content: "\f8b6"; }
  &-lock-laminated-bold::before { content: "\f8b7"; }
  &-lock-laminated-open-bold::before { content: "\f8b8"; }
  &-lock-open-bold::before { content: "\f8b9"; }
  &-lock-simple-bold::before { content: "\f8ba"; }
  &-lock-simple-open-bold::before { content: "\f8bb"; }
  &-magic-wand-bold::before { content: "\f8bc"; }
  &-magnet-bold::before { content: "\f8bd"; }
  &-magnet-straight-bold::before { content: "\f8be"; }
  &-magnifying-glass-bold::before { content: "\f8bf"; }
  &-magnifying-glass-minus-bold::before { content: "\f8c0"; }
  &-magnifying-glass-plus-bold::before { content: "\f8c1"; }
  &-map-pin-bold::before { content: "\f8c2"; }
  &-map-pin-line-bold::before { content: "\f8c3"; }
  &-map-trifold-bold::before { content: "\f8c4"; }
  &-marker-circle-bold::before { content: "\f8c5"; }
  &-martini-bold::before { content: "\f8c6"; }
  &-mask-happy-bold::before { content: "\f8c7"; }
  &-mask-sad-bold::before { content: "\f8c8"; }
  &-math-operations-bold::before { content: "\f8c9"; }
  &-medal-bold::before { content: "\f8ca"; }
  &-medium-logo-bold::before { content: "\f8cb"; }
  &-megaphone-bold::before { content: "\f8cc"; }
  &-megaphone-simple-bold::before { content: "\f8cd"; }
  &-messenger-logo-bold::before { content: "\f8ce"; }
  &-microphone-bold::before { content: "\f8cf"; }
  &-microphone-slash-bold::before { content: "\f8d0"; }
  &-microphone-stage-bold::before { content: "\f8d1"; }
  &-microsoft-excel-logo-bold::before { content: "\f8d2"; }
  &-microsoft-powerpoint-logo-bold::before { content: "\f8d3"; }
  &-microsoft-teams-logo-bold::before { content: "\f8d4"; }
  &-microsoft-word-logo-bold::before { content: "\f8d5"; }
  &-minus-bold::before { content: "\f8d6"; }
  &-minus-circle-bold::before { content: "\f8d7"; }
  &-money-bold::before { content: "\f8d8"; }
  &-monitor-bold::before { content: "\f8d9"; }
  &-monitor-play-bold::before { content: "\f8da"; }
  &-moon-bold::before { content: "\f8db"; }
  &-moon-stars-bold::before { content: "\f8dc"; }
  &-mountains-bold::before { content: "\f8dd"; }
  &-mouse-bold::before { content: "\f8de"; }
  &-mouse-simple-bold::before { content: "\f8df"; }
  &-music-note-bold::before { content: "\f8e0"; }
  &-music-note-simple-bold::before { content: "\f8e1"; }
  &-music-notes-bold::before { content: "\f8e2"; }
  &-music-notes-plus-bold::before { content: "\f8e3"; }
  &-music-notes-simple-bold::before { content: "\f8e4"; }
  &-navigation-arrow-bold::before { content: "\f8e5"; }
  &-needle-bold::before { content: "\f8e6"; }
  &-newspaper-bold::before { content: "\f8e7"; }
  &-newspaper-clipping-bold::before { content: "\f8e8"; }
  &-note-blank-bold::before { content: "\f8e9"; }
  &-note-bold::before { content: "\f8ea"; }
  &-note-pencil-bold::before { content: "\f8eb"; }
  &-notebook-bold::before { content: "\f8ec"; }
  &-notepad-bold::before { content: "\f8ed"; }
  &-notification-bold::before { content: "\f8ee"; }
  &-number-circle-eight-bold::before { content: "\f8ef"; }
  &-number-circle-five-bold::before { content: "\f8f0"; }
  &-number-circle-four-bold::before { content: "\f8f1"; }
  &-number-circle-nine-bold::before { content: "\f8f2"; }
  &-number-circle-one-bold::before { content: "\f8f3"; }
  &-number-circle-seven-bold::before { content: "\f8f4"; }
  &-number-circle-six-bold::before { content: "\f8f5"; }
  &-number-circle-three-bold::before { content: "\f8f6"; }
  &-number-circle-two-bold::before { content: "\f8f7"; }
  &-number-circle-zero-bold::before { content: "\f8f8"; }
  &-number-eight-bold::before { content: "\f8f9"; }
  &-number-five-bold::before { content: "\f8fa"; }
  &-number-four-bold::before { content: "\f8fb"; }
  &-number-nine-bold::before { content: "\f8fc"; }
  &-number-one-bold::before { content: "\f8fd"; }
  &-number-seven-bold::before { content: "\f8fe"; }
  &-number-six-bold::before { content: "\f8ff"; }
  &-number-square-eight-bold::before { content: "\f900"; }
  &-number-square-five-bold::before { content: "\f901"; }
  &-number-square-four-bold::before { content: "\f902"; }
  &-number-square-nine-bold::before { content: "\f903"; }
  &-number-square-one-bold::before { content: "\f904"; }
  &-number-square-seven-bold::before { content: "\f905"; }
  &-number-square-six-bold::before { content: "\f906"; }
  &-number-square-three-bold::before { content: "\f907"; }
  &-number-square-two-bold::before { content: "\f908"; }
  &-number-square-zero-bold::before { content: "\f909"; }
  &-number-three-bold::before { content: "\f90a"; }
  &-number-two-bold::before { content: "\f90b"; }
  &-number-zero-bold::before { content: "\f90c"; }
  &-nut-bold::before { content: "\f90d"; }
  &-ny-times-logo-bold::before { content: "\f90e"; }
  &-octagon-bold::before { content: "\f90f"; }
  &-option-bold::before { content: "\f910"; }
  &-package-bold::before { content: "\f911"; }
  &-paint-brush-bold::before { content: "\f912"; }
  &-paint-brush-broad-bold::before { content: "\f913"; }
  &-paint-brush-household-bold::before { content: "\f914"; }
  &-paint-bucket-bold::before { content: "\f915"; }
  &-paint-roller-bold::before { content: "\f916"; }
  &-palette-bold::before { content: "\f917"; }
  &-paper-plane-bold::before { content: "\f918"; }
  &-paper-plane-right-bold::before { content: "\f919"; }
  &-paper-plane-tilt-bold::before { content: "\f91a"; }
  &-paperclip-bold::before { content: "\f91b"; }
  &-paperclip-horizontal-bold::before { content: "\f91c"; }
  &-parachute-bold::before { content: "\f91d"; }
  &-password-bold::before { content: "\f91e"; }
  &-path-bold::before { content: "\f91f"; }
  &-pause-bold::before { content: "\f920"; }
  &-pause-circle-bold::before { content: "\f921"; }
  &-paw-print-bold::before { content: "\f922"; }
  &-peace-bold::before { content: "\f923"; }
  &-pen-bold::before { content: "\f924"; }
  &-pen-nib-bold::before { content: "\f925"; }
  &-pen-nib-straight-bold::before { content: "\f926"; }
  &-pencil-bold::before { content: "\f927"; }
  &-pencil-circle-bold::before { content: "\f928"; }
  &-pencil-line-bold::before { content: "\f929"; }
  &-pencil-simple-bold::before { content: "\f92a"; }
  &-pencil-simple-line-bold::before { content: "\f92b"; }
  &-percent-bold::before { content: "\f92c"; }
  &-person-bold::before { content: "\f92d"; }
  &-person-simple-bold::before { content: "\f92e"; }
  &-person-simple-run-bold::before { content: "\f92f"; }
  &-person-simple-walk-bold::before { content: "\f930"; }
  &-perspective-bold::before { content: "\f931"; }
  &-phone-bold::before { content: "\f932"; }
  &-phone-call-bold::before { content: "\f933"; }
  &-phone-disconnect-bold::before { content: "\f934"; }
  &-phone-incoming-bold::before { content: "\f935"; }
  &-phone-outgoing-bold::before { content: "\f936"; }
  &-phone-slash-bold::before { content: "\f937"; }
  &-phone-x-bold::before { content: "\f938"; }
  &-phosphor-logo-bold::before { content: "\f939"; }
  &-piano-keys-bold::before { content: "\f93a"; }
  &-picture-in-picture-bold::before { content: "\f93b"; }
  &-pill-bold::before { content: "\f93c"; }
  &-pinterest-logo-bold::before { content: "\f93d"; }
  &-pinwheel-bold::before { content: "\f93e"; }
  &-pizza-bold::before { content: "\f93f"; }
  &-placeholder-bold::before { content: "\f940"; }
  &-planet-bold::before { content: "\f941"; }
  &-play-bold::before { content: "\f942"; }
  &-play-circle-bold::before { content: "\f943"; }
  &-playlist-bold::before { content: "\f944"; }
  &-plug-bold::before { content: "\f945"; }
  &-plugs-bold::before { content: "\f946"; }
  &-plugs-connected-bold::before { content: "\f947"; }
  &-plus-bold::before { content: "\f948"; }
  &-plus-circle-bold::before { content: "\f949"; }
  &-plus-minus-bold::before { content: "\f94a"; }
  &-poker-chip-bold::before { content: "\f94b"; }
  &-police-car-bold::before { content: "\f94c"; }
  &-polygon-bold::before { content: "\f94d"; }
  &-popcorn-bold::before { content: "\f94e"; }
  &-power-bold::before { content: "\f94f"; }
  &-prescription-bold::before { content: "\f950"; }
  &-presentation-bold::before { content: "\f951"; }
  &-presentation-chart-bold::before { content: "\f952"; }
  &-printer-bold::before { content: "\f953"; }
  &-prohibit-bold::before { content: "\f954"; }
  &-prohibit-inset-bold::before { content: "\f955"; }
  &-projector-screen-bold::before { content: "\f956"; }
  &-projector-screen-chart-bold::before { content: "\f957"; }
  &-push-pin-bold::before { content: "\f958"; }
  &-push-pin-simple-bold::before { content: "\f959"; }
  &-push-pin-simple-slash-bold::before { content: "\f95a"; }
  &-push-pin-slash-bold::before { content: "\f95b"; }
  &-puzzle-piece-bold::before { content: "\f95c"; }
  &-qr-code-bold::before { content: "\f95d"; }
  &-question-bold::before { content: "\f95e"; }
  &-queue-bold::before { content: "\f95f"; }
  &-quotes-bold::before { content: "\f960"; }
  &-radical-bold::before { content: "\f961"; }
  &-radio-bold::before { content: "\f962"; }
  &-radio-button-bold::before { content: "\f963"; }
  &-rainbow-bold::before { content: "\f964"; }
  &-rainbow-cloud-bold::before { content: "\f965"; }
  &-receipt-bold::before { content: "\f966"; }
  &-record-bold::before { content: "\f967"; }
  &-rectangle-bold::before { content: "\f968"; }
  &-recycle-bold::before { content: "\f969"; }
  &-reddit-logo-bold::before { content: "\f96a"; }
  &-repeat-bold::before { content: "\f96b"; }
  &-repeat-once-bold::before { content: "\f96c"; }
  &-rewind-bold::before { content: "\f96d"; }
  &-rewind-circle-bold::before { content: "\f96e"; }
  &-robot-bold::before { content: "\f96f"; }
  &-rocket-bold::before { content: "\f970"; }
  &-rocket-launch-bold::before { content: "\f971"; }
  &-rows-bold::before { content: "\f972"; }
  &-rss-bold::before { content: "\f973"; }
  &-rss-simple-bold::before { content: "\f974"; }
  &-rug-bold::before { content: "\f975"; }
  &-ruler-bold::before { content: "\f976"; }
  &-scales-bold::before { content: "\f977"; }
  &-scan-bold::before { content: "\f978"; }
  &-scissors-bold::before { content: "\f979"; }
  &-screencast-bold::before { content: "\f97a"; }
  &-scribble-loop-bold::before { content: "\f97b"; }
  &-scroll-bold::before { content: "\f97c"; }
  &-selection-all-bold::before { content: "\f97d"; }
  &-selection-background-bold::before { content: "\f97e"; }
  &-selection-bold::before { content: "\f97f"; }
  &-selection-foreground-bold::before { content: "\f980"; }
  &-selection-inverse-bold::before { content: "\f981"; }
  &-selection-plus-bold::before { content: "\f982"; }
  &-selection-slash-bold::before { content: "\f983"; }
  &-share-bold::before { content: "\f984"; }
  &-share-network-bold::before { content: "\f985"; }
  &-shield-bold::before { content: "\f986"; }
  &-shield-check-bold::before { content: "\f987"; }
  &-shield-checkered-bold::before { content: "\f988"; }
  &-shield-chevron-bold::before { content: "\f989"; }
  &-shield-plus-bold::before { content: "\f98a"; }
  &-shield-slash-bold::before { content: "\f98b"; }
  &-shield-star-bold::before { content: "\f98c"; }
  &-shield-warning-bold::before { content: "\f98d"; }
  &-shopping-bag-bold::before { content: "\f98e"; }
  &-shopping-bag-open-bold::before { content: "\f98f"; }
  &-shopping-cart-bold::before { content: "\f990"; }
  &-shopping-cart-simple-bold::before { content: "\f991"; }
  &-shower-bold::before { content: "\f992"; }
  &-shuffle-angular-bold::before { content: "\f993"; }
  &-shuffle-bold::before { content: "\f994"; }
  &-shuffle-simple-bold::before { content: "\f995"; }
  &-sidebar-bold::before { content: "\f996"; }
  &-sidebar-simple-bold::before { content: "\f997"; }
  &-sign-in-bold::before { content: "\f998"; }
  &-sign-out-bold::before { content: "\f999"; }
  &-signpost-bold::before { content: "\f99a"; }
  &-sim-card-bold::before { content: "\f99b"; }
  &-sketch-logo-bold::before { content: "\f99c"; }
  &-skip-back-bold::before { content: "\f99d"; }
  &-skip-back-circle-bold::before { content: "\f99e"; }
  &-skip-forward-bold::before { content: "\f99f"; }
  &-skip-forward-circle-bold::before { content: "\f9a0"; }
  &-skull-bold::before { content: "\f9a1"; }
  &-slack-logo-bold::before { content: "\f9a2"; }
  &-sliders-bold::before { content: "\f9a3"; }
  &-sliders-horizontal-bold::before { content: "\f9a4"; }
  &-smiley-blank-bold::before { content: "\f9a5"; }
  &-smiley-bold::before { content: "\f9a6"; }
  &-smiley-meh-bold::before { content: "\f9a7"; }
  &-smiley-nervous-bold::before { content: "\f9a8"; }
  &-smiley-sad-bold::before { content: "\f9a9"; }
  &-smiley-sticker-bold::before { content: "\f9aa"; }
  &-smiley-wink-bold::before { content: "\f9ab"; }
  &-smiley-x-eyes-bold::before { content: "\f9ac"; }
  &-snapchat-logo-bold::before { content: "\f9ad"; }
  &-snowflake-bold::before { content: "\f9ae"; }
  &-soccer-ball-bold::before { content: "\f9af"; }
  &-sort-ascending-bold::before { content: "\f9b0"; }
  &-sort-descending-bold::before { content: "\f9b1"; }
  &-spade-bold::before { content: "\f9b2"; }
  &-sparkle-bold::before { content: "\f9b3"; }
  &-speaker-high-bold::before { content: "\f9b4"; }
  &-speaker-low-bold::before { content: "\f9b5"; }
  &-speaker-none-bold::before { content: "\f9b6"; }
  &-speaker-simple-high-bold::before { content: "\f9b7"; }
  &-speaker-simple-low-bold::before { content: "\f9b8"; }
  &-speaker-simple-none-bold::before { content: "\f9b9"; }
  &-speaker-simple-slash-bold::before { content: "\f9ba"; }
  &-speaker-simple-x-bold::before { content: "\f9bb"; }
  &-speaker-slash-bold::before { content: "\f9bc"; }
  &-speaker-x-bold::before { content: "\f9bd"; }
  &-spinner-bold::before { content: "\f9be"; }
  &-spinner-gap-bold::before { content: "\f9bf"; }
  &-spiral-bold::before { content: "\f9c0"; }
  &-spotify-logo-bold::before { content: "\f9c1"; }
  &-square-bold::before { content: "\f9c2"; }
  &-square-half-bold::before { content: "\f9c3"; }
  &-square-half-bottom-bold::before { content: "\f9c4"; }
  &-square-logo-bold::before { content: "\f9c5"; }
  &-squares-four-bold::before { content: "\f9c6"; }
  &-stack-bold::before { content: "\f9c7"; }
  &-stack-overflow-logo-bold::before { content: "\f9c8"; }
  &-stack-simple-bold::before { content: "\f9c9"; }
  &-stamp-bold::before { content: "\f9ca"; }
  &-star-bold::before { content: "\f9cb"; }
  &-star-four-bold::before { content: "\f9cc"; }
  &-star-half-bold::before { content: "\f9cd"; }
  &-sticker-bold::before { content: "\f9ce"; }
  &-stop-bold::before { content: "\f9cf"; }
  &-stop-circle-bold::before { content: "\f9d0"; }
  &-storefront-bold::before { content: "\f9d1"; }
  &-strategy-bold::before { content: "\f9d2"; }
  &-stripe-logo-bold::before { content: "\f9d3"; }
  &-student-bold::before { content: "\f9d4"; }
  &-suitcase-bold::before { content: "\f9d5"; }
  &-suitcase-simple-bold::before { content: "\f9d6"; }
  &-sun-bold::before { content: "\f9d7"; }
  &-sun-dim-bold::before { content: "\f9d8"; }
  &-sun-horizon-bold::before { content: "\f9d9"; }
  &-sunglasses-bold::before { content: "\f9da"; }
  &-swap-bold::before { content: "\f9db"; }
  &-swatches-bold::before { content: "\f9dc"; }
  &-sword-bold::before { content: "\f9dd"; }
  &-syringe-bold::before { content: "\f9de"; }
  &-t-shirt-bold::before { content: "\f9df"; }
  &-table-bold::before { content: "\f9e0"; }
  &-tabs-bold::before { content: "\f9e1"; }
  &-tag-bold::before { content: "\f9e2"; }
  &-tag-chevron-bold::before { content: "\f9e3"; }
  &-tag-simple-bold::before { content: "\f9e4"; }
  &-target-bold::before { content: "\f9e5"; }
  &-taxi-bold::before { content: "\f9e6"; }
  &-telegram-logo-bold::before { content: "\f9e7"; }
  &-television-bold::before { content: "\f9e8"; }
  &-television-simple-bold::before { content: "\f9e9"; }
  &-tennis-ball-bold::before { content: "\f9ea"; }
  &-terminal-bold::before { content: "\f9eb"; }
  &-terminal-window-bold::before { content: "\f9ec"; }
  &-test-tube-bold::before { content: "\f9ed"; }
  &-text-aa-bold::before { content: "\f9ee"; }
  &-text-align-center-bold::before { content: "\f9ef"; }
  &-text-align-justify-bold::before { content: "\f9f0"; }
  &-text-align-left-bold::before { content: "\f9f1"; }
  &-text-align-right-bold::before { content: "\f9f2"; }
  &-text-bolder-bold::before { content: "\f9f3"; }
  &-text-h-bold::before { content: "\f9f4"; }
  &-text-h-five-bold::before { content: "\f9f5"; }
  &-text-h-four-bold::before { content: "\f9f6"; }
  &-text-h-one-bold::before { content: "\f9f7"; }
  &-text-h-six-bold::before { content: "\f9f8"; }
  &-text-h-three-bold::before { content: "\f9f9"; }
  &-text-h-two-bold::before { content: "\f9fa"; }
  &-text-indent-bold::before { content: "\f9fb"; }
  &-text-italic-bold::before { content: "\f9fc"; }
  &-text-outdent-bold::before { content: "\f9fd"; }
  &-text-strikethrough-bold::before { content: "\f9fe"; }
  &-text-t-bold::before { content: "\f9ff"; }
  &-text-underline-bold::before { content: "\fa00"; }
  &-textbox-bold::before { content: "\fa01"; }
  &-thermometer-bold::before { content: "\fa02"; }
  &-thermometer-cold-bold::before { content: "\fa03"; }
  &-thermometer-hot-bold::before { content: "\fa04"; }
  &-thermometer-simple-bold::before { content: "\fa05"; }
  &-thumbs-down-bold::before { content: "\fa06"; }
  &-thumbs-up-bold::before { content: "\fa07"; }
  &-ticket-bold::before { content: "\fa08"; }
  &-tiktok-logo-bold::before { content: "\fa09"; }
  &-timer-bold::before { content: "\fa0a"; }
  &-toggle-left-bold::before { content: "\fa0b"; }
  &-toggle-right-bold::before { content: "\fa0c"; }
  &-toilet-bold::before { content: "\fa0d"; }
  &-toilet-paper-bold::before { content: "\fa0e"; }
  &-tote-bold::before { content: "\fa0f"; }
  &-tote-simple-bold::before { content: "\fa10"; }
  &-trademark-registered-bold::before { content: "\fa11"; }
  &-traffic-cone-bold::before { content: "\fa12"; }
  &-traffic-sign-bold::before { content: "\fa13"; }
  &-traffic-signal-bold::before { content: "\fa14"; }
  &-train-bold::before { content: "\fa15"; }
  &-train-regional-bold::before { content: "\fa16"; }
  &-train-simple-bold::before { content: "\fa17"; }
  &-translate-bold::before { content: "\fa18"; }
  &-trash-bold::before { content: "\fa19"; }
  &-trash-simple-bold::before { content: "\fa1a"; }
  &-tray-bold::before { content: "\fa1b"; }
  &-tree-bold::before { content: "\fa1c"; }
  &-tree-evergreen-bold::before { content: "\fa1d"; }
  &-tree-structure-bold::before { content: "\fa1e"; }
  &-trend-down-bold::before { content: "\fa1f"; }
  &-trend-up-bold::before { content: "\fa20"; }
  &-triangle-bold::before { content: "\fa21"; }
  &-trophy-bold::before { content: "\fa22"; }
  &-truck-bold::before { content: "\fa23"; }
  &-twitch-logo-bold::before { content: "\fa24"; }
  &-twitter-logo-bold::before { content: "\fa25"; }
  &-umbrella-bold::before { content: "\fa26"; }
  &-umbrella-simple-bold::before { content: "\fa27"; }
  &-upload-bold::before { content: "\fa28"; }
  &-upload-simple-bold::before { content: "\fa29"; }
  &-user-bold::before { content: "\fa2a"; }
  &-user-circle-bold::before { content: "\fa2b"; }
  &-user-circle-gear-bold::before { content: "\fa2c"; }
  &-user-circle-minus-bold::before { content: "\fa2d"; }
  &-user-circle-plus-bold::before { content: "\fa2e"; }
  &-user-focus-bold::before { content: "\fa2f"; }
  &-user-gear-bold::before { content: "\fa30"; }
  &-user-list-bold::before { content: "\fa31"; }
  &-user-minus-bold::before { content: "\fa32"; }
  &-user-plus-bold::before { content: "\fa33"; }
  &-user-rectangle-bold::before { content: "\fa34"; }
  &-user-square-bold::before { content: "\fa35"; }
  &-user-switch-bold::before { content: "\fa36"; }
  &-users-bold::before { content: "\fa37"; }
  &-users-four-bold::before { content: "\fa38"; }
  &-users-three-bold::before { content: "\fa39"; }
  &-vault-bold::before { content: "\fa3a"; }
  &-vibrate-bold::before { content: "\fa3b"; }
  &-video-camera-bold::before { content: "\fa3c"; }
  &-video-camera-slash-bold::before { content: "\fa3d"; }
  &-vignette-bold::before { content: "\fa3e"; }
  &-voicemail-bold::before { content: "\fa3f"; }
  &-volleyball-bold::before { content: "\fa40"; }
  &-wall-bold::before { content: "\fa41"; }
  &-wallet-bold::before { content: "\fa42"; }
  &-warning-bold::before { content: "\fa43"; }
  &-warning-circle-bold::before { content: "\fa44"; }
  &-warning-octagon-bold::before { content: "\fa45"; }
  &-watch-bold::before { content: "\fa46"; }
  &-wave-sawtooth-bold::before { content: "\fa47"; }
  &-wave-sine-bold::before { content: "\fa48"; }
  &-wave-square-bold::before { content: "\fa49"; }
  &-wave-triangle-bold::before { content: "\fa4a"; }
  &-waves-bold::before { content: "\fa4b"; }
  &-webcam-bold::before { content: "\fa4c"; }
  &-whatsapp-logo-bold::before { content: "\fa4d"; }
  &-wheelchair-bold::before { content: "\fa4e"; }
  &-wifi-high-bold::before { content: "\fa4f"; }
  &-wifi-low-bold::before { content: "\fa50"; }
  &-wifi-medium-bold::before { content: "\fa51"; }
  &-wifi-none-bold::before { content: "\fa52"; }
  &-wifi-slash-bold::before { content: "\fa53"; }
  &-wifi-x-bold::before { content: "\fa54"; }
  &-wind-bold::before { content: "\fa55"; }
  &-windows-logo-bold::before { content: "\fa56"; }
  &-wine-bold::before { content: "\fa57"; }
  &-wrench-bold::before { content: "\fa58"; }
  &-x-bold::before { content: "\fa59"; }
  &-x-circle-bold::before { content: "\fa5a"; }
  &-x-square-bold::before { content: "\fa5b"; }
  &-yin-yang-bold::before { content: "\fa5c"; }
  &-youtube-logo-bold::before { content: "\fa5d"; }

  &-activity-fill::before { content: "\fa5e"; }
  &-address-book-fill::before { content: "\fa5f"; }
  &-airplane-fill::before { content: "\fa60"; }
  &-airplane-in-flight-fill::before { content: "\fa61"; }
  &-airplane-landing-fill::before { content: "\fa62"; }
  &-airplane-takeoff-fill::before { content: "\fa63"; }
  &-airplane-tilt-fill::before { content: "\fa64"; }
  &-airplay-fill::before { content: "\fa65"; }
  &-alarm-fill::before { content: "\fa66"; }
  &-alien-fill::before { content: "\fa67"; }
  &-align-bottom-fill::before { content: "\fa68"; }
  &-align-bottom-simple-fill::before { content: "\fa69"; }
  &-align-center-horizontal-fill::before { content: "\fa6a"; }
  &-align-center-horizontal-simple-fill::before { content: "\fa6b"; }
  &-align-center-vertical-fill::before { content: "\fa6c"; }
  &-align-center-vertical-simple-fill::before { content: "\fa6d"; }
  &-align-left-fill::before { content: "\fa6e"; }
  &-align-left-simple-fill::before { content: "\fa6f"; }
  &-align-right-fill::before { content: "\fa70"; }
  &-align-right-simple-fill::before { content: "\fa71"; }
  &-align-top-fill::before { content: "\fa72"; }
  &-align-top-simple-fill::before { content: "\fa73"; }
  &-anchor-fill::before { content: "\fa74"; }
  &-anchor-simple-fill::before { content: "\fa75"; }
  &-android-logo-fill::before { content: "\fa76"; }
  &-angular-logo-fill::before { content: "\fa77"; }
  &-aperture-fill::before { content: "\fa78"; }
  &-app-store-logo-fill::before { content: "\fa79"; }
  &-app-window-fill::before { content: "\fa7a"; }
  &-apple-logo-fill::before { content: "\fa7b"; }
  &-apple-podcasts-logo-fill::before { content: "\fa7c"; }
  &-archive-box-fill::before { content: "\fa7d"; }
  &-archive-fill::before { content: "\fa7e"; }
  &-archive-tray-fill::before { content: "\fa7f"; }
  &-armchair-fill::before { content: "\fa80"; }
  &-arrow-arc-left-fill::before { content: "\fa81"; }
  &-arrow-arc-right-fill::before { content: "\fa82"; }
  &-arrow-bend-double-up-left-fill::before { content: "\fa83"; }
  &-arrow-bend-double-up-right-fill::before { content: "\fa84"; }
  &-arrow-bend-down-left-fill::before { content: "\fa85"; }
  &-arrow-bend-down-right-fill::before { content: "\fa86"; }
  &-arrow-bend-left-down-fill::before { content: "\fa87"; }
  &-arrow-bend-left-up-fill::before { content: "\fa88"; }
  &-arrow-bend-right-down-fill::before { content: "\fa89"; }
  &-arrow-bend-right-up-fill::before { content: "\fa8a"; }
  &-arrow-bend-up-left-fill::before { content: "\fa8b"; }
  &-arrow-bend-up-right-fill::before { content: "\fa8c"; }
  &-arrow-circle-down-fill::before { content: "\fa8d"; }
  &-arrow-circle-down-left-fill::before { content: "\fa8e"; }
  &-arrow-circle-down-right-fill::before { content: "\fa8f"; }
  &-arrow-circle-left-fill::before { content: "\fa90"; }
  &-arrow-circle-right-fill::before { content: "\fa91"; }
  &-arrow-circle-up-fill::before { content: "\fa92"; }
  &-arrow-circle-up-left-fill::before { content: "\fa93"; }
  &-arrow-circle-up-right-fill::before { content: "\fa94"; }
  &-arrow-clockwise-fill::before { content: "\fa95"; }
  &-arrow-counter-clockwise-fill::before { content: "\fa96"; }
  &-arrow-down-fill::before { content: "\fa97"; }
  &-arrow-down-left-fill::before { content: "\fa98"; }
  &-arrow-down-right-fill::before { content: "\fa99"; }
  &-arrow-elbow-down-left-fill::before { content: "\fa9a"; }
  &-arrow-elbow-down-right-fill::before { content: "\fa9b"; }
  &-arrow-elbow-left-down-fill::before { content: "\fa9c"; }
  &-arrow-elbow-left-fill::before { content: "\fa9d"; }
  &-arrow-elbow-left-up-fill::before { content: "\fa9e"; }
  &-arrow-elbow-right-down-fill::before { content: "\fa9f"; }
  &-arrow-elbow-right-fill::before { content: "\faa0"; }
  &-arrow-elbow-right-up-fill::before { content: "\faa1"; }
  &-arrow-elbow-up-left-fill::before { content: "\faa2"; }
  &-arrow-elbow-up-right-fill::before { content: "\faa3"; }
  &-arrow-fat-down-fill::before { content: "\faa4"; }
  &-arrow-fat-left-fill::before { content: "\faa5"; }
  &-arrow-fat-line-down-fill::before { content: "\faa6"; }
  &-arrow-fat-line-left-fill::before { content: "\faa7"; }
  &-arrow-fat-line-right-fill::before { content: "\faa8"; }
  &-arrow-fat-line-up-fill::before { content: "\faa9"; }
  &-arrow-fat-lines-down-fill::before { content: "\faaa"; }
  &-arrow-fat-lines-left-fill::before { content: "\faab"; }
  &-arrow-fat-lines-right-fill::before { content: "\faac"; }
  &-arrow-fat-lines-up-fill::before { content: "\faad"; }
  &-arrow-fat-right-fill::before { content: "\faae"; }
  &-arrow-fat-up-fill::before { content: "\faaf"; }
  &-arrow-left-fill::before { content: "\fab0"; }
  &-arrow-line-down-fill::before { content: "\fab1"; }
  &-arrow-line-down-left-fill::before { content: "\fab2"; }
  &-arrow-line-down-right-fill::before { content: "\fab3"; }
  &-arrow-line-left-fill::before { content: "\fab4"; }
  &-arrow-line-right-fill::before { content: "\fab5"; }
  &-arrow-line-up-fill::before { content: "\fab6"; }
  &-arrow-line-up-left-fill::before { content: "\fab7"; }
  &-arrow-line-up-right-fill::before { content: "\fab8"; }
  &-arrow-right-fill::before { content: "\fab9"; }
  &-arrow-square-down-fill::before { content: "\faba"; }
  &-arrow-square-down-left-fill::before { content: "\fabb"; }
  &-arrow-square-down-right-fill::before { content: "\fabc"; }
  &-arrow-square-in-fill::before { content: "\fabd"; }
  &-arrow-square-left-fill::before { content: "\fabe"; }
  &-arrow-square-out-fill::before { content: "\fabf"; }
  &-arrow-square-right-fill::before { content: "\fac0"; }
  &-arrow-square-up-fill::before { content: "\fac1"; }
  &-arrow-square-up-left-fill::before { content: "\fac2"; }
  &-arrow-square-up-right-fill::before { content: "\fac3"; }
  &-arrow-u-down-left-fill::before { content: "\fac4"; }
  &-arrow-u-down-right-fill::before { content: "\fac5"; }
  &-arrow-u-left-down-fill::before { content: "\fac6"; }
  &-arrow-u-left-up-fill::before { content: "\fac7"; }
  &-arrow-u-right-down-fill::before { content: "\fac8"; }
  &-arrow-u-right-up-fill::before { content: "\fac9"; }
  &-arrow-u-up-left-fill::before { content: "\faca"; }
  &-arrow-u-up-right-fill::before { content: "\facb"; }
  &-arrow-up-fill::before { content: "\facc"; }
  &-arrow-up-left-fill::before { content: "\facd"; }
  &-arrow-up-right-fill::before { content: "\face"; }
  &-arrows-clockwise-fill::before { content: "\facf"; }
  &-arrows-counter-clockwise-fill::before { content: "\fad0"; }
  &-arrows-down-up-fill::before { content: "\fad1"; }
  &-arrows-horizontal-fill::before { content: "\fad2"; }
  &-arrows-in-cardinal-fill::before { content: "\fad3"; }
  &-arrows-in-fill::before { content: "\fad4"; }
  &-arrows-in-line-horizontal-fill::before { content: "\fad5"; }
  &-arrows-in-line-vertical-fill::before { content: "\fad6"; }
  &-arrows-in-simple-fill::before { content: "\fad7"; }
  &-arrows-left-right-fill::before { content: "\fad8"; }
  &-arrows-out-cardinal-fill::before { content: "\fad9"; }
  &-arrows-out-fill::before { content: "\fada"; }
  &-arrows-out-line-horizontal-fill::before { content: "\fadb"; }
  &-arrows-out-line-vertical-fill::before { content: "\fadc"; }
  &-arrows-out-simple-fill::before { content: "\fadd"; }
  &-arrows-vertical-fill::before { content: "\fade"; }
  &-article-fill::before { content: "\fadf"; }
  &-article-medium-fill::before { content: "\fae0"; }
  &-article-ny-times-fill::before { content: "\fae1"; }
  &-asterisk-fill::before { content: "\fae2"; }
  &-asterisk-simple-fill::before { content: "\fae3"; }
  &-at-fill::before { content: "\fae4"; }
  &-atom-fill::before { content: "\fae5"; }
  &-baby-fill::before { content: "\fae6"; }
  &-backpack-fill::before { content: "\fae7"; }
  &-backspace-fill::before { content: "\fae8"; }
  &-bag-fill::before { content: "\fae9"; }
  &-bag-simple-fill::before { content: "\faea"; }
  &-balloon-fill::before { content: "\faeb"; }
  &-bandaids-fill::before { content: "\faec"; }
  &-bank-fill::before { content: "\faed"; }
  &-barbell-fill::before { content: "\faee"; }
  &-barcode-fill::before { content: "\faef"; }
  &-barricade-fill::before { content: "\faf0"; }
  &-baseball-fill::before { content: "\faf1"; }
  &-basketball-fill::before { content: "\faf2"; }
  &-bathtub-fill::before { content: "\faf3"; }
  &-battery-charging-fill::before { content: "\faf4"; }
  &-battery-charging-vertical-fill::before { content: "\faf5"; }
  &-battery-empty-fill::before { content: "\faf6"; }
  &-battery-full-fill::before { content: "\faf7"; }
  &-battery-high-fill::before { content: "\faf8"; }
  &-battery-low-fill::before { content: "\faf9"; }
  &-battery-medium-fill::before { content: "\fafa"; }
  &-battery-plus-fill::before { content: "\fafb"; }
  &-battery-warning-fill::before { content: "\fafc"; }
  &-battery-warning-vertical-fill::before { content: "\fafd"; }
  &-bed-fill::before { content: "\fafe"; }
  &-beer-bottle-fill::before { content: "\faff"; }
  &-behance-logo-fill::before { content: "\fb00"; }
  &-bell-fill::before { content: "\fb01"; }
  &-bell-ringing-fill::before { content: "\fb02"; }
  &-bell-simple-fill::before { content: "\fb03"; }
  &-bell-simple-ringing-fill::before { content: "\fb04"; }
  &-bell-simple-slash-fill::before { content: "\fb05"; }
  &-bell-simple-z-fill::before { content: "\fb06"; }
  &-bell-slash-fill::before { content: "\fb07"; }
  &-bell-z-fill::before { content: "\fb08"; }
  &-bezier-curve-fill::before { content: "\fb09"; }
  &-bicycle-fill::before { content: "\fb0a"; }
  &-binoculars-fill::before { content: "\fb0b"; }
  &-bird-fill::before { content: "\fb0c"; }
  &-bluetooth-connected-fill::before { content: "\fb0d"; }
  &-bluetooth-fill::before { content: "\fb0e"; }
  &-bluetooth-slash-fill::before { content: "\fb0f"; }
  &-bluetooth-x-fill::before { content: "\fb10"; }
  &-boat-fill::before { content: "\fb11"; }
  &-book-bookmark-fill::before { content: "\fb12"; }
  &-book-fill::before { content: "\fb13"; }
  &-book-open-fill::before { content: "\fb14"; }
  &-bookmark-fill::before { content: "\fb15"; }
  &-bookmark-simple-fill::before { content: "\fb16"; }
  &-bookmarks-fill::before { content: "\fb17"; }
  &-bookmarks-simple-fill::before { content: "\fb18"; }
  &-books-fill::before { content: "\fb19"; }
  &-bounding-box-fill::before { content: "\fb1a"; }
  &-brackets-angle-fill::before { content: "\fb1b"; }
  &-brackets-curly-fill::before { content: "\fb1c"; }
  &-brackets-round-fill::before { content: "\fb1d"; }
  &-brackets-square-fill::before { content: "\fb1e"; }
  &-brain-fill::before { content: "\fb1f"; }
  &-brandy-fill::before { content: "\fb20"; }
  &-briefcase-fill::before { content: "\fb21"; }
  &-briefcase-metal-fill::before { content: "\fb22"; }
  &-broadcast-fill::before { content: "\fb23"; }
  &-browser-fill::before { content: "\fb24"; }
  &-browsers-fill::before { content: "\fb25"; }
  &-bug-beetle-fill::before { content: "\fb26"; }
  &-bug-droid-fill::before { content: "\fb27"; }
  &-bug-fill::before { content: "\fb28"; }
  &-buildings-fill::before { content: "\fb29"; }
  &-bus-fill::before { content: "\fb2a"; }
  &-butterfly-fill::before { content: "\fb2b"; }
  &-cactus-fill::before { content: "\fb2c"; }
  &-cake-fill::before { content: "\fb2d"; }
  &-calculator-fill::before { content: "\fb2e"; }
  &-calendar-blank-fill::before { content: "\fb2f"; }
  &-calendar-check-fill::before { content: "\fb30"; }
  &-calendar-fill::before { content: "\fb31"; }
  &-calendar-plus-fill::before { content: "\fb32"; }
  &-calendar-x-fill::before { content: "\fb33"; }
  &-camera-fill::before { content: "\fb34"; }
  &-camera-rotate-fill::before { content: "\fb35"; }
  &-camera-slash-fill::before { content: "\fb36"; }
  &-campfire-fill::before { content: "\fb37"; }
  &-car-fill::before { content: "\fb38"; }
  &-car-simple-fill::before { content: "\fb39"; }
  &-cardholder-fill::before { content: "\fb3a"; }
  &-cards-fill::before { content: "\fb3b"; }
  &-caret-circle-double-down-fill::before { content: "\fb3c"; }
  &-caret-circle-double-left-fill::before { content: "\fb3d"; }
  &-caret-circle-double-right-fill::before { content: "\fb3e"; }
  &-caret-circle-double-up-fill::before { content: "\fb3f"; }
  &-caret-circle-down-fill::before { content: "\fb40"; }
  &-caret-circle-left-fill::before { content: "\fb41"; }
  &-caret-circle-right-fill::before { content: "\fb42"; }
  &-caret-circle-up-fill::before { content: "\fb43"; }
  &-caret-double-down-fill::before { content: "\fb44"; }
  &-caret-double-left-fill::before { content: "\fb45"; }
  &-caret-double-right-fill::before { content: "\fb46"; }
  &-caret-double-up-fill::before { content: "\fb47"; }
  &-caret-down-fill::before { content: "\fb48"; }
  &-caret-left-fill::before { content: "\fb49"; }
  &-caret-right-fill::before { content: "\fb4a"; }
  &-caret-up-fill::before { content: "\fb4b"; }
  &-cat-fill::before { content: "\fb4c"; }
  &-cell-signal-full-fill::before { content: "\fb4d"; }
  &-cell-signal-high-fill::before { content: "\fb4e"; }
  &-cell-signal-low-fill::before { content: "\fb4f"; }
  &-cell-signal-medium-fill::before { content: "\fb50"; }
  &-cell-signal-none-fill::before { content: "\fb51"; }
  &-cell-signal-slash-fill::before { content: "\fb52"; }
  &-cell-signal-x-fill::before { content: "\fb53"; }
  &-chalkboard-fill::before { content: "\fb54"; }
  &-chalkboard-simple-fill::before { content: "\fb55"; }
  &-chalkboard-teacher-fill::before { content: "\fb56"; }
  &-chart-bar-fill::before { content: "\fb57"; }
  &-chart-bar-horizontal-fill::before { content: "\fb58"; }
  &-chart-line-fill::before { content: "\fb59"; }
  &-chart-line-up-fill::before { content: "\fb5a"; }
  &-chart-pie-fill::before { content: "\fb5b"; }
  &-chart-pie-slice-fill::before { content: "\fb5c"; }
  &-chat-centered-dots-fill::before { content: "\fb5d"; }
  &-chat-centered-fill::before { content: "\fb5e"; }
  &-chat-centered-text-fill::before { content: "\fb5f"; }
  &-chat-circle-dots-fill::before { content: "\fb60"; }
  &-chat-circle-fill::before { content: "\fb61"; }
  &-chat-circle-text-fill::before { content: "\fb62"; }
  &-chat-dots-fill::before { content: "\fb63"; }
  &-chat-fill::before { content: "\fb64"; }
  &-chat-teardrop-dots-fill::before { content: "\fb65"; }
  &-chat-teardrop-fill::before { content: "\fb66"; }
  &-chat-teardrop-text-fill::before { content: "\fb67"; }
  &-chat-text-fill::before { content: "\fb68"; }
  &-chats-circle-fill::before { content: "\fb69"; }
  &-chats-fill::before { content: "\fb6a"; }
  &-chats-teardrop-fill::before { content: "\fb6b"; }
  &-check-circle-fill::before { content: "\fb6c"; }
  &-check-fill::before { content: "\fb6d"; }
  &-check-square-fill::before { content: "\fb6e"; }
  &-check-square-offset-fill::before { content: "\fb6f"; }
  &-checks-fill::before { content: "\fb70"; }
  &-circle-dashed-fill::before { content: "\fb71"; }
  &-circle-fill::before { content: "\fb72"; }
  &-circle-half-fill::before { content: "\fb73"; }
  &-circle-half-tilt-fill::before { content: "\fb74"; }
  &-circle-notch-fill::before { content: "\fb75"; }
  &-circle-wavy-check-fill::before { content: "\fb76"; }
  &-circle-wavy-fill::before { content: "\fb77"; }
  &-circle-wavy-question-fill::before { content: "\fb78"; }
  &-circle-wavy-warning-fill::before { content: "\fb79"; }
  &-circles-four-fill::before { content: "\fb7a"; }
  &-circles-three-fill::before { content: "\fb7b"; }
  &-circles-three-plus-fill::before { content: "\fb7c"; }
  &-clipboard-fill::before { content: "\fb7d"; }
  &-clipboard-text-fill::before { content: "\fb7e"; }
  &-clock-afternoon-fill::before { content: "\fb7f"; }
  &-clock-clockwise-fill::before { content: "\fb80"; }
  &-clock-counter-clockwise-fill::before { content: "\fb81"; }
  &-clock-fill::before { content: "\fb82"; }
  &-closed-captioning-fill::before { content: "\fb83"; }
  &-cloud-arrow-down-fill::before { content: "\fb84"; }
  &-cloud-arrow-up-fill::before { content: "\fb85"; }
  &-cloud-check-fill::before { content: "\fb86"; }
  &-cloud-fill::before { content: "\fb87"; }
  &-cloud-fog-fill::before { content: "\fb88"; }
  &-cloud-lightning-fill::before { content: "\fb89"; }
  &-cloud-moon-fill::before { content: "\fb8a"; }
  &-cloud-rain-fill::before { content: "\fb8b"; }
  &-cloud-slash-fill::before { content: "\fb8c"; }
  &-cloud-snow-fill::before { content: "\fb8d"; }
  &-cloud-sun-fill::before { content: "\fb8e"; }
  &-club-fill::before { content: "\fb8f"; }
  &-coat-hanger-fill::before { content: "\fb90"; }
  &-code-fill::before { content: "\fb91"; }
  &-code-simple-fill::before { content: "\fb92"; }
  &-codepen-logo-fill::before { content: "\fb93"; }
  &-codesandbox-logo-fill::before { content: "\fb94"; }
  &-coffee-fill::before { content: "\fb95"; }
  &-coin-fill::before { content: "\fb96"; }
  &-coin-vertical-fill::before { content: "\fb97"; }
  &-coins-fill::before { content: "\fb98"; }
  &-columns-fill::before { content: "\fb99"; }
  &-command-fill::before { content: "\fb9a"; }
  &-compass-fill::before { content: "\fb9b"; }
  &-computer-tower-fill::before { content: "\fb9c"; }
  &-confetti-fill::before { content: "\fb9d"; }
  &-cookie-fill::before { content: "\fb9e"; }
  &-cooking-pot-fill::before { content: "\fb9f"; }
  &-copy-fill::before { content: "\fba0"; }
  &-copy-simple-fill::before { content: "\fba1"; }
  &-copyleft-fill::before { content: "\fba2"; }
  &-copyright-fill::before { content: "\fba3"; }
  &-corners-in-fill::before { content: "\fba4"; }
  &-corners-out-fill::before { content: "\fba5"; }
  &-cpu-fill::before { content: "\fba6"; }
  &-credit-card-fill::before { content: "\fba7"; }
  &-crop-fill::before { content: "\fba8"; }
  &-crosshair-fill::before { content: "\fba9"; }
  &-crosshair-simple-fill::before { content: "\fbaa"; }
  &-crown-fill::before { content: "\fbab"; }
  &-crown-simple-fill::before { content: "\fbac"; }
  &-cube-fill::before { content: "\fbad"; }
  &-currency-btc-fill::before { content: "\fbae"; }
  &-currency-circle-dollar-fill::before { content: "\fbaf"; }
  &-currency-cny-fill::before { content: "\fbb0"; }
  &-currency-dollar-fill::before { content: "\fbb1"; }
  &-currency-dollar-simple-fill::before { content: "\fbb2"; }
  &-currency-eth-fill::before { content: "\fbb3"; }
  &-currency-eur-fill::before { content: "\fbb4"; }
  &-currency-gbp-fill::before { content: "\fbb5"; }
  &-currency-inr-fill::before { content: "\fbb6"; }
  &-currency-jpy-fill::before { content: "\fbb7"; }
  &-currency-krw-fill::before { content: "\fbb8"; }
  &-currency-kzt-fill::before { content: "\fbb9"; }
  &-currency-ngn-fill::before { content: "\fbba"; }
  &-currency-rub-fill::before { content: "\fbbb"; }
  &-cursor-fill::before { content: "\fbbc"; }
  &-cursor-text-fill::before { content: "\fbbd"; }
  &-cylinder-fill::before { content: "\fbbe"; }
  &-database-fill::before { content: "\fbbf"; }
  &-desktop-fill::before { content: "\fbc0"; }
  &-desktop-tower-fill::before { content: "\fbc1"; }
  &-detective-fill::before { content: "\fbc2"; }
  &-device-mobile-camera-fill::before { content: "\fbc3"; }
  &-device-mobile-fill::before { content: "\fbc4"; }
  &-device-mobile-speaker-fill::before { content: "\fbc5"; }
  &-device-tablet-camera-fill::before { content: "\fbc6"; }
  &-device-tablet-fill::before { content: "\fbc7"; }
  &-device-tablet-speaker-fill::before { content: "\fbc8"; }
  &-diamond-fill::before { content: "\fbc9"; }
  &-diamonds-four-fill::before { content: "\fbca"; }
  &-dice-five-fill::before { content: "\fbcb"; }
  &-dice-four-fill::before { content: "\fbcc"; }
  &-dice-one-fill::before { content: "\fbcd"; }
  &-dice-six-fill::before { content: "\fbce"; }
  &-dice-three-fill::before { content: "\fbcf"; }
  &-dice-two-fill::before { content: "\fbd0"; }
  &-disc-fill::before { content: "\fbd1"; }
  &-discord-logo-fill::before { content: "\fbd2"; }
  &-divide-fill::before { content: "\fbd3"; }
  &-dog-fill::before { content: "\fbd4"; }
  &-door-fill::before { content: "\fbd5"; }
  &-dots-nine-fill::before { content: "\fbd6"; }
  &-dots-six-fill::before { content: "\fbd7"; }
  &-dots-six-vertical-fill::before { content: "\fbd8"; }
  &-dots-three-circle-fill::before { content: "\fbd9"; }
  &-dots-three-circle-vertical-fill::before { content: "\fbda"; }
  &-dots-three-fill::before { content: "\fbdb"; }
  &-dots-three-outline-fill::before { content: "\fbdc"; }
  &-dots-three-outline-vertical-fill::before { content: "\fbdd"; }
  &-dots-three-vertical-fill::before { content: "\fbde"; }
  &-download-fill::before { content: "\fbdf"; }
  &-download-simple-fill::before { content: "\fbe0"; }
  &-dribbble-logo-fill::before { content: "\fbe1"; }
  &-drop-fill::before { content: "\fbe2"; }
  &-drop-half-bottom-fill::before { content: "\fbe3"; }
  &-drop-half-fill::before { content: "\fbe4"; }
  &-ear-fill::before { content: "\fbe5"; }
  &-ear-slash-fill::before { content: "\fbe6"; }
  &-egg-crack-fill::before { content: "\fbe7"; }
  &-egg-fill::before { content: "\fbe8"; }
  &-eject-fill::before { content: "\fbe9"; }
  &-eject-simple-fill::before { content: "\fbea"; }
  &-envelope-fill::before { content: "\fbeb"; }
  &-envelope-open-fill::before { content: "\fbec"; }
  &-envelope-simple-fill::before { content: "\fbed"; }
  &-envelope-simple-open-fill::before { content: "\fbee"; }
  &-equalizer-fill::before { content: "\fbef"; }
  &-equals-fill::before { content: "\fbf0"; }
  &-eraser-fill::before { content: "\fbf1"; }
  &-exam-fill::before { content: "\fbf2"; }
  &-export-fill::before { content: "\fbf3"; }
  &-eye-closed-fill::before { content: "\fbf4"; }
  &-eye-fill::before { content: "\fbf5"; }
  &-eye-slash-fill::before { content: "\fbf6"; }
  &-eyedropper-fill::before { content: "\fbf7"; }
  &-eyedropper-sample-fill::before { content: "\fbf8"; }
  &-eyeglasses-fill::before { content: "\fbf9"; }
  &-face-mask-fill::before { content: "\fbfa"; }
  &-facebook-logo-fill::before { content: "\fbfb"; }
  &-factory-fill::before { content: "\fbfc"; }
  &-faders-fill::before { content: "\fbfd"; }
  &-faders-horizontal-fill::before { content: "\fbfe"; }
  &-fast-forward-circle-fill::before { content: "\fbff"; }
  &-fast-forward-fill::before { content: "\fc00"; }
  &-figma-logo-fill::before { content: "\fc01"; }
  &-file-arrow-down-fill::before { content: "\fc02"; }
  &-file-arrow-up-fill::before { content: "\fc03"; }
  &-file-audio-fill::before { content: "\fc04"; }
  &-file-cloud-fill::before { content: "\fc05"; }
  &-file-code-fill::before { content: "\fc06"; }
  &-file-css-fill::before { content: "\fc07"; }
  &-file-csv-fill::before { content: "\fc08"; }
  &-file-doc-fill::before { content: "\fc09"; }
  &-file-dotted-fill::before { content: "\fc0a"; }
  &-file-fill::before { content: "\fc0b"; }
  &-file-html-fill::before { content: "\fc0c"; }
  &-file-image-fill::before { content: "\fc0d"; }
  &-file-jpg-fill::before { content: "\fc0e"; }
  &-file-js-fill::before { content: "\fc0f"; }
  &-file-jsx-fill::before { content: "\fc10"; }
  &-file-lock-fill::before { content: "\fc11"; }
  &-file-minus-fill::before { content: "\fc12"; }
  &-file-pdf-fill::before { content: "\fc13"; }
  &-file-plus-fill::before { content: "\fc14"; }
  &-file-png-fill::before { content: "\fc15"; }
  &-file-ppt-fill::before { content: "\fc16"; }
  &-file-rs-fill::before { content: "\fc17"; }
  &-file-search-fill::before { content: "\fc18"; }
  &-file-text-fill::before { content: "\fc19"; }
  &-file-ts-fill::before { content: "\fc1a"; }
  &-file-tsx-fill::before { content: "\fc1b"; }
  &-file-video-fill::before { content: "\fc1c"; }
  &-file-vue-fill::before { content: "\fc1d"; }
  &-file-x-fill::before { content: "\fc1e"; }
  &-file-xls-fill::before { content: "\fc1f"; }
  &-file-zip-fill::before { content: "\fc20"; }
  &-files-fill::before { content: "\fc21"; }
  &-film-script-fill::before { content: "\fc22"; }
  &-film-slate-fill::before { content: "\fc23"; }
  &-film-strip-fill::before { content: "\fc24"; }
  &-fingerprint-fill::before { content: "\fc25"; }
  &-fingerprint-simple-fill::before { content: "\fc26"; }
  &-finn-the-human-fill::before { content: "\fc27"; }
  &-fire-fill::before { content: "\fc28"; }
  &-fire-simple-fill::before { content: "\fc29"; }
  &-first-aid-fill::before { content: "\fc2a"; }
  &-first-aid-kit-fill::before { content: "\fc2b"; }
  &-fish-fill::before { content: "\fc2c"; }
  &-fish-simple-fill::before { content: "\fc2d"; }
  &-flag-banner-fill::before { content: "\fc2e"; }
  &-flag-checkered-fill::before { content: "\fc2f"; }
  &-flag-fill::before { content: "\fc30"; }
  &-flame-fill::before { content: "\fc31"; }
  &-flashlight-fill::before { content: "\fc32"; }
  &-flask-fill::before { content: "\fc33"; }
  &-floppy-disk-back-fill::before { content: "\fc34"; }
  &-floppy-disk-fill::before { content: "\fc35"; }
  &-flow-arrow-fill::before { content: "\fc36"; }
  &-flower-fill::before { content: "\fc37"; }
  &-flower-lotus-fill::before { content: "\fc38"; }
  &-flying-saucer-fill::before { content: "\fc39"; }
  &-folder-dotted-fill::before { content: "\fc3a"; }
  &-folder-fill::before { content: "\fc3b"; }
  &-folder-lock-fill::before { content: "\fc3c"; }
  &-folder-minus-fill::before { content: "\fc3d"; }
  &-folder-notch-fill::before { content: "\fc3e"; }
  &-folder-notch-minus-fill::before { content: "\fc3f"; }
  &-folder-notch-open-fill::before { content: "\fc40"; }
  &-folder-notch-plus-fill::before { content: "\fc41"; }
  &-folder-open-fill::before { content: "\fc42"; }
  &-folder-plus-fill::before { content: "\fc43"; }
  &-folder-simple-dotted-fill::before { content: "\fc44"; }
  &-folder-simple-fill::before { content: "\fc45"; }
  &-folder-simple-lock-fill::before { content: "\fc46"; }
  &-folder-simple-minus-fill::before { content: "\fc47"; }
  &-folder-simple-plus-fill::before { content: "\fc48"; }
  &-folder-simple-star-fill::before { content: "\fc49"; }
  &-folder-simple-user-fill::before { content: "\fc4a"; }
  &-folder-star-fill::before { content: "\fc4b"; }
  &-folder-user-fill::before { content: "\fc4c"; }
  &-folders-fill::before { content: "\fc4d"; }
  &-football-fill::before { content: "\fc4e"; }
  &-fork-knife-fill::before { content: "\fc4f"; }
  &-frame-corners-fill::before { content: "\fc50"; }
  &-framer-logo-fill::before { content: "\fc51"; }
  &-function-fill::before { content: "\fc52"; }
  &-funnel-fill::before { content: "\fc53"; }
  &-funnel-simple-fill::before { content: "\fc54"; }
  &-game-controller-fill::before { content: "\fc55"; }
  &-gas-pump-fill::before { content: "\fc56"; }
  &-gauge-fill::before { content: "\fc57"; }
  &-gear-fill::before { content: "\fc58"; }
  &-gear-six-fill::before { content: "\fc59"; }
  &-gender-female-fill::before { content: "\fc5a"; }
  &-gender-intersex-fill::before { content: "\fc5b"; }
  &-gender-male-fill::before { content: "\fc5c"; }
  &-gender-neuter-fill::before { content: "\fc5d"; }
  &-gender-nonbinary-fill::before { content: "\fc5e"; }
  &-gender-transgender-fill::before { content: "\fc5f"; }
  &-ghost-fill::before { content: "\fc60"; }
  &-gif-fill::before { content: "\fc61"; }
  &-gift-fill::before { content: "\fc62"; }
  &-git-branch-fill::before { content: "\fc63"; }
  &-git-commit-fill::before { content: "\fc64"; }
  &-git-diff-fill::before { content: "\fc65"; }
  &-git-fork-fill::before { content: "\fc66"; }
  &-git-merge-fill::before { content: "\fc67"; }
  &-git-pull-request-fill::before { content: "\fc68"; }
  &-github-logo-fill::before { content: "\fc69"; }
  &-gitlab-logo-fill::before { content: "\fc6a"; }
  &-gitlab-logo-simple-fill::before { content: "\fc6b"; }
  &-globe-fill::before { content: "\fc6c"; }
  &-globe-hemisphere-east-fill::before { content: "\fc6d"; }
  &-globe-hemisphere-west-fill::before { content: "\fc6e"; }
  &-globe-simple-fill::before { content: "\fc6f"; }
  &-globe-stand-fill::before { content: "\fc70"; }
  &-google-chrome-logo-fill::before { content: "\fc71"; }
  &-google-logo-fill::before { content: "\fc72"; }
  &-google-photos-logo-fill::before { content: "\fc73"; }
  &-google-play-logo-fill::before { content: "\fc74"; }
  &-google-podcasts-logo-fill::before { content: "\fc75"; }
  &-gradient-fill::before { content: "\fc76"; }
  &-graduation-cap-fill::before { content: "\fc77"; }
  &-graph-fill::before { content: "\fc78"; }
  &-grid-four-fill::before { content: "\fc79"; }
  &-hamburger-fill::before { content: "\fc7a"; }
  &-hand-eye-fill::before { content: "\fc7b"; }
  &-hand-fill::before { content: "\fc7c"; }
  &-hand-fist-fill::before { content: "\fc7d"; }
  &-hand-grabbing-fill::before { content: "\fc7e"; }
  &-hand-palm-fill::before { content: "\fc7f"; }
  &-hand-pointing-fill::before { content: "\fc80"; }
  &-hand-soap-fill::before { content: "\fc81"; }
  &-hand-waving-fill::before { content: "\fc82"; }
  &-handbag-fill::before { content: "\fc83"; }
  &-handbag-simple-fill::before { content: "\fc84"; }
  &-hands-clapping-fill::before { content: "\fc85"; }
  &-handshake-fill::before { content: "\fc86"; }
  &-hard-drive-fill::before { content: "\fc87"; }
  &-hard-drives-fill::before { content: "\fc88"; }
  &-hash-fill::before { content: "\fc89"; }
  &-hash-straight-fill::before { content: "\fc8a"; }
  &-headlights-fill::before { content: "\fc8b"; }
  &-headphones-fill::before { content: "\fc8c"; }
  &-headset-fill::before { content: "\fc8d"; }
  &-heart-break-fill::before { content: "\fc8e"; }
  &-heart-fill::before { content: "\fc8f"; }
  &-heart-straight-break-fill::before { content: "\fc90"; }
  &-heart-straight-fill::before { content: "\fc91"; }
  &-heartbeat-fill::before { content: "\fc92"; }
  &-hexagon-fill::before { content: "\fc93"; }
  &-highlighter-circle-fill::before { content: "\fc94"; }
  &-horse-fill::before { content: "\fc95"; }
  &-hourglass-fill::before { content: "\fc96"; }
  &-hourglass-high-fill::before { content: "\fc97"; }
  &-hourglass-low-fill::before { content: "\fc98"; }
  &-hourglass-medium-fill::before { content: "\fc99"; }
  &-hourglass-simple-fill::before { content: "\fc9a"; }
  &-hourglass-simple-high-fill::before { content: "\fc9b"; }
  &-hourglass-simple-low-fill::before { content: "\fc9c"; }
  &-hourglass-simple-medium-fill::before { content: "\fc9d"; }
  &-house-fill::before { content: "\fc9e"; }
  &-house-line-fill::before { content: "\fc9f"; }
  &-house-simple-fill::before { content: "\fca0"; }
  &-identification-badge-fill::before { content: "\fca1"; }
  &-identification-card-fill::before { content: "\fca2"; }
  &-image-fill::before { content: "\fca3"; }
  &-image-square-fill::before { content: "\fca4"; }
  &-infinity-fill::before { content: "\fca5"; }
  &-info-fill::before { content: "\fca6"; }
  &-instagram-logo-fill::before { content: "\fca7"; }
  &-intersect-fill::before { content: "\fca8"; }
  &-jeep-fill::before { content: "\fca9"; }
  &-kanban-fill::before { content: "\fcaa"; }
  &-key-fill::before { content: "\fcab"; }
  &-key-return-fill::before { content: "\fcac"; }
  &-keyboard-fill::before { content: "\fcad"; }
  &-keyhole-fill::before { content: "\fcae"; }
  &-knife-fill::before { content: "\fcaf"; }
  &-ladder-fill::before { content: "\fcb0"; }
  &-ladder-simple-fill::before { content: "\fcb1"; }
  &-lamp-fill::before { content: "\fcb2"; }
  &-laptop-fill::before { content: "\fcb3"; }
  &-layout-fill::before { content: "\fcb4"; }
  &-leaf-fill::before { content: "\fcb5"; }
  &-lifebuoy-fill::before { content: "\fcb6"; }
  &-lightbulb-filament-fill::before { content: "\fcb7"; }
  &-lightbulb-fill::before { content: "\fcb8"; }
  &-lightning-fill::before { content: "\fcb9"; }
  &-lightning-slash-fill::before { content: "\fcba"; }
  &-line-segment-fill::before { content: "\fcbb"; }
  &-line-segments-fill::before { content: "\fcbc"; }
  &-link-break-fill::before { content: "\fcbd"; }
  &-link-fill::before { content: "\fcbe"; }
  &-link-simple-break-fill::before { content: "\fcbf"; }
  &-link-simple-fill::before { content: "\fcc0"; }
  &-link-simple-horizontal-break-fill::before { content: "\fcc1"; }
  &-link-simple-horizontal-fill::before { content: "\fcc2"; }
  &-linkedin-logo-fill::before { content: "\fcc3"; }
  &-linux-logo-fill::before { content: "\fcc4"; }
  &-list-bullets-fill::before { content: "\fcc5"; }
  &-list-checks-fill::before { content: "\fcc6"; }
  &-list-dashes-fill::before { content: "\fcc7"; }
  &-list-fill::before { content: "\fcc8"; }
  &-list-numbers-fill::before { content: "\fcc9"; }
  &-list-plus-fill::before { content: "\fcca"; }
  &-lock-fill::before { content: "\fccb"; }
  &-lock-key-fill::before { content: "\fccc"; }
  &-lock-key-open-fill::before { content: "\fccd"; }
  &-lock-laminated-fill::before { content: "\fcce"; }
  &-lock-laminated-open-fill::before { content: "\fccf"; }
  &-lock-open-fill::before { content: "\fcd0"; }
  &-lock-simple-fill::before { content: "\fcd1"; }
  &-lock-simple-open-fill::before { content: "\fcd2"; }
  &-magic-wand-fill::before { content: "\fcd3"; }
  &-magnet-fill::before { content: "\fcd4"; }
  &-magnet-straight-fill::before { content: "\fcd5"; }
  &-magnifying-glass-fill::before { content: "\fcd6"; }
  &-magnifying-glass-minus-fill::before { content: "\fcd7"; }
  &-magnifying-glass-plus-fill::before { content: "\fcd8"; }
  &-map-pin-fill::before { content: "\fcd9"; }
  &-map-pin-line-fill::before { content: "\fcda"; }
  &-map-trifold-fill::before { content: "\fcdb"; }
  &-marker-circle-fill::before { content: "\fcdc"; }
  &-martini-fill::before { content: "\fcdd"; }
  &-mask-happy-fill::before { content: "\fcde"; }
  &-mask-sad-fill::before { content: "\fcdf"; }
  &-math-operations-fill::before { content: "\fce0"; }
  &-medal-fill::before { content: "\fce1"; }
  &-medium-logo-fill::before { content: "\fce2"; }
  &-megaphone-fill::before { content: "\fce3"; }
  &-megaphone-simple-fill::before { content: "\fce4"; }
  &-messenger-logo-fill::before { content: "\fce5"; }
  &-microphone-fill::before { content: "\fce6"; }
  &-microphone-slash-fill::before { content: "\fce7"; }
  &-microphone-stage-fill::before { content: "\fce8"; }
  &-microsoft-excel-logo-fill::before { content: "\fce9"; }
  &-microsoft-powerpoint-logo-fill::before { content: "\fcea"; }
  &-microsoft-teams-logo-fill::before { content: "\fceb"; }
  &-microsoft-word-logo-fill::before { content: "\fcec"; }
  &-minus-circle-fill::before { content: "\fced"; }
  &-minus-fill::before { content: "\fcee"; }
  &-money-fill::before { content: "\fcef"; }
  &-monitor-fill::before { content: "\fcf0"; }
  &-monitor-play-fill::before { content: "\fcf1"; }
  &-moon-fill::before { content: "\fcf2"; }
  &-moon-stars-fill::before { content: "\fcf3"; }
  &-mountains-fill::before { content: "\fcf4"; }
  &-mouse-fill::before { content: "\fcf5"; }
  &-mouse-simple-fill::before { content: "\fcf6"; }
  &-music-note-fill::before { content: "\fcf7"; }
  &-music-note-simple-fill::before { content: "\fcf8"; }
  &-music-notes-fill::before { content: "\fcf9"; }
  &-music-notes-plus-fill::before { content: "\fcfa"; }
  &-music-notes-simple-fill::before { content: "\fcfb"; }
  &-navigation-arrow-fill::before { content: "\fcfc"; }
  &-needle-fill::before { content: "\fcfd"; }
  &-newspaper-clipping-fill::before { content: "\fcfe"; }
  &-newspaper-fill::before { content: "\fcff"; }
  &-note-blank-fill::before { content: "\fd00"; }
  &-note-fill::before { content: "\fd01"; }
  &-note-pencil-fill::before { content: "\fd02"; }
  &-notebook-fill::before { content: "\fd03"; }
  &-notepad-fill::before { content: "\fd04"; }
  &-notification-fill::before { content: "\fd05"; }
  &-number-circle-eight-fill::before { content: "\fd06"; }
  &-number-circle-five-fill::before { content: "\fd07"; }
  &-number-circle-four-fill::before { content: "\fd08"; }
  &-number-circle-nine-fill::before { content: "\fd09"; }
  &-number-circle-one-fill::before { content: "\fd0a"; }
  &-number-circle-seven-fill::before { content: "\fd0b"; }
  &-number-circle-six-fill::before { content: "\fd0c"; }
  &-number-circle-three-fill::before { content: "\fd0d"; }
  &-number-circle-two-fill::before { content: "\fd0e"; }
  &-number-circle-zero-fill::before { content: "\fd0f"; }
  &-number-eight-fill::before { content: "\fd10"; }
  &-number-five-fill::before { content: "\fd11"; }
  &-number-four-fill::before { content: "\fd12"; }
  &-number-nine-fill::before { content: "\fd13"; }
  &-number-one-fill::before { content: "\fd14"; }
  &-number-seven-fill::before { content: "\fd15"; }
  &-number-six-fill::before { content: "\fd16"; }
  &-number-square-eight-fill::before { content: "\fd17"; }
  &-number-square-five-fill::before { content: "\fd18"; }
  &-number-square-four-fill::before { content: "\fd19"; }
  &-number-square-nine-fill::before { content: "\fd1a"; }
  &-number-square-one-fill::before { content: "\fd1b"; }
  &-number-square-seven-fill::before { content: "\fd1c"; }
  &-number-square-six-fill::before { content: "\fd1d"; }
  &-number-square-three-fill::before { content: "\fd1e"; }
  &-number-square-two-fill::before { content: "\fd1f"; }
  &-number-square-zero-fill::before { content: "\fd20"; }
  &-number-three-fill::before { content: "\fd21"; }
  &-number-two-fill::before { content: "\fd22"; }
  &-number-zero-fill::before { content: "\fd23"; }
  &-nut-fill::before { content: "\fd24"; }
  &-ny-times-logo-fill::before { content: "\fd25"; }
  &-octagon-fill::before { content: "\fd26"; }
  &-option-fill::before { content: "\fd27"; }
  &-package-fill::before { content: "\fd28"; }
  &-paint-brush-broad-fill::before { content: "\fd29"; }
  &-paint-brush-fill::before { content: "\fd2a"; }
  &-paint-brush-household-fill::before { content: "\fd2b"; }
  &-paint-bucket-fill::before { content: "\fd2c"; }
  &-paint-roller-fill::before { content: "\fd2d"; }
  &-palette-fill::before { content: "\fd2e"; }
  &-paper-plane-fill::before { content: "\fd2f"; }
  &-paper-plane-right-fill::before { content: "\fd30"; }
  &-paper-plane-tilt-fill::before { content: "\fd31"; }
  &-paperclip-fill::before { content: "\fd32"; }
  &-paperclip-horizontal-fill::before { content: "\fd33"; }
  &-parachute-fill::before { content: "\fd34"; }
  &-password-fill::before { content: "\fd35"; }
  &-path-fill::before { content: "\fd36"; }
  &-pause-circle-fill::before { content: "\fd37"; }
  &-pause-fill::before { content: "\fd38"; }
  &-paw-print-fill::before { content: "\fd39"; }
  &-peace-fill::before { content: "\fd3a"; }
  &-pen-fill::before { content: "\fd3b"; }
  &-pen-nib-fill::before { content: "\fd3c"; }
  &-pen-nib-straight-fill::before { content: "\fd3d"; }
  &-pencil-circle-fill::before { content: "\fd3e"; }
  &-pencil-fill::before { content: "\fd3f"; }
  &-pencil-line-fill::before { content: "\fd40"; }
  &-pencil-simple-fill::before { content: "\fd41"; }
  &-pencil-simple-line-fill::before { content: "\fd42"; }
  &-percent-fill::before { content: "\fd43"; }
  &-person-fill::before { content: "\fd44"; }
  &-person-simple-fill::before { content: "\fd45"; }
  &-person-simple-run-fill::before { content: "\fd46"; }
  &-person-simple-walk-fill::before { content: "\fd47"; }
  &-perspective-fill::before { content: "\fd48"; }
  &-phone-call-fill::before { content: "\fd49"; }
  &-phone-disconnect-fill::before { content: "\fd4a"; }
  &-phone-fill::before { content: "\fd4b"; }
  &-phone-incoming-fill::before { content: "\fd4c"; }
  &-phone-outgoing-fill::before { content: "\fd4d"; }
  &-phone-slash-fill::before { content: "\fd4e"; }
  &-phone-x-fill::before { content: "\fd4f"; }
  &-phosphor-logo-fill::before { content: "\fd50"; }
  &-piano-keys-fill::before { content: "\fd51"; }
  &-picture-in-picture-fill::before { content: "\fd52"; }
  &-pill-fill::before { content: "\fd53"; }
  &-pinterest-logo-fill::before { content: "\fd54"; }
  &-pinwheel-fill::before { content: "\fd55"; }
  &-pizza-fill::before { content: "\fd56"; }
  &-placeholder-fill::before { content: "\fd57"; }
  &-planet-fill::before { content: "\fd58"; }
  &-play-circle-fill::before { content: "\fd59"; }
  &-play-fill::before { content: "\fd5a"; }
  &-playlist-fill::before { content: "\fd5b"; }
  &-plug-fill::before { content: "\fd5c"; }
  &-plugs-connected-fill::before { content: "\fd5d"; }
  &-plugs-fill::before { content: "\fd5e"; }
  &-plus-circle-fill::before { content: "\fd5f"; }
  &-plus-fill::before { content: "\fd60"; }
  &-plus-minus-fill::before { content: "\fd61"; }
  &-poker-chip-fill::before { content: "\fd62"; }
  &-police-car-fill::before { content: "\fd63"; }
  &-polygon-fill::before { content: "\fd64"; }
  &-popcorn-fill::before { content: "\fd65"; }
  &-power-fill::before { content: "\fd66"; }
  &-prescription-fill::before { content: "\fd67"; }
  &-presentation-chart-fill::before { content: "\fd68"; }
  &-presentation-fill::before { content: "\fd69"; }
  &-printer-fill::before { content: "\fd6a"; }
  &-prohibit-fill::before { content: "\fd6b"; }
  &-prohibit-inset-fill::before { content: "\fd6c"; }
  &-projector-screen-chart-fill::before { content: "\fd6d"; }
  &-projector-screen-fill::before { content: "\fd6e"; }
  &-push-pin-fill::before { content: "\fd6f"; }
  &-push-pin-simple-fill::before { content: "\fd70"; }
  &-push-pin-simple-slash-fill::before { content: "\fd71"; }
  &-push-pin-slash-fill::before { content: "\fd72"; }
  &-puzzle-piece-fill::before { content: "\fd73"; }
  &-qr-code-fill::before { content: "\fd74"; }
  &-question-fill::before { content: "\fd75"; }
  &-queue-fill::before { content: "\fd76"; }
  &-quotes-fill::before { content: "\fd77"; }
  &-radical-fill::before { content: "\fd78"; }
  &-radio-button-fill::before { content: "\fd79"; }
  &-radio-fill::before { content: "\fd7a"; }
  &-rainbow-cloud-fill::before { content: "\fd7b"; }
  &-rainbow-fill::before { content: "\fd7c"; }
  &-receipt-fill::before { content: "\fd7d"; }
  &-record-fill::before { content: "\fd7e"; }
  &-rectangle-fill::before { content: "\fd7f"; }
  &-recycle-fill::before { content: "\fd80"; }
  &-reddit-logo-fill::before { content: "\fd81"; }
  &-repeat-fill::before { content: "\fd82"; }
  &-repeat-once-fill::before { content: "\fd83"; }
  &-rewind-circle-fill::before { content: "\fd84"; }
  &-rewind-fill::before { content: "\fd85"; }
  &-robot-fill::before { content: "\fd86"; }
  &-rocket-fill::before { content: "\fd87"; }
  &-rocket-launch-fill::before { content: "\fd88"; }
  &-rows-fill::before { content: "\fd89"; }
  &-rss-fill::before { content: "\fd8a"; }
  &-rss-simple-fill::before { content: "\fd8b"; }
  &-rug-fill::before { content: "\fd8c"; }
  &-ruler-fill::before { content: "\fd8d"; }
  &-scales-fill::before { content: "\fd8e"; }
  &-scan-fill::before { content: "\fd8f"; }
  &-scissors-fill::before { content: "\fd90"; }
  &-screencast-fill::before { content: "\fd91"; }
  &-scribble-loop-fill::before { content: "\fd92"; }
  &-scroll-fill::before { content: "\fd93"; }
  &-selection-all-fill::before { content: "\fd94"; }
  &-selection-background-fill::before { content: "\fd95"; }
  &-selection-fill::before { content: "\fd96"; }
  &-selection-foreground-fill::before { content: "\fd97"; }
  &-selection-inverse-fill::before { content: "\fd98"; }
  &-selection-plus-fill::before { content: "\fd99"; }
  &-selection-slash-fill::before { content: "\fd9a"; }
  &-share-fill::before { content: "\fd9b"; }
  &-share-network-fill::before { content: "\fd9c"; }
  &-shield-check-fill::before { content: "\fd9d"; }
  &-shield-checkered-fill::before { content: "\fd9e"; }
  &-shield-chevron-fill::before { content: "\fd9f"; }
  &-shield-fill::before { content: "\fda0"; }
  &-shield-plus-fill::before { content: "\fda1"; }
  &-shield-slash-fill::before { content: "\fda2"; }
  &-shield-star-fill::before { content: "\fda3"; }
  &-shield-warning-fill::before { content: "\fda4"; }
  &-shopping-bag-fill::before { content: "\fda5"; }
  &-shopping-bag-open-fill::before { content: "\fda6"; }
  &-shopping-cart-fill::before { content: "\fda7"; }
  &-shopping-cart-simple-fill::before { content: "\fda8"; }
  &-shower-fill::before { content: "\fda9"; }
  &-shuffle-angular-fill::before { content: "\fdaa"; }
  &-shuffle-fill::before { content: "\fdab"; }
  &-shuffle-simple-fill::before { content: "\fdac"; }
  &-sidebar-fill::before { content: "\fdad"; }
  &-sidebar-simple-fill::before { content: "\fdae"; }
  &-sign-in-fill::before { content: "\fdaf"; }
  &-sign-out-fill::before { content: "\fdb0"; }
  &-signpost-fill::before { content: "\fdb1"; }
  &-sim-card-fill::before { content: "\fdb2"; }
  &-sketch-logo-fill::before { content: "\fdb3"; }
  &-skip-back-circle-fill::before { content: "\fdb4"; }
  &-skip-back-fill::before { content: "\fdb5"; }
  &-skip-forward-circle-fill::before { content: "\fdb6"; }
  &-skip-forward-fill::before { content: "\fdb7"; }
  &-skull-fill::before { content: "\fdb8"; }
  &-slack-logo-fill::before { content: "\fdb9"; }
  &-sliders-fill::before { content: "\fdba"; }
  &-sliders-horizontal-fill::before { content: "\fdbb"; }
  &-smiley-blank-fill::before { content: "\fdbc"; }
  &-smiley-fill::before { content: "\fdbd"; }
  &-smiley-meh-fill::before { content: "\fdbe"; }
  &-smiley-nervous-fill::before { content: "\fdbf"; }
  &-smiley-sad-fill::before { content: "\fdc0"; }
  &-smiley-sticker-fill::before { content: "\fdc1"; }
  &-smiley-wink-fill::before { content: "\fdc2"; }
  &-smiley-x-eyes-fill::before { content: "\fdc3"; }
  &-snapchat-logo-fill::before { content: "\fdc4"; }
  &-snowflake-fill::before { content: "\fdc5"; }
  &-soccer-ball-fill::before { content: "\fdc6"; }
  &-sort-ascending-fill::before { content: "\fdc7"; }
  &-sort-descending-fill::before { content: "\fdc8"; }
  &-spade-fill::before { content: "\fdc9"; }
  &-sparkle-fill::before { content: "\fdca"; }
  &-speaker-high-fill::before { content: "\fdcb"; }
  &-speaker-low-fill::before { content: "\fdcc"; }
  &-speaker-none-fill::before { content: "\fdcd"; }
  &-speaker-simple-high-fill::before { content: "\fdce"; }
  &-speaker-simple-low-fill::before { content: "\fdcf"; }
  &-speaker-simple-none-fill::before { content: "\fdd0"; }
  &-speaker-simple-slash-fill::before { content: "\fdd1"; }
  &-speaker-simple-x-fill::before { content: "\fdd2"; }
  &-speaker-slash-fill::before { content: "\fdd3"; }
  &-speaker-x-fill::before { content: "\fdd4"; }
  &-spinner-fill::before { content: "\fdd5"; }
  &-spinner-gap-fill::before { content: "\fdd6"; }
  &-spiral-fill::before { content: "\fdd7"; }
  &-spotify-logo-fill::before { content: "\fdd8"; }
  &-square-fill::before { content: "\fdd9"; }
  &-square-half-bottom-fill::before { content: "\fdda"; }
  &-square-half-fill::before { content: "\fddb"; }
  &-square-logo-fill::before { content: "\fddc"; }
  &-squares-four-fill::before { content: "\fddd"; }
  &-stack-fill::before { content: "\fdde"; }
  &-stack-overflow-logo-fill::before { content: "\fddf"; }
  &-stack-simple-fill::before { content: "\fde0"; }
  &-stamp-fill::before { content: "\fde1"; }
  &-star-fill::before { content: "\fde2"; }
  &-star-four-fill::before { content: "\fde3"; }
  &-star-half-fill::before { content: "\fde4"; }
  &-sticker-fill::before { content: "\fde5"; }
  &-stop-circle-fill::before { content: "\fde6"; }
  &-stop-fill::before { content: "\fde7"; }
  &-storefront-fill::before { content: "\fde8"; }
  &-strategy-fill::before { content: "\fde9"; }
  &-stripe-logo-fill::before { content: "\fdea"; }
  &-student-fill::before { content: "\fdeb"; }
  &-suitcase-fill::before { content: "\fdec"; }
  &-suitcase-simple-fill::before { content: "\fded"; }
  &-sun-dim-fill::before { content: "\fdee"; }
  &-sun-fill::before { content: "\fdef"; }
  &-sun-horizon-fill::before { content: "\fdf0"; }
  &-sunglasses-fill::before { content: "\fdf1"; }
  &-swap-fill::before { content: "\fdf2"; }
  &-swatches-fill::before { content: "\fdf3"; }
  &-sword-fill::before { content: "\fdf4"; }
  &-syringe-fill::before { content: "\fdf5"; }
  &-t-shirt-fill::before { content: "\fdf6"; }
  &-table-fill::before { content: "\fdf7"; }
  &-tabs-fill::before { content: "\fdf8"; }
  &-tag-chevron-fill::before { content: "\fdf9"; }
  &-tag-fill::before { content: "\fdfa"; }
  &-tag-simple-fill::before { content: "\fdfb"; }
  &-target-fill::before { content: "\fdfc"; }
  &-taxi-fill::before { content: "\fdfd"; }
  &-telegram-logo-fill::before { content: "\fdfe"; }
  &-television-fill::before { content: "\fdff"; }
  &-television-simple-fill::before { content: "\fe00"; }
  &-tennis-ball-fill::before { content: "\fe01"; }
  &-terminal-fill::before { content: "\fe02"; }
  &-terminal-window-fill::before { content: "\fe03"; }
  &-test-tube-fill::before { content: "\fe04"; }
  &-text-aa-fill::before { content: "\fe05"; }
  &-text-align-center-fill::before { content: "\fe06"; }
  &-text-align-justify-fill::before { content: "\fe07"; }
  &-text-align-left-fill::before { content: "\fe08"; }
  &-text-align-right-fill::before { content: "\fe09"; }
  &-text-bolder-fill::before { content: "\fe0a"; }
  &-text-h-fill::before { content: "\fe0b"; }
  &-text-h-five-fill::before { content: "\fe0c"; }
  &-text-h-four-fill::before { content: "\fe0d"; }
  &-text-h-one-fill::before { content: "\fe0e"; }
  &-text-h-six-fill::before { content: "\fe0f"; }
  &-text-h-three-fill::before { content: "\fe10"; }
  &-text-h-two-fill::before { content: "\fe11"; }
  &-text-indent-fill::before { content: "\fe12"; }
  &-text-italic-fill::before { content: "\fe13"; }
  &-text-outdent-fill::before { content: "\fe14"; }
  &-text-strikethrough-fill::before { content: "\fe15"; }
  &-text-t-fill::before { content: "\fe16"; }
  &-text-underline-fill::before { content: "\fe17"; }
  &-textbox-fill::before { content: "\fe18"; }
  &-thermometer-cold-fill::before { content: "\fe19"; }
  &-thermometer-fill::before { content: "\fe1a"; }
  &-thermometer-hot-fill::before { content: "\fe1b"; }
  &-thermometer-simple-fill::before { content: "\fe1c"; }
  &-thumbs-down-fill::before { content: "\fe1d"; }
  &-thumbs-up-fill::before { content: "\fe1e"; }
  &-ticket-fill::before { content: "\fe1f"; }
  &-tiktok-logo-fill::before { content: "\fe20"; }
  &-timer-fill::before { content: "\fe21"; }
  &-toggle-left-fill::before { content: "\fe22"; }
  &-toggle-right-fill::before { content: "\fe23"; }
  &-toilet-fill::before { content: "\fe24"; }
  &-toilet-paper-fill::before { content: "\fe25"; }
  &-tote-fill::before { content: "\fe26"; }
  &-tote-simple-fill::before { content: "\fe27"; }
  &-trademark-registered-fill::before { content: "\fe28"; }
  &-traffic-cone-fill::before { content: "\fe29"; }
  &-traffic-sign-fill::before { content: "\fe2a"; }
  &-traffic-signal-fill::before { content: "\fe2b"; }
  &-train-fill::before { content: "\fe2c"; }
  &-train-regional-fill::before { content: "\fe2d"; }
  &-train-simple-fill::before { content: "\fe2e"; }
  &-translate-fill::before { content: "\fe2f"; }
  &-trash-fill::before { content: "\fe30"; }
  &-trash-simple-fill::before { content: "\fe31"; }
  &-tray-fill::before { content: "\fe32"; }
  &-tree-evergreen-fill::before { content: "\fe33"; }
  &-tree-fill::before { content: "\fe34"; }
  &-tree-structure-fill::before { content: "\fe35"; }
  &-trend-down-fill::before { content: "\fe36"; }
  &-trend-up-fill::before { content: "\fe37"; }
  &-triangle-fill::before { content: "\fe38"; }
  &-trophy-fill::before { content: "\fe39"; }
  &-truck-fill::before { content: "\fe3a"; }
  &-twitch-logo-fill::before { content: "\fe3b"; }
  &-twitter-logo-fill::before { content: "\fe3c"; }
  &-umbrella-fill::before { content: "\fe3d"; }
  &-umbrella-simple-fill::before { content: "\fe3e"; }
  &-upload-fill::before { content: "\fe3f"; }
  &-upload-simple-fill::before { content: "\fe40"; }
  &-user-circle-fill::before { content: "\fe41"; }
  &-user-circle-gear-fill::before { content: "\fe42"; }
  &-user-circle-minus-fill::before { content: "\fe43"; }
  &-user-circle-plus-fill::before { content: "\fe44"; }
  &-user-fill::before { content: "\fe45"; }
  &-user-focus-fill::before { content: "\fe46"; }
  &-user-gear-fill::before { content: "\fe47"; }
  &-user-list-fill::before { content: "\fe48"; }
  &-user-minus-fill::before { content: "\fe49"; }
  &-user-plus-fill::before { content: "\fe4a"; }
  &-user-rectangle-fill::before { content: "\fe4b"; }
  &-user-square-fill::before { content: "\fe4c"; }
  &-user-switch-fill::before { content: "\fe4d"; }
  &-users-fill::before { content: "\fe4e"; }
  &-users-four-fill::before { content: "\fe4f"; }
  &-users-three-fill::before { content: "\fe50"; }
  &-vault-fill::before { content: "\fe51"; }
  &-vibrate-fill::before { content: "\fe52"; }
  &-video-camera-fill::before { content: "\fe53"; }
  &-video-camera-slash-fill::before { content: "\fe54"; }
  &-vignette-fill::before { content: "\fe55"; }
  &-voicemail-fill::before { content: "\fe56"; }
  &-volleyball-fill::before { content: "\fe57"; }
  &-wall-fill::before { content: "\fe58"; }
  &-wallet-fill::before { content: "\fe59"; }
  &-warning-circle-fill::before { content: "\fe5a"; }
  */
  &-warning-fill::before { content: "\fe5b"; }
  /*
  &-warning-octagon-fill::before { content: "\fe5c"; }
  &-watch-fill::before { content: "\fe5d"; }
  &-wave-sawtooth-fill::before { content: "\fe5e"; }
  &-wave-sine-fill::before { content: "\fe5f"; }
  &-wave-square-fill::before { content: "\fe60"; }
  &-wave-triangle-fill::before { content: "\fe61"; }
  &-waves-fill::before { content: "\fe62"; }
  &-webcam-fill::before { content: "\fe63"; }
  &-whatsapp-logo-fill::before { content: "\fe64"; }
  &-wheelchair-fill::before { content: "\fe65"; }
  &-wifi-high-fill::before { content: "\fe66"; }
  &-wifi-low-fill::before { content: "\fe67"; }
  &-wifi-medium-fill::before { content: "\fe68"; }
  &-wifi-none-fill::before { content: "\fe69"; }
  &-wifi-slash-fill::before { content: "\fe6a"; }
  &-wifi-x-fill::before { content: "\fe6b"; }
  &-wind-fill::before { content: "\fe6c"; }
  &-windows-logo-fill::before { content: "\fe6d"; }
  &-wine-fill::before { content: "\fe6e"; }
  &-wrench-fill::before { content: "\fe6f"; }
  &-x-circle-fill::before { content: "\fe70"; }
  &-x-fill::before { content: "\fe71"; }
  &-x-square-fill::before { content: "\fe72"; }
  &-yin-yang-fill::before { content: "\fe73"; }
  &-youtube-logo-fill::before { content: "\fe74"; }
  */
}
