@import '@styles/basic';

p.single {
  color: $dark !important;
}
.container {
  p {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 0.9rem;
    margin-bottom: 0;
    &:nth-child(2) {
      color: rgba($dark, 0.5);
    }
    .info {
      color: rgba($dark, 0.5);
      font-size: 0.75rem;
      margin-left: 0.2rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
