@import '@styles/basic';

.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 100px;
  font-weight: 400;
  font-size: toRem(12);
  line-height: toRem(18);
  padding: 3px 8px;
  border: 1px solid;

  // Type modifiers

  &.primary {
    background-color: $blue;
    color: $light;
    border-color: $blue;
  }
  &.secondary {
    background-color: transparent;
    color: $blue;
    border-color: $blue;
  }

  &.green {
    border-color: $success;
    color: $success;
  }
  &.grey {
    border-color: rgba($dark, 0.5);
    color: rgba($dark, 0.5);
  }

  &.orange {
    border-color: $orange;
    color: $orange;
  }

  &.red {
    border-color: $red;
    color: $red;
  }

  &.yellow {
    border-color: $yellow;
    color: $yellow;
  }

  .status {
    width: toRem(10);
    height: toRem(10);
    border-radius: 50%;

    &.leading {
      margin-right: 8px;
    }

    &.trailing {
      margin-left: 8px;
    }

    &.warning {
      background-color: $yellow;
    }
    &.danger {
      background-color: $red;
    }
    &.new {
      background-color: $orange;
    }
    &.success {
      background-color: $green;
    }
  }
}
