@import '@styles/basic';

.container {
  border: 1px solid rgba(11, 40, 78, 0.25);
  border-radius: toRem(15);
  padding: toRem(16);
  background-color: $light;
  display: flex;
  flex-direction: column;
  gap: toRem(8);
  align-self: flex-start;
}
