@import '@styles/basic';

.inline {
  display: flex;
  align-items: center;
  strong {
    display: inline;
  }
}

.step {
  border: 1px solid #ccc;
  width: 32px;
  height: 32px;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: toRem(8);

  p {
    line-height: 2.1;
    font-size: toRem(14);
  }
  + p {
    flex: 1;
  }
}

.cardWrapper {
  margin-bottom: toRem(16);
}

.container {
  @include breakpoint(md, true) {
    max-width: toRem(525);
  }
}

.dark {
  margin-top: toRem(16);
  background-color: $catskill-white;
  border-radius: 10px;
  padding: toRem(16);
  > div:not(:last-of-type) {
    margin-bottom: toRem(12);
  }
}
