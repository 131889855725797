@import '@styles/basic';

.container {
  background: transparent;
  padding: 1.2rem;
  display: flex;
  align-items: center;

  & > p a {
    color: $blue;
  }

  &.hasBorder {
    border: 1px solid rgba($dark, 0.5);
    border-radius: 30px;
  }

  .link {
    color: $blue;
    cursor: pointer;
  }
}
