.focus {
  &__wrap {
    align-items: center;
  }

  &__text {
    @include breakpoint(md, true) {
      width: 46%;
    }

    img {
      @include breakpoint(md) {
        max-width: 75%;
      }
    }
  }

  &__image {
    position: relative;
    font-size: 0;

    @include breakpoint(md, true) {
      width: 47%;
    }

    @include breakpoint(md) {
      margin-top: 2.5rem;
    }

    img,
    video {
      border-radius: 1.5rem;

      @include breakpoint(sm) {
        border-radius: 0.75rem;
      }
    }
  }

  &--left {
    .focus__wrap {
      @include breakpoint(md, true) {
        flex-direction: row-reverse;
      }
    }
  }
}
