.badge {
  --color: #{$blue};
  --bg: #{$light};

  font-size: 0.8rem;
  background: transparentize($light, 0.05);
  line-height: 1.25;
  padding: 1.25em 2.125em;
  display: inline-flex;
  border-radius: 1em;
  align-items: center;

  @include breakpoint(sm) {
    padding: 0.8em 1em;
    font-size: 0.7rem;
  }

  &--success {
    --color: #3cc13b;
  }

  &--warning {
    --color: #f2c94c;
  }

  &--alert {
    --color: #cd143c;
  }

  &__status {
    align-items: center;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    display: block;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;

    @include breakpoint(sm) {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.3rem;
    }

    &::before {
      content: "";
      background: var(--color);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: block;
      animation: pulse1 3s linear infinite;
    }

    &::after {
      top: 0;
      left: 0;
      position: absolute;
      content: "";
      border: 2px solid var(--color);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: block;
      animation: pulse2 3s linear infinite;
      box-sizing: border-box;
    }
  }

  p {
    font-size: inherit;
    margin-bottom: 0;
  }

  .focus__image &,
  .hero__image & {
    position: absolute;
    bottom: 0.9rem;
    right: 1rem;
    max-width: calc(100% - 2rem);
  }
}

@keyframes pulse1 {
  0% { transform: scale(1); }
  25% { transform: scale(0.8); }
  33% { transform: scale(1.4); }
  50% { transform: scale(0.9); }
  60% { transform: scale(1); }
  100% { transform: scale(1); }
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  25% { transform: scale(0.8); }
  50% { opacity: 0; }
  70% { transform: scale(4); }
  71% { transform: scale(1); }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
