@import '@styles/basic';

.container {
  display: flex;
  flex-direction: column;
  gap: toRem(16);
}

.input {
  position: relative;

  > span {
    position: absolute;
    top: 57%;
    opacity: 0.5;
    left: toRem(16);
    transform: translateY(-50%);
  }
  span + input {
    padding-left: toRem(120);
  }

  input {
    width: 100%;
  }
  input + .copy {
    right: toRem(16);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    position: absolute;
    span {
      font-size: toRem(16);
    }
  }
}

.description {
  font-size: toRem(12) !important;
  line-height: toRem(18) !important;
}

.imageContainer {
  display: flex;
  justify-content: center;
}
