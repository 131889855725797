@import '@styles/basic';
.container {
  background-color: white;
  border: toRem(1) solid rgba($dark, 0.2);
  border-radius: toRem(16);
  height: 100%;
  p,
  strong {
    font-size: toRem(16);
    margin: 0;
    line-height: 1.5;
    display: block;
  }
}

.header {
  padding: toRem(16);
  border-bottom: toRem(1) solid rgba($dark, 0.2);
}

.content {
  height: auto;
  display: flex;
  flex-direction: column;
  gap: toRem(16);
  padding: toRem(16);
}
