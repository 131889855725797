@import '@styles/basic';

.container {
  text-align: center;
  width: 100%;
  max-width: toRem(450);
  background: $light;
  border: 1px solid transparentize($dark, 0.75);
  border-radius: 15px;
  margin-bottom: toRem(16);
  display: flex;
  flex-direction: column;

  @include breakpoint(md, true) {
    width: toRem(450);
  }
  & > form {
    padding: toRem(24);
  }

  &.maxWidth {
    width: 100%;
    max-width: toRem(595);

    @include breakpoint(md, true) {
      max-width: toRem(595);
      width: 100%;
    }
  }
  .header {
    text-align: left;
    padding: toRem(16) toRem(24);
    border-bottom: 1px solid transparentize($dark, 0.75);
    & > p {
      font-weight: 600;
      font-size: toRem(16);
      line-height: toRem(26);
    }
  }

  .body {
    padding: toRem(24);
  }
}
