@import '@styles/basic';

.container {
  text-align: center;
  width: 100%;
  max-width: toRem(556);
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 44px;
    font-size: toRem(48);
  }
  img {
    max-width: 200px;
    width: 100%;
    text-align: center;
  }
  p {
    font-size: toRem(16);
  }
}

.block {
  width: 100%;
  text-align: center;
  background: $light;
  border: 1px solid transparentize($dark, 0.75);
  border-radius: 16px;
  padding: toRem(48) toRem(24);
}

.totalWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: toRem(32);
}

.total {
  font-size: toRem(24);
  margin-top: toRem(8);
}

.error {
  margin-top: toRem(24);
  p {
    color: $red;
  }
}
