@import '@styles/basic';

.formWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  text-align: center;
  width: 100%;
}

.registerCta {
  margin-top: 1.2rem !important;
  text-align: center;
}

.loginCta {
  display: inline;
  font-size: 0.8rem;
  line-height: 26px;
  font-weight: 600;
  color: $blue;
  cursor: pointer;
}
