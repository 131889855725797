@import '@styles/basic';

.container {
  padding: toRem(16);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 100%;
  span {
    font-size: toRem(32);
    cursor: pointer;
  }
}

.title {
  display: flex;
  align-items: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-align: 26px;
  span {
    margin-right: toRem(12);
    cursor: initial;
    color: $success;
  }
}
.footer {
  display: flex;
  column-gap: toRem(8);
  flex: 1 1 100%;
  > * {
    flex: 1 1 100%;
  }
}

.body {
  padding: toRem(16) 0;
  strong {
    max-width: calc(100vw / 1.6);
    @include breakpoint(md, true) {
      max-width: calc(430px / 1.6);
    }
  }
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
