@import '@styles/basic';

.container {
  height: 100px;
  color: rgba($dark, 0.5);
  padding: 0 toRem(24);
  background-color: $light;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    line-height: 1.5;
  }
}
