@import '@styles/basic';

.container {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  .message {
    font-size: toRem(16);
    font-weight: 400;
    line-height: toRem(24);
    margin-bottom: toRem(16);
  }

  .email {
    padding: toRem(16) toRem(16) toRem(20) toRem(16);
    background-color: rgba($blue, 0.05);
    border-radius: 15px;
    margin-bottom: toRem(16);
    & > p {
      font-size: toRem(14);
      font-weight: 400;
      line-height: toRem(30);
      text-align: center;
      @include breakpoint(xs, true) {
        font-size: toRem(14);
      }

      @include breakpoint(sm, true) {
        font-size: toRem(16);
      }

      @include breakpoint(lg, true) {
        font-size: toRem(18);
      }
    }
  }

  .notReceivedMessage {
    font-size: toRem(16);
    font-weight: 600;
    line-height: toRem(26);
    margin-bottom: toRem(4);
  }
  .checkSpam {
    font-size: toRem(16);
    font-weight: 400;
    line-height: toRem(24);
    span {
      color: $blue;
      cursor: pointer;
    }
  }
}
