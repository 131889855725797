a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0 0 0.35em;
  font-weight: 500;
  line-height: 1.25;
}

h1,
.h1 {
  font-size: 3.2rem;

  @include breakpoint(xxl) {
    font-size: 3rem;
  }

  @include breakpoint(xl) {
    font-size: 2.8rem;
  }

  @include breakpoint(lg) {
    font-size: 2.6rem;
  }

  @include breakpoint(md) {
    font-size: 2.4rem;
  }

  @include breakpoint(sm) {
    font-size: 2.2rem;
  }

  @include breakpoint(xs) {
    font-size: 2rem;
  }

  @include breakpoint(xxs) {
    font-size: 1.6rem;
  }
}

h2,
.h2 {
  font-size: 2.4rem;

  @include breakpoint(lg) {
    font-size: 2rem;
  }

  @include breakpoint(sm) {
    font-size: 1.8rem;
  }

  @include breakpoint(xxs) {
    font-size: 1.4rem;
  }
}

h3,
.h3 {
  font-size: 2rem;
}

h4,
.h4 {
  font-size: 1.2rem;
  font-weight: 700;
}

h5,
.h5 {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: $blue;
}

h6,
.h6 {
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  color: $blue;
}

.cta {
  font-weight: 700;
  display: inline-flex;
  font-size: 0.8rem;
  padding: 0.65em 1em 0.75em;
  background: $blue;
  color: $light;
  line-height: 1.26;
  border: 2px solid transparent;
  border-radius: 2em;
  text-align: center;
  justify-content: center;

  &.big {
    font-size: 0.9rem;
    padding: 0.7em 1.33em 0.8em;
  }

  &.block {
    display: flex;
    width: 100%;
    align-items: center;
  }

  &.secondary,
  &--secondary {
    background: none;
    color: $blue;
    border: 2px solid currentcolor;

    &:hover {
      background: transparentize($blue, 0.95);
    }
  }
}

span[style='text-decoration: underline;'] {
  text-decoration: none !important;
  display: inline-block;
  position: relative;
  white-space: nowrap;

  &::before {
    content: '';
    background-image: url('../../images/underline.svg');
    background-size: auto 100%;
    width: 0;
    height: 0.166em;
    border-radius: 0.083em;
    position: absolute;
    top: 100%;
    margin-top: -0.083em;
    left: 0;
    pointer-events: none;
    transition: width 0.25s 0.15s ease-in-out;
  }

  .scrolled & {
    &::before {
      width: 100%;
    }
  }
}

.wp-block-button__link {
  @extend .cta;

  display: inline-block;
}

.is-style-outline .wp-block-button__link {
  @extend .cta--secondary;

  &:not(.has-text-color) {
    color: $blue;
  }
}

.wp-block-buttons {
  @include breakpoint(md) {
    justify-content: center !important;
  }
}

p {
  margin-top: 0;
  font-size: 1.2rem;
  line-height: 1.41;
  margin-bottom: 1em;

  @include breakpoint(lg) {
    font-size: 1rem;
  }

  @include breakpoint(sm) {
    font-size: 0.9rem;
  }

  @include breakpoint(xs) {
    font-size: 0.8rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
