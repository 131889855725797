@import '@styles/basic';

.container {
  form {
    text-align: left;
  }
}

.horizontalSelect:not(:last-of-type),
.horizontal:not(:last-of-type) {
  border-bottom: none;

  @include breakpoint(md, true) {
    border-bottom: 1px solid rgba(11, 40, 78, 0.25);
  }
}

.horizontal {
  @include breakpoint(md, true) {
    padding-top: toRem(8);
    padding-bottom: toRem(34);
  }
  & > div {
    display: flex;
    justify-content: space-between;
    @include breakpoint(md, true) {
      display: flex;
      justify-content: space-between;
    }

    & > input {
      @include breakpoint(md, true) {
        max-width: toRem(500);
      }
    }

    & > label {
      font-size: toRem(16);
      font-weight: 600;
      line-height: toRem(26);
      margin-right: 0 !important;

      @include breakpoint(md, true) {
        min-width: 40%;
      }
    }
  }
}

.horizontalSelect {
  @include breakpoint(md, true) {
    padding-top: toRem(8);
    padding-bottom: toRem(34);
    display: flex;
    flex-direction: column;
  }
  & > div {
    text-align: left;

    @include breakpoint(md, true) {
      display: flex;
      justify-content: space-between;
    }
    & > div {
      @include breakpoint(md, true) {
        max-width: toRem(500);
        width: 100%;
      }
    }
    & > label {
      margin-right: toRem(16);
      @include breakpoint(md, true) {
        margin-right: 0;
        min-width: 40%;
        font-weight: 600;
      }
    }
  }
}

.horizontalError {
  font-weight: 500;
  @include breakpoint(md, true) {
    margin-right: toRem(8);
    align-self: flex-end;
    width: calc(60% - 22px);
  }
}

.error {
  color: $red;
  font-weight: 500;
  line-height: 0.9rem;
  font-size: 0.7rem;
  line-height: 1;
  margin-top: 0.4rem;
}
.inputLabel {
  font-size: toRem(16);
  line-height: toRem(18);
  color: $dark;
  font-weight: 600;
  margin-bottom: toRem(8);
  display: block;
}
.inputWrapperContainer {
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  display: flex;
  line-height: 1.2rem;
  margin-bottom: 0.7rem;
  margin-top: 0.7rem;

  &.userType {
    flex-direction: column;
    align-items: flex-start;
  }
  .inputWrapper {
    &.userType {
      flex-direction: column;
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
      .inputRadio {
        margin-bottom: 0.7rem;
      }
    }

    .inputRadio {
      display: flex;
      &.isPa {
        &:first-child {
          margin-right: 0.7rem;
        }
      }
    }
  }
}
