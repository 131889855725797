@import '@styles/basic';
.container {
  display: flex;
  flex-direction: column;

  a.forgetPass {
    margin-top: toRem(14);
    display: inline-block;
  }

  .recaptcha {
    display: flex;
    justify-content: center;
    margin-bottom: toRem(24);
  }
}

.error {
  color: $red;
  font-weight: 500;
  line-height: 0.9rem;
  font-size: 0.7rem;
  line-height: 1;
  margin-top: 0.4rem;
}
