.footer {
  padding: 1.8rem 0;
  font-size: 0.9rem;

  @include breakpoint(xs) {
    font-size: 0.8rem;
  }

  p {
    font-size: 0.9rem;

    @include breakpoint(xs) {
      font-size: 0.8rem;
    }
  }

  &__newsletter {
    width: 100%;
    text-align: center;
    margin-bottom: 2.5rem;

    form {
      padding: 0.8rem 0 1rem;

      @include breakpoint(sm) {
        padding: 0.5rem 0 0;
      }

      input,
      button {
        @include breakpoint(xs) {
          display: block;
          width: 100%;
        }
      }

      input {
        @include breakpoint(xs) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  &__widgets {
    display: flex;
    flex-wrap: wrap;

    section {
      width: 25%;
      margin-bottom: 2.5rem;
      padding-right: 1rem;

      @include breakpoint(lg) {
        width: 50%;
      }

      @include breakpoint(sm) {
        width: 100%;
        padding-right: 0;
      }

      h3 {
        @extend .h6;

        margin-bottom: 1.1rem;
      }

      &:last-child {
        width: 100%;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
}
