.main {
  > *:not(section) {
    max-width: 786px;
    margin-left: auto;
    margin-right: auto;
  }

  > section,
  > .wp-block-columns {
    padding: 2.2rem 0 3.2rem;
    margin-bottom: 1rem;
    opacity: 0;
    transform: translateY(1.5rem);
    transition: opacity 0.25s linear, transform 0.25s ease-in-out;

    @include breakpoint(md) {
    }

    @include breakpoint(sm) {
      padding: 1.2rem 0;
      margin-bottom: 1.2rem;
    }

    &.scrolled {
      opacity: 1;
      transform: none;
    }
  }
}
