@import '@styles/basic';

.container {
  border: 1px solid rgba($dark, 0.25);
  max-width: 86px;
  background-color: $light;
  border-radius: 5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;

  span {
    font-weight: 600;
    font-size: 14px;
  }
  button {
    background-color: transparent;
    color: $dark;
    border: none;
    padding: 0;
    i {
      font-size: 0.7rem;
      font-weight: 500;
    }
  }
}

.input {
  width: 100%;
  text-align: center;
  border: 0;
  padding: 0;
}
