@import '@styles/basic';

.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgba(11, 40, 78, 0.5);
  border-radius: 50%;
  flex-shrink: 0;
  align-self: center;
  span {
    color: $light;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-transform: uppercase;
  }

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
  }

  &.default {
    width: 40px;
    height: 40px;

    span {
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  &.highlight {
    border: 4px solid #3d82ff;
  }

  &.big {
    width: 60px;
    height: 60px;

    span {
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  &.noPhoto {
    background-color: var(--dark);
    opacity: 0.5;
  }
}
