.hero {
  padding: 0 !important;

  &--background {
    @include breakpoint(lg) {
      background: var(--bg);
    }

    .hero__wrap {
      border-radius: 2.5rem;
      background: var(--bg);
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;

      @include breakpoint(xxl) {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
      }

      @include breakpoint(xl) {
        padding-top: 2.6rem;
        padding-bottom: 2.6rem;
      }

      @include breakpoint(lg) {
        background: none;
      }

      @include breakpoint(xs) {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
      }

      @include breakpoint(xxs) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }

  &--extended {
    .hero__text {
      @include breakpoint(md, true) {
        width: 42%;
      }
    }

    .hero__image {
      @include breakpoint(xl, true) {
        margin-right: -2rem;
      }

      @include breakpoint(md, true) {
        width: 57%;
      }
    }
  }

  &--block {
    text-align: center;
    flex-direction: column;

    .hero__text,
    .hero__image {
      width: 100%;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }

    .hero__image {
      margin-top: 2.5rem;
    }

    .wp-block-buttons {
      justify-content: center;
    }

    .hero__wrap {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;

      @include breakpoint(xxl) {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
      }

      @include breakpoint(xl) {
        padding-top: 2.6rem;
        padding-bottom: 2.6rem;
      }

      @include breakpoint(lg) {
        background: none;
      }

      @include breakpoint(xs) {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
      }

      @include breakpoint(xxs) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }

  &__wrap {
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    align-items: center;
  }

  &__text {
    @include breakpoint(md, true) {
      width: 46%;
    }

    img {
      @include breakpoint(md) {
        max-width: 75%;
      }
    }
  }

  &__image {
    position: relative;
    font-size: 0;

    @include breakpoint(md, true) {
      width: 47%;
    }

    @include breakpoint(md) {
      margin-top: 2.5rem;
    }

    img,
    video {
      border-radius: 1.5rem;

      @include breakpoint(sm) {
        border-radius: 0.75rem;
      }
    }
  }

  &__observer {
    position: absolute;
    top: 20%;
    height: 1px;
    pointer-events: none;
    width: 100%;
    left: 0;
  }
}
