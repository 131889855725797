@import '@styles/basic';

.header {
  text-align: center;
  margin-bottom: 1.8rem;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.ausilio {
    margin-top: -1.2rem;

    & > h3 {
      margin-bottom: 0.6rem;
    }
  }
  .titleSection {
    &.blue {
      color: $blue;
    }
  }

  h1 {
    margin-bottom: 0;
    font-size: 48px;
    @media screen and (max-width: 1190px) {
      font-size: 40px;
    }
    & + p {
      margin-bottom: 1.8rem !important;
    }
  }

  button:nth-of-type(1) {
    margin-right: 0.3rem;
    padding: 0.6rem 1.5rem;
  }
  button:nth-of-type(2) {
    margin-left: 0.3rem;
    padding: 0.6rem 0.9rem;
  }
}

.cardWrap {
  display: grid;
  grid-template-columns: repeat(3, minmax(16rem, 19.05rem));
  justify-content: center;
  width: 100%;
  grid-gap: 1.2rem;
  grid-auto-rows: 1fr;
  margin-bottom: 1.8rem;
  @include breakpoint(lg) {
    max-width: 19.05rem;
    grid-template-columns: 1fr;
  }
}

.tableWrap {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  & > div {
    width: 100%;
  }
}

.alternatives {
  max-width: 60rem;
  display: flex;
  width: 100%;

  &.ausilio {
    margin-top: 0.6rem;
    margin-bottom: -1.2rem;
    & > div {
      margin-bottom: 0;
    }
  }
  p.titleSection {
    margin-bottom: 2.4rem;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoint(lg) {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    width: 100%;
    grid-gap: 1.2rem;
    grid-auto-rows: 1fr;
    max-width: 19.05rem;
  }

  & > div:first-child > div:first-child {
    @include breakpoint(lg) {
      margin-bottom: 8px;
    }
  }
}
