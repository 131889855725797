@import '@styles/basic';

.container {
  flex: 1;
  position: relative;
  margin-top: 48px;
  width: 100%;
  max-width: toRem(595);
  @include breakpoint(xl, true) {
    position: sticky;
    top: 128px;
    margin-top: -8px;
    right: 0;
    max-width: toRem(408);
  }
}

.hideOnMobile {
  display: none;
  @include breakpoint(xl, true) {
    display: block;
  }
}
