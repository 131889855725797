@import '@styles/basic';

.container {
  display: inline-flex;
  align-items: flex-start;

  .number {
    background-color: white;
    border: toRem(1) solid rgba($dark, 0.2);
    border-radius: 50%;
    flex-shrink: 0;
    line-height: 32px;
    width: toRem(32);
    height: toRem(32);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: toRem(8);
  }

  .title {
    padding-top: toRem(4);
    strong {
      display: inline;
    }
    a {
      color: $blue;
      cursor: pointer;
      font-weight: 700;
    }
  }
}
